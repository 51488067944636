import {
  ApolloError,
  gql,
  QueryResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import GroupCard from "../../components/Groups/GroupCard";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import Button from "../../components/shared/Button";
import Checkbox from "../../components/shared/Checkbox";
import { MenuItem } from "../../components/shared/context-menu/MenuItem";
import Dialog, { ButtonCol } from "../../components/shared/Dialog";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import { renderLoadingText } from "../../components/shared/LoadingIndicator";
import SelectField from "../../components/shared/SelectField";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import TextField from "../../components/shared/TextField";
import { Bold, H4, StyledLink, Text } from "../../components/shared/typography";
import { Customer, CustomerGroup, GroupRole } from "../../generated/graphql";
import {
  haveRole,
  KeycloakAccessToken,
  userId,
} from "../../lib/keycloakAccessToken";
import { useToast } from "../../lib/useToast";
import {
  ButtonSizes,
  SortOrder,
  ToastFormat,
  ToastType,
} from "../../shared/enums";
import CheckboxSpacer from "../../components/CheckboxSpacer";
import { Option } from "react-select/src/filters";
import RadioButton from "../../components/shared/RadioButton";
interface GroupDeletionInfo {
  groupId: number;
  groupName: string;
  customerId: number;
  retainAccess: boolean;
}

const Groups: React.FC = () => {
  const [, createToast] = useToast();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isGroupAdmin = haveRole(user, "group_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const [filteredCustomerIDs, setFilteredCustomerIDs] = useState<number[]>([]);
  const [searchText, setSearchText] = useState("");
  const [customerGroupsList, setCustomerGroupsList] = useState<CustomerGroup[]>(
    []
  );
  // Tracks whether to show creation modal
  const [creatingGroup, setCreatingGroup] = useState<boolean>(false);
  // Tracks whether to show save (edit/update) modal and for which group ID
  const [savingGroupForGroupId, setSavingGroupForGroupId] = useState<
    number | undefined
  >(undefined);
  // Tracks whether to show deletion modal and what to show in it
  const [deletingGroupInfo, setDeletingGroupInfo] = useState<
    GroupDeletionInfo | undefined
  >(undefined);
  // Tracks whether to show remove all deletion modal and what to show in it
  const [removingAllUsersInfo, setRemovingAllUsersInfo] = useState<
    { groupId: number } | undefined
  >(undefined);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [customerErrorMessage, setCustomerErrorMessage] = useState("");
  const [adminErrorMessage, setAdminErrorMessage] = useState("");
  const [inputValues, setInputValues] = useState<{
    groupName: string;
    groupAdmin: string;
    customerId?: number;
    customerName: string;
    description: string;
  }>({
    groupName: "",
    groupAdmin: user?.email ?? "",
    customerId: undefined,
    customerName: "",
    description: "",
  });
  const sortOrderOptions = [
    {
      value: "Group Name (A-Z)",
      label: "Group Name (A-Z)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Group Name (Z-A)",
      label: "Group Name (Z-A)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );

  const {
    data,
    loading,
    error,
  }: QueryResult<{
    customerGroups: CustomerGroup[];
    getCustomersForAdmin: Customer[];
  }> = useQuery(
    gql`
      query FetchCustomerGroups($roles: [GroupRole!]) {
        customerGroups(roles: $roles) {
          id
          invitationCode {
            code
          }
          name
          description
          admins {
            first_name
            last_name
            email
            keycloak_id
          }
          members {
            first_name
            last_name
            email
          }
          customer {
            id
            name
            admins {
              keycloak_id
            }
          }
          courses {
            course {
              name
            }
            assigned
          }
        }
        getCustomersForAdmin {
          name
          id
        }
      }
    `,
    {
      variables: {
        roles: [GroupRole.Admin],
      },
    }
  );

  const [createGroup, { loading: loadingCreateGroup }] = useMutation(
    gql`
      mutation CreateGroup(
        $customerGroup: CreateCustomerGroup!
        $roles: [GroupRole!]
      ) {
        createCustomerGroup(customerGroup: $customerGroup) {
          refetch {
            customerGroups(roles: $roles) {
              id
              name
              description
              admins {
                first_name
                last_name
                email
                keycloak_id
              }
              members {
                first_name
                last_name
                email
              }
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        closeCreateOrSaveDialog();
        createToast({
          title: "Successfully created group.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: (err: ApolloError) => {
        if (
          err.graphQLErrors[0].message ===
          'duplicate key value violates unique constraint "unique_group_names"'
        ) {
          setNameErrorMessage("A group with this name already exists");
        } else {
          setAdminErrorMessage(err.graphQLErrors[0].message);
        }
      },
    }
  );

  const [saveGroup, { loading: loadingSaveGroup }] = useMutation(
    gql`
      mutation SaveGroup(
        $customerGroup: SaveCustomerGroup!
        $roles: [GroupRole!]
      ) {
        saveCustomerGroup(customerGroup: $customerGroup) {
          refetch {
            customerGroups(roles: $roles) {
              id
              name
              description
              admins {
                first_name
                last_name
                email
                keycloak_id
              }
              members {
                first_name
                last_name
                email
              }
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        closeCreateOrSaveDialog();
        createToast({
          title: "Successfully updated group.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: (err: ApolloError) => {
        if (
          err.graphQLErrors[0].message ===
          'duplicate key value violates unique constraint "unique_group_names"'
        ) {
          setNameErrorMessage("A group with this name already exists");
        } else {
          setAdminErrorMessage(err.graphQLErrors[0].message);
        }
      },
    }
  );

  const [removeGroup, { loading: loadingDeleteGroup }] = useMutation(
    gql`
      mutation RemoveGroup(
        $customerId: Int!
        $customerGroupId: Int!
        $roles: [GroupRole!]
        $retainCourseAccess: Boolean!
      ) {
        removeCustomerGroup(
          customerId: $customerId
          customerGroupId: $customerGroupId
          retainCourseAccess: $retainCourseAccess
        ) {
          refetch {
            customerGroups(roles: $roles) {
              id
              name
              description
              admins {
                first_name
                last_name
                email
                keycloak_id
              }
              members {
                first_name
                last_name
                email
              }
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setDeletingGroupInfo(undefined);
        createToast({
          title: "Successfully deleted group.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const [removeAllUsers, { loading: loadingRemoveAllUsers }] = useMutation(
    gql`
      mutation RemoveAllUsers($customerGroupId: Int!, $roles: [GroupRole!]) {
        removeAllUsers(customerGroupId: $customerGroupId) {
          refetch {
            customerGroups(roles: $roles) {
              id
              name
              description
              admins {
                first_name
                last_name
                email
                keycloak_id
              }
              members {
                first_name
                last_name
                email
              }
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setRemovingAllUsersInfo(undefined);
        createToast({
          title: "Successfully removed all users from group.",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
      },
      onError: () => {
        createToast({
          title:
            "Something went wrong trying to remove all users. Try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  const handleChange = (name: string) => (event: any) => {
    if (name === "customer") {
      setInputValues({ ...inputValues, customerId: event.value });
    } else {
      setNameErrorMessage("");
      setAdminErrorMessage("");
      const value = event.currentTarget.value;
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleCreateGroup = () => {
    if (!inputValues.groupAdmin) {
      setAdminErrorMessage("Please enter group admin");
      return;
    }
    if (!inputValues.customerId) {
      setCustomerErrorMessage("Please enter account");
      return;
    }
    if (!inputValues.groupName) {
      setNameErrorMessage("Please enter group name");
      return;
    }
    createGroup({
      variables: {
        customerGroup: {
          customer_id: inputValues.customerId,
          name: inputValues.groupName,
          admin: inputValues.groupAdmin,
          description: inputValues.description,
        },
        roles: [GroupRole.Admin],
      },
    });
  };

  const handleSaveGroup = () => {
    if (!inputValues.groupAdmin) {
      setAdminErrorMessage("Please enter group admin");
      return;
    }
    if (!inputValues.customerId) {
      setNameErrorMessage("Please enter account");
      return;
    }
    if (!inputValues.groupName) {
      setNameErrorMessage("Please enter group name");
      return;
    }
    saveGroup({
      variables: {
        customerGroup: {
          customer_group_id: savingGroupForGroupId,
          name: inputValues.groupName,
          adminEmail: inputValues.groupAdmin,
          description: inputValues.description,
        },
        roles: [GroupRole.Admin],
      },
    });
  };

  const closeCreateOrSaveDialog = () => {
    setCreatingGroup(false);
    setSavingGroupForGroupId(undefined);
    setInputValues({
      groupName: "",
      groupAdmin: user?.email ?? "",
      customerId: undefined,
      customerName: "",
      description: "",
    });
    setNameErrorMessage("");
    setAdminErrorMessage("");
  };

  const findString = (string: string, searchText: string) =>
    string.toLowerCase().includes(searchText.toLowerCase());

  const searchByFirstNameOrLastName = (
    firstName: string,
    lastName: string,
    searchText: string
  ) => findString(firstName, searchText) || findString(lastName, searchText);

  useEffect(() => {
    data &&
      (searchText !== ""
        ? setCustomerGroupsList(
            customerGroupsList.filter(
              (group: CustomerGroup) =>
                findString(group.name, searchText) ||
                findString(
                  group.customer ? group.customer.name : "",
                  searchText
                ) ||
                group.members?.some((member) =>
                  searchByFirstNameOrLastName(
                    member.first_name,
                    member.last_name,
                    searchText
                  )
                ) ||
                group.admins?.some((admin) =>
                  searchByFirstNameOrLastName(
                    admin.first_name,
                    admin.last_name,
                    searchText
                  )
                )
            )
          )
        : setCustomerGroupsList(data.customerGroups));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 1000),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  const customers: Customer[] | undefined = [];
  data?.customerGroups.forEach((customerGroup) => {
    if (customerGroup.customer) {
      if (
        !customers.some(
          (customer) => customer.id === customerGroup.customer?.id
        )
      )
        customers.push(customerGroup.customer);
    }
  });

  return (
    <SidebarCardSection title="" maxColumns={1}>
      <ActionBar
        title={"Groups"}
        searchField={
          <TextField
            searchable
            onClearSearch={() => setSearchText("")}
            placeholder="Search by group, customer, or members"
            onChange={handleSearchChange}
          />
        }
        button={
          <>
            {isCustomerAdmin && (
              <Button
                size={ButtonSizes.Medium}
                onClick={() => setCreatingGroup(true)}
              >
                Create group
              </Button>
            )}
          </>
        }
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
            <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
              <FilterContainer
                onClear={() => {
                  setFilteredCustomerIDs([]);
                }}
              >
                <H4 style={{ marginBottom: "24px" }}>Customers</H4>
                <CheckboxSpacer>
                  {Array.from(customers).map((customer) => (
                    <Checkbox
                      key={customer.id}
                      title={customer.name}
                      checkboxChecked={filteredCustomerIDs.includes(
                        customer.id
                      )}
                      onCheckboxClick={() => {
                        const newCustomerIds = filteredCustomerIDs.includes(
                          customer.id
                        )
                          ? filteredCustomerIDs.filter(
                              (filteredCustomerID) =>
                                filteredCustomerID !== customer.id
                            )
                          : [...filteredCustomerIDs, customer.id];

                        setFilteredCustomerIDs(newCustomerIds);
                      }}
                    />
                  ))}
                </CheckboxSpacer>
              </FilterContainer>
            </DropdownMenu>
          </>
        }
      />
      {loading && !error && !data && (
        <SidebarCardSection title="Groups" maxColumns={1}>
          <Text>Loading groups...</Text>
        </SidebarCardSection>
      )}
      {(error || !data) && !loading && (
        <SidebarCardSection title="Groups" maxColumns={1}>
          <Text>Something went wrong when fetching accounts...</Text>
          {error?.graphQLErrors?.map(
            (error: { message: string }, i: number) => (
              <Text key={i}>{error.message}</Text>
            )
          )}
        </SidebarCardSection>
      )}
      {!error && !loading && data && customerGroupsList && (
        <>
          <H4>{customerGroupsList.length} groups</H4>

          <SidebarCardSection maxColumns={2}>
            {customerGroupsList.length > 0 &&
              [...customerGroupsList]
                .sort((group1, group2) =>
                  selectedSortOption.data.sortOrder === SortOrder.AZ
                    ? group1.name > group2.name
                      ? 1
                      : -1
                    : group1.name < group2.name
                    ? 1
                    : -1
                )
                .filter((group) => {
                  if (filteredCustomerIDs.length === 0) {
                    return true;
                  } else {
                    return filteredCustomerIDs.some(
                      (filteredCustomerID) =>
                        filteredCustomerID === group.customer?.id
                    );
                  }
                })
                .map((group: CustomerGroup) => {
                  const isCustomerAdminForGroup =
                    isCustomerAdmin &&
                    group.customer &&
                    group.customer.admins &&
                    group.customer.admins.some(
                      (admin) => admin.keycloak_id === userId(user)
                    );
                  const isGroupAdminForGroup =
                    isGroupAdmin &&
                    group.admins &&
                    group.admins.some(
                      (admin) => admin.keycloak_id === userId(user)
                    );
                  return (
                    <GroupCard
                      key={group.id}
                      name={group.name}
                      admin={group?.admins?.[0]}
                      members={group.members}
                      customerName={group.customer ? group.customer.name : ""}
                      description={group.description}
                      invitationLink={
                        isGroupAdmin || isCustomerAdminForGroup
                          ? group.invitationCode?.code
                          : undefined
                      }
                    >
                      {(isGroupAdmin ||
                        isCustomerAdminForGroup ||
                        isSysAdmin) && (
                        <StyledLink
                          style={{ textDecoration: "none" }}
                          to={`/admin/group-courses/${group.id}/${group.name}`}
                        >
                          <MenuItem>Manage Courses</MenuItem>
                        </StyledLink>
                      )}
                      {(isCustomerAdminForGroup || isSysAdmin) && (
                        <>
                          <MenuItem
                            onClick={() => {
                              if (group.customer) {
                                setInputValues({
                                  ...inputValues,
                                  groupAdmin: group?.admins?.[0].email ?? "",
                                  customerId: group.customer.id,
                                  customerName: "",
                                  groupName: group.name,
                                  description: group.description,
                                });
                                setSavingGroupForGroupId(group.id);
                              }
                            }}
                          >
                            Edit Group
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              if (group?.customer?.id) {
                                setDeletingGroupInfo({
                                  groupId: group.id,
                                  groupName: group.name,
                                  customerId: group.customer.id,
                                  retainAccess: false,
                                });
                              }
                            }}
                          >
                            Delete Group
                          </MenuItem>
                        </>
                      )}
                      {isGroupAdminForGroup && (
                        <MenuItem
                          onClick={() =>
                            setRemovingAllUsersInfo({ groupId: group.id })
                          }
                        >
                          Remove All Users
                        </MenuItem>
                      )}
                    </GroupCard>
                  );
                })}
            {customerGroupsList.length === 0 && <Text>No groups found.</Text>}
          </SidebarCardSection>
        </>
      )}
      {creatingGroup && (
        <Dialog
          title={"Create Group"}
          onClose={() => closeCreateOrSaveDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeCreateOrSaveDialog()}>
                Cancel
              </Button>
              <Button
                id="cy-create-group-dialog-button"
                onClick={handleCreateGroup}
              >
                {renderLoadingText(loadingCreateGroup, "Create group")}
              </Button>
            </>
          }
        >
          <TextField
            label={"Group name"}
            placeholder={"Enter a name for your group"}
            onChange={handleChange("groupName")}
            value={inputValues.groupName}
            error={nameErrorMessage}
            id="cy-create-group-name"
          />
          <SelectField
            label={"Account"}
            onChange={handleChange("customer")}
            placeholder={"Select account"}
            defaultValue={inputValues.customerId}
            error={customerErrorMessage}
            id="cy-create-group-customer"
            name="customer"
            options={data?.getCustomersForAdmin.map((customer) => ({
              value: customer.id,
              label: customer.name,
            }))}
          />
          <TextField
            label={"Group admin"}
            onChange={handleChange("groupAdmin")}
            placeholder={"Enter email address"}
            value={inputValues.groupAdmin}
            error={adminErrorMessage}
            id="cy-create-group-admin"
          />
          <TextField
            label={"Description"}
            onChange={handleChange("description")}
            placeholder={"Enter description"}
            value={inputValues.description}
            id="cy-create-group-description"
          />
        </Dialog>
      )}
      {deletingGroupInfo && (
        <Dialog
          title="Delete Group"
          onClose={() => setDeletingGroupInfo(undefined)}
          buttons={
            <>
              <Button secondary onClick={() => setDeletingGroupInfo(undefined)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  removeGroup({
                    variables: {
                      customerGroupId: deletingGroupInfo?.groupId,
                      customerId: deletingGroupInfo?.customerId,
                      roles: [GroupRole.Admin],
                      retainCourseAccess: deletingGroupInfo?.retainAccess,
                    },
                  });
                }}
              >
                {renderLoadingText(loadingDeleteGroup, "Delete Group")}
              </Button>
            </>
          }
        >
          When deleting the group "{deletingGroupInfo?.groupName}", do you want
          to remove access to started or assigned courses for users in this
          group?
          <ButtonCol>
            <RadioButton
              name="new"
              value="new"
              checked={!deletingGroupInfo.retainAccess}
              onChange={() =>
                setDeletingGroupInfo({
                  customerId: deletingGroupInfo.customerId,
                  groupId: deletingGroupInfo.groupId,
                  groupName: deletingGroupInfo.groupName,
                  retainAccess: false,
                })
              }
              label={
                <>
                  <Bold>Remove</Bold> course access for users in this group
                </>
              }
            />
            <RadioButton
              name="all"
              value="all"
              checked={deletingGroupInfo.retainAccess}
              onChange={() => {
                setDeletingGroupInfo({
                  customerId: deletingGroupInfo.customerId,
                  groupId: deletingGroupInfo.groupId,
                  groupName: deletingGroupInfo.groupName,
                  retainAccess: true,
                });
              }}
              label={
                <>
                  <Bold>Retain</Bold> course access for users in this group
                </>
              }
            />
          </ButtonCol>
        </Dialog>
      )}
      {removingAllUsersInfo && (
        <Dialog
          title="Remove All Users"
          onClose={() => setRemovingAllUsersInfo(undefined)}
          buttons={
            <>
              <Button
                secondary
                onClick={() => setRemovingAllUsersInfo(undefined)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  removeAllUsers({
                    variables: {
                      customerGroupId: removingAllUsersInfo.groupId,
                      roles: [GroupRole.Admin],
                    },
                  });
                }}
              >
                {renderLoadingText(loadingRemoveAllUsers, "Remove Users")}
              </Button>
            </>
          }
        >
          Removing all users from the group will remove their access to the
          following courses:
          <ul>
            {data?.customerGroups
              .find(
                (customerGroup) =>
                  customerGroup.id === removingAllUsersInfo.groupId
              )
              ?.courses?.filter((course) => course.assigned === true)
              .map((course) => (
                <li key={course.course.id}>{course.course.name}</li>
              ))}
          </ul>
          Are you sure you want to remove all users?
        </Dialog>
      )}
      {savingGroupForGroupId && (
        <Dialog
          title={"Edit Group"}
          onClose={() => closeCreateOrSaveDialog()}
          buttons={
            <>
              <Button secondary onClick={() => closeCreateOrSaveDialog()}>
                Cancel
              </Button>
              <Button onClick={handleSaveGroup}>
                {renderLoadingText(loadingSaveGroup, "Update Group")}
              </Button>
            </>
          }
        >
          <TextField
            label={"Group name"}
            placeholder={"Enter a name for your group"}
            onChange={handleChange("groupName")}
            value={inputValues.groupName}
            error={nameErrorMessage}
            id="cy-create-group-name"
          />
          <TextField
            label={"Group admin"}
            onChange={handleChange("groupAdmin")}
            placeholder={"Enter email address"}
            value={inputValues.groupAdmin}
            error={adminErrorMessage}
            id="cy-create-group-admin"
          />
          <TextField
            label={"Description"}
            onChange={handleChange("description")}
            placeholder={"Enter description"}
            value={inputValues.description}
            id="cy-create-group-description"
          />
        </Dialog>
      )}
    </SidebarCardSection>
  );
};

export default Groups;
