import React from "react";
import { ArrowUpCircle, Download, CheckCircle, Plus } from "lucide-react";
import { VistAvailableReleasesForSystem } from "../types/updateTypes";

interface NotificationItemProps {
  notification: VistAvailableReleasesForSystem;
  onClear: (event: React.MouseEvent) => void;
  onClick: (event: React.MouseEvent) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClear,
  onClick,
}) => {
  const getNotificationIcon = (notification: VistAvailableReleasesForSystem) => {
    if (notification.state.installed) {
      return <CheckCircle size={24} color="#4CAF50" />;
    }
    if (notification.state.downloaded) {
      return <Download size={24} color="#2196F3" />;
    }
    if (!notification.currentRelease) {
      return <Plus size={24} color="#9C27B0" />;
    }
    return <ArrowUpCircle size={24} color="#FF9800" />;
  };

  const getNotificationTitle = (notification: VistAvailableReleasesForSystem) => {
    const { release, state, currentRelease } = notification;
    const softwareInfo = `${release.software.name} ${release.version.semantic}`;

    if (state.installed) return `${softwareInfo} has been installed.`;
    if (state.downloaded) return `${softwareInfo} is ready to install.`;
    if (!currentRelease) return `New software available: ${softwareInfo}`;
    return `New update available: ${softwareInfo}`;
  };

  const getNotificationSubtitle = (notification: VistAvailableReleasesForSystem) => {
    const { state, currentRelease } = notification;

    if (state.installed && !currentRelease) return "New software installation";
    if (state.installed) return `Upgraded from version ${currentRelease.version.semantic}`;
    if (state.downloaded && !currentRelease) return "New software ready for installation";
    if (state.downloaded) return `Will upgrade from version ${currentRelease.version.semantic}`;
    if (!currentRelease) return "First-time installation";
    return `Current version: ${currentRelease.version.semantic}`;
  };

  const formatNotification = (notification: VistAvailableReleasesForSystem) => {
    return (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ marginRight: "16px", marginTop: "4px" }}>
          {getNotificationIcon(notification)}
        </div>
        <div>
          <span style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
            {getNotificationTitle(notification)}
          </span>
          <br />
          <small style={{ color: "#666" }}>
            {getNotificationSubtitle(notification)}
          </small>
        </div>
      </div>
    );
  };

  const getNotificationColor = (notification: VistAvailableReleasesForSystem) => {
    if (notification.state.installed) return "#E8F5E9";
    if (notification.state.downloaded) return "#E3F2FD";
    return notification.currentRelease ? "#FFF3E0" : "#F3E5F5";
  };

  return (
    <div
      style={{
        marginBottom: "10px",
        position: "relative",
        padding: "12px",
        borderRadius: "4px",
        backgroundColor: getNotificationColor(notification),
        transition: "all 0.3s ease",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div style={{ paddingRight: "24px" }}>
        {formatNotification(notification)}
        {/* <small style={{ display: "block", marginTop: "8px", color: "#999" }}>
          {new Date(notification.createdAt).toLocaleString()}
        </small> */}
      </div>
      <button
        onClick={onClear}
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
          background: "none",
          border: "none",
          cursor: "pointer",
          fontSize: "18px",
          color: "#999",
          transition: "color 0.3s ease",
          padding: "4px",
          lineHeight: "1",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.color = "#ff4757")}
        onMouseLeave={(e) => (e.currentTarget.style.color = "#999")}
      >
        ×
      </button>
    </div>
  );
};

export default NotificationItem;