import React from "react";
import {
  dots,
  Pagination,
  Page,
  PageArrow,
} from "../../components/shared/pagination/Pagination";
import {
  paginationLimit,
  getPagesList,
  handlePageChange,
  scrollToTop,
} from "../../components/shared/pagination/helper";

interface ActivitiesPaginationProps {
  activitiesLoading: boolean;
  allActivitiesLoading: boolean;
  activePage: number;
  totalPages: number;
  setActivePage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const ActivitiesPagination = ({
  activitiesLoading,
  allActivitiesLoading,
  activePage,
  totalPages,
  setActivePage,
  setOffset,
}: ActivitiesPaginationProps) => {
  return (
    <Pagination activitiesLoading={activitiesLoading || allActivitiesLoading}>
      <PageArrow
        src="/static/assets/arrow-prev-month.svg"
        alt="Previous page"
        onClick={() => {
          handlePageChange(
            activePage - 1,
            totalPages,
            setActivePage,
            setOffset
          );
          scrollToTop();
        }}
      />
      {totalPages !== 1 && (
        <Page
          activePage={activePage === 1}
          onClick={() => {
            handlePageChange(1, totalPages, setActivePage, setOffset);
            scrollToTop();
          }}
        >
          {1}
        </Page>
      )}
      {activePage >= paginationLimit && dots}
      {getPagesList(activePage, totalPages).map((page: number) => (
        <Page
          key={page}
          activePage={page === activePage}
          onClick={() => {
            handlePageChange(page, totalPages, setActivePage, setOffset);
            scrollToTop();
          }}
        >
          {page}
        </Page>
      ))}
      {activePage <= totalPages - 3 && dots}
      {totalPages !== 0 && (
        <Page
          activePage={activePage === totalPages}
          onClick={() => {
            handlePageChange(totalPages, totalPages, setActivePage, setOffset);
            scrollToTop();
          }}
        >
          {totalPages}
        </Page>
      )}
      <PageArrow
        src="/static/assets/arrow-next-month.svg"
        alt="Next page"
        onClick={() => {
          handlePageChange(
            activePage + 1,
            totalPages,
            setActivePage,
            setOffset
          );
          scrollToTop();
        }}
      />
    </Pagination>
  );
};

export default ActivitiesPagination;
