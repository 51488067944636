import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  gap: 2px;
  margin: 20px 0;
  border-bottom: 1px solid #eee;
`;

export const Tab = styled.button<{ active: boolean }>`
  padding: 12px 24px;
  background: ${props => props.active ? '#fff' : '#f5f5f5'};
  border: 1px solid #eee;
  border-bottom: ${props => props.active ? '1px solid #fff' : '1px solid #eee'};
  margin-bottom: -1px;
  cursor: pointer;
  color: ${props => props.active ? '#333' : '#666'};
  font-weight: ${props => props.active ? '500' : 'normal'};
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.active ? '#fff' : '#f0f0f0'};
  }

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }
`;

export const TabCount = styled.span`
  background: #eee;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
  color: #666;
`;

export const AnimatedCard = styled.div<{ index: number }>`
opacity: 0;
transform: translateY(20px);
animation: slideIn 0.5s ease forwards;
animation-delay: ${props => props.index * 0.15}s;

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;