import React from "react";
import styled from "styled-components";
import { LargeText } from "./typography";

const CourseCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled.div`
  display: flex;
  margin-right: 14px;
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    height: ${(props: { small?: boolean }) => (props.small ? "16px" : "24px")};
    width: ${(props: { small?: boolean }) => (props.small ? "16px" : "24px")};
    border: 2px solid ${(props) => props.theme.colors.lightGrey};
    border-radius: ${(props: { small?: boolean }) =>
      props.small ? "2px" : "4px"};
    margin: 0px;
  }

  input[type="checkbox"]:after {
    content: "";
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid ${(props) => props.theme.colors.primary300};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }

  input[type="checkbox"]:checked {
    border: 2px solid ${(props) => props.theme.colors.primary300};
    background: white;
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }
`;

interface CheckboxProps {
  onCheckboxClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  checkboxChecked?: boolean;
  small?: boolean;
  testId?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  onCheckboxClick,
  title,
  checkboxChecked,
  small,
  testId,
}) => {
  return (
    <CourseCheckboxWrapper>
      <StyledCheckbox small={small}>
        <input
          id={testId}
          type="checkbox"
          checked={checkboxChecked}
          onChange={onCheckboxClick}
        />
      </StyledCheckbox>
      {title && <LargeText>{title}</LargeText>}
    </CourseCheckboxWrapper>
  );
};

export default Checkbox;
