import styled from "styled-components";

const CheckboxSpacer = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.spacing4};
  > div {
    margin-bottom: ${(props) => props.theme.sizes.spacing2};
    white-space: nowrap;
  }
`;

export default CheckboxSpacer