import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import { gql, useLazyQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { useToast } from "../../lib/useToast";
import {
  ActivitySortField,
  CustomerGroup as CustomerGroupEntity,
  SortOrder,
  VistActivityItem as VistActivityItemEntity,
} from "../../generated/graphql";
import { Option } from "react-select/src/filters";
import { limit } from "../../components/shared/pagination/helper";
import ActionsSection from "./ActionsSection";
import ActivitiesSection from "./ActivitiesSection";
import ActivitiesPagination from "./ActivitiesPagination";
import { handleDownloadActivities } from "./helper";

interface ActivitiesList {
  activities: VistActivityItemEntity[];
  count: number;
}

const ActivityLogPage: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;

  const [, createToast] = useToast();

  const sortOrderOptions: Option[] = [
    {
      value: "Date (Newest to oldest)",
      label: "Date (Newest to oldest)",
      data: {
        sortField: ActivitySortField.ActivityStart,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Date (Oldest to newest)",
      label: "Date (Oldest to newest)",
      data: {
        sortField: ActivitySortField.ActivityStart,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Course (A-Z)",
      label: "Course (A-Z)",
      data: {
        sortField: ActivitySortField.CourseName,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Course (Z-A)",
      label: "Course (Z-A)",
      data: {
        sortField: ActivitySortField.CourseName,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Exercise (A-Z)",
      label: "Exercise (A-Z)",
      data: {
        sortField: ActivitySortField.ExerciseName,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Exercise (Z-A)",
      label: "Exercise (Z-A)",
      data: {
        sortField: ActivitySortField.ExerciseName,
        sortOrder: SortOrder.Desc,
      },
    },
    {
      value: "Score (Highest to lowest)",
      label: "Score (Highest to lowest)",
      data: {
        sortField: ActivitySortField.Score,
        sortOrder: SortOrder.Asc,
      },
    },
    {
      value: "Score (Lowest to highest)",
      label: "Score (Lowest to highest)",
      data: {
        sortField: ActivitySortField.Score,
        sortOrder: SortOrder.Desc,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [searchText, setSearchText] = useState("");
  const [checkedGroups, setCheckedGroups] = useState<number[]>([]);

  const [viewAllActivities, setViewAllActivities] = useState(false);

  const [activityState, setActivityState] = useState<
    VistActivityItemEntity[] | undefined
  >(undefined);
  const [shownActivities, setShownActivities] = useState<
    VistActivityItemEntity[] | undefined
  >(undefined);

  const [customerGroupsState, setCustomerGroupsState] = useState<
    CustomerGroupEntity[] | undefined
  >([]);
  const [shownCustomersGroup, setShownCustomersGroup] = useState<
    CustomerGroupEntity[] | undefined
  >([]);

  const [offset, setOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allActivitiesCount, setAllActivitiesCount] = useState(0);

  // Vist activities if user is group admin
  // Last fields used for Export
  const queryActivitiesFields = `
    activityStart
    activityEnd
    createdBy {
      first_name
      last_name
      email
    }
    id
    unitName
    courseName
    totalTimeInSeconds
    result
    progress
    sourceUrl
    exerciseId
    exerciseName
    moduleId
    moduleName
    moduleVersion
    serviceTag
    ipAddress
    `;

  useEffect(() => {
    if (user) {
      fetchVistActivities({
        variables: {
          groups: checkedGroups,
          searchParams: {
            sortField: selectedSortOption.data.sortField,
            sortOrder: selectedSortOption.data.sortOrder,
            searchText: searchText,
          },
        },
      });
    }
  }, [checkedGroups, searchText, selectedSortOption, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      handleViewAllActivities(viewAllActivities);
    }
  }, [offset, searchText, selectedSortOption, user, viewAllActivities]); // eslint-disable-line react-hooks/exhaustive-deps

  const [
    fetchVistActivities,
    { loading: activitiesLoading, error: vistActivitiesErrors },
  ] = useLazyQuery<{
    customerGroupsByUser: CustomerGroupEntity[];
    vistActivities: VistActivityItemEntity[];
  }>(
    gql`
      query FetchVistActivity(
        $groups: [Int!]!
        $searchParams: ActivitySearchParams
      ) {
        vistActivities(groups: $groups, searchParams: $searchParams) {
         ${queryActivitiesFields}
        }
        customerGroupsByUser {
          id
          name
        }
      }
    `,
    {
      onCompleted: ({ customerGroupsByUser, vistActivities }) => {
        setActivityState(vistActivities);
        setCustomerGroupsState(customerGroupsByUser);
        setShownActivities(vistActivities);
        setShownCustomersGroup(customerGroupsByUser);

        if (vistActivities.length > 1) {
          setTotalPages(Math.ceil(vistActivities.length / limit));
        }
      },
      onError(err) {
        console.error("err", err);
      },
    }
  );

  const [
    fetchAllVistActivitiesPaginated,
    {
      loading: allActivitiesPaginatedLoading,
      error: allVistActivitiesPaginetedErrors,
    },
  ] = useLazyQuery<{
    allCustomerGroups: CustomerGroupEntity[];
    allVistActivities: ActivitiesList;
  }>(
    gql`
      query FetchAllVistActivity(
        $searchParams: ActivitySearchParams
        $limit: Int
        $offset: Int
      ) {
        allVistActivities(
          searchParams: $searchParams
          limit: $limit
          offset: $offset
        ) {
          activities { ${queryActivitiesFields} }
          count
        }
        allCustomerGroups {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({
        allCustomerGroups,
        allVistActivities: { activities, count },
      }) => {
        
        if (offset === 0) {
          setAllActivitiesCount(count);
          if (count > 1) {
            setTotalPages(Math.ceil(count / limit));
          }
        }

        setShownActivities(activities);
        setShownCustomersGroup(allCustomerGroups);
      },
      onError(err) {
        console.error("err", err);
      },
    }
  );

  const handleViewAllActivitiesToggle = () => {
    setViewAllActivities(!viewAllActivities);
    setOffset(0);
    setActivePage(1);
  };

  const handleViewAllActivities = (viewAll: boolean) => {
    if (viewAll) {
      fetchAllVistActivitiesPaginated({
        variables: {
          searchParams: {
            sortField: selectedSortOption.data.sortField,
            sortOrder: selectedSortOption.data.sortOrder,
            searchText: searchText,
          },
          limit: limit,
          offset: offset,
        },
      });
    } else {
      setShownActivities(activityState);
      setShownCustomersGroup(customerGroupsState);

      const count = activityState?.length || 1;

      if (count > 1) {
        setTotalPages(Math.ceil(count / limit));
      }
    }
  };

  const [fetchAllVistActivities] = useLazyQuery<{
    allVistActivities: ActivitiesList;
  }>(
    gql`
      query FetchAllVistActivity($searchParams: ActivitySearchParams) {
        allVistActivities(searchParams: $searchParams) {
          activities { ${queryActivitiesFields} }
        }
      }
    `,
    {
      onCompleted: ({ allVistActivities: { activities } }) => {
        handleDownloadActivities(activities, createToast);
      },
      onError(err) {
        console.error(err);
      },
    }
  );

  const onClickDownloadActivities = () => {
    if (viewAllActivities) {
      fetchAllVistActivities({
        variables: {
          searchParams: {
            sortField: selectedSortOption.data.sortField,
            sortOrder: selectedSortOption.data.sortOrder,
            searchText: searchText,
          },
        },
      });
    } else {
      handleDownloadActivities(shownActivities, createToast);
    }
  };

  return (
    <PageLayout title="Mentice Live | Group Courses">
      {children}
      <ActionsSection
        checkedGroups={checkedGroups}
        selectedSortOption={selectedSortOption}
        shownCustomersGroup={shownCustomersGroup}
        sortOrderOptions={sortOrderOptions}
        user={user}
        viewAllActivities={viewAllActivities}
        setCheckedGroups={setCheckedGroups}
        setSearchText={setSearchText}
        setSelectedSortOption={setSelectedSortOption}
        handleViewAllActivitiesToggle={handleViewAllActivitiesToggle}
        onClickDownloadActivities={onClickDownloadActivities}
      />
      <ActivitiesSection
        loading={activitiesLoading || allActivitiesPaginatedLoading}
        error={vistActivitiesErrors || allVistActivitiesPaginetedErrors}
        shownActivities={shownActivities}
        viewAllActivities={viewAllActivities}
        offset={offset}
        limit={limit}
      />
      {shownActivities?.length &&
      (shownActivities?.length > limit || allActivitiesCount > limit) ? (
        <ActivitiesPagination
          activitiesLoading={activitiesLoading}
          allActivitiesLoading={allActivitiesPaginatedLoading}
          activePage={activePage}
          totalPages={totalPages}
          setActivePage={setActivePage}
          setOffset={setOffset}
        />
      ) : (
        ""
      )}
    </PageLayout>
  );
};

export default ActivityLogPage;
