import styled, { keyframes } from 'styled-components';

const successRipple = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const successFade = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const slideOutRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
  }
`;

const slideInLeft = keyframes`
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const SuccessWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

export const SuccessRipple = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(5, 150, 105, 0.15);
  animation: ${successRipple} 1s ease-out forwards;
`;

export const SuccessContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  animation: ${successFade} 0.4s ease-out forwards;
  z-index: 1;
`;

export const SuccessText = styled.span`
  color: #059669;
  font-weight: 500;
  font-size: 13px;
`;

export const TransitionWrapper = styled.div<{ isInstalled: boolean; isExiting: boolean }>`
  animation: ${props => props.isExiting ? slideOutRight : slideInLeft} 0.4s ease-out forwards;
  width: 100%;
`;
