import React from 'react';
import Button from "../shared/Button";
import { ButtonSizes } from "../../shared/enums";
import { ChevronDown, ChevronUp, CheckCircle } from "lucide-react";
import Update from "../icons/Update";
import Download from "../icons/Download";
import Loading from "../icons/Loading";
import { 
  ActionPane, 
  CardLoadingContainer, 
  ExpandButton,
  StatusText,
  StatusContainer,
  SuccessIcon
} from "./ActionButtonStyles";
import { UpdateInfo } from './types';
import { SuccessWrapper, SuccessRipple, SuccessContent, SuccessText } from './CelebrationStyles';

interface ActionButtonsProps {
  update: UpdateInfo;
  onDownload: (id: string) => void;
  onInstall: (id: string) => void;
  isExpanded: boolean;
  toggleExpansion: () => void;
  tabType: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  update,
  onDownload,
  onInstall,
  isExpanded,
  toggleExpansion,
  tabType
}) => {
  const getStatusMessage = () => {
    if (tabType === 'installed') {
      return "Installed";
    }
    if (update.state.operationIsRunning) {
      return update.state.downloaded ? "Installing..." : "Downloading...";
    }
    if (update.state.installed) {
      return "Successfully Installed";
    }
    if (update.state.downloaded) {
      return "Ready to Install";
    }
    return "Update Available";
  };

  return (
    <ActionPane>
      <StatusContainer>
        {update.state.installed && tabType === 'available' ? (
          <SuccessWrapper>
            <SuccessRipple />
            <SuccessContent>
              <SuccessIcon>
                <CheckCircle size={16} />
              </SuccessIcon>
              <SuccessText>Successfully Installed</SuccessText>
            </SuccessContent>
          </SuccessWrapper>
        ) : (
          <StatusText status={update.state}>{getStatusMessage()}</StatusText>
        )}
      </StatusContainer>
      
      {tabType === 'available' && (
        <>
          {update.state.downloaded &&
            !update.state.installed &&
            !update.state.operationIsRunning && (
              <Button
                onClick={() => onInstall(update.id)}
                size={ButtonSizes.Small}
                icon={<Update />}
              />
          )}
          {!update.state.downloaded &&
            !update.state.operationIsRunning &&
            !update.state.installed && (
              <Button
                onClick={() => onDownload(update.id)}
                size={ButtonSizes.Small}
                icon={<Download />}
              />
          )}
          {update.state.operationIsRunning && (
            <CardLoadingContainer>
              <Loading small={true} />
            </CardLoadingContainer>
          )}
        </>
      )}
      <ExpandButton onClick={toggleExpansion}>
        {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </ExpandButton>
    </ActionPane>
  );
};
