import React from 'react';
import { H4 } from "../shared/typography";
import { UpdateInfo } from './types';

interface CardHeaderProps {
  update: UpdateInfo;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ update }) => {
  const versionDisplay = !update.state.installed;

  return (
    <div>
      <H4>
        {update.release.software.name}{" "}
        <span>
          <b>v.{update.release.version.semantic}</b> {versionDisplay}
        </span>
      </H4>
    </div>
  );
};
