import styled from 'styled-components';

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
`;

export const InfoItem = styled.div`
  p {
    margin: 0;
    &:first-child {
      color: #666;
      font-size: 13px;
      margin-bottom: 4px;
    }
    &:last-child {
      color: #333;
      font-weight: 500;
    }
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 13px;
  margin: 0 0 4px 0;
`;

export const InfoValue = styled.p`
  color: #333;
  font-weight: 500;
  margin: 0;
`;

export const InfoTooltip = styled.div`
  position: relative;
  cursor: help;
  color: #888;
  display: flex;
  align-items: center;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }

  span {
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background: #2c3e50;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.2s ease;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 6px solid transparent;
      border-top-color: #2c3e50;
    }
  }
`;
