import styled from "styled-components";
import { H4, Span } from "../shared/typography";
import Button from "../shared/Button";

export const Container = styled.div`
  padding: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled(H4)`
  margin-bottom: 20px;
  color: #333333;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

export const SearchWrapper = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const ErrorMessage = styled.div`
  color: #e74c3c;
  background-color: #fadbd8;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const FileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding-right: 15px;
`;

export const UploadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }

  svg {
    margin-right: 8px;
  }
`;

export const BackButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
`;

export const CurrentPath = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  color: #4a4a4a;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
`;

export const BreadcrumbItem = styled.span`
  cursor: pointer;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

export const BreadcrumbSeparator = styled.span`
  margin: 0 8px;
  color: #6c757d;
`;

export const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 70vh;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(20px);

  &.loaded {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FileItem = styled.li`
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const FileName = styled.span`
  font-weight: 500;
`;

export const FileInfo = styled.span`
  color: #888;
  font-size: 0.9em;
`;

export const CreateFolderButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
`;

export const IconWrapper = styled.div<{ isDirectory?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: ${(props) => (props.isDirectory ? "#FDEADE" : "#f5f5f5")};
  border-radius: 50%;
  margin-bottom: 8px;
`;

export const Card = styled.div<{ isDirectory?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: ${(props) => (props.isDirectory ? "#FFF6F0" : "#f5f5f5")};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: ${(props) => (props.isDirectory ? "pointer" : "default")};

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
`;

export const CardFileInfo = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export const CardFileName = styled.h3`
  font-size: 16px;
  margin: 0;
  word-break: break-all;
`;

export const CardFileDetails = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 5px;

  span {
    display: block;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 8px;
  
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;
