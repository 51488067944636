import React, { useState } from 'react';
import { InfoGrid as StyledInfoGrid, InfoItem, InfoLabel, InfoValue, InfoTooltip } from './InfoGridStyles';
import { UpdateInfo } from './types';
import { HelpCircle } from 'lucide-react';

interface InfoGridProps {
  update: UpdateInfo;
  tabType: 'available' | 'installed';
}

export const InfoGrid: React.FC<InfoGridProps> = ({ update, tabType }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleTooltipPosition = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      x: rect.left + rect.width / 2,
      y: rect.top - 5
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const diffDays = Math.floor((today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  return (
    <StyledInfoGrid>
      <InfoItem>
        <InfoLabel>
          Software Name
          <InfoTooltip>
            <HelpCircle size={14} />
            <span>{tabType === 'installed' ? "The name of the software currently installed" : "The name of the software being updated"}</span>
          </InfoTooltip>
        </InfoLabel>
        <InfoValue>{update.release.software.name}</InfoValue>
      </InfoItem>
      {update.currentRelease && update.state.installed ? (
        <InfoItem>
          <InfoLabel>
            Current System Version
            <InfoTooltip>
              <HelpCircle size={14} />
              <span>The version currently running on your system</span>
            </InfoTooltip>
          </InfoLabel>
          <InfoValue>{update.currentRelease.version.semantic}</InfoValue>
        </InfoItem>
      ) : (
        <InfoItem>
          <InfoLabel>
            New Version Available
            <InfoTooltip>
              <HelpCircle size={14} />
              <span>The version that will be installed with this update</span>
            </InfoTooltip>
          </InfoLabel>
          <InfoValue>v.{update.release.version.semantic}</InfoValue>
        </InfoItem>
      )}
      <InfoItem>
        <InfoLabel>
          Rollout Date
          <InfoTooltip>
            <HelpCircle size={14} />
            <span>When this software update became available</span>
          </InfoTooltip>
        </InfoLabel>
        <InfoValue>{formatDate(update.createdAt)}</InfoValue>
      </InfoItem>
    </StyledInfoGrid>
  );
};
