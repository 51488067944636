import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { SystemsStats } from "../../generated/graphql";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import DateRangePicker from "../../components/shared/DateRangePicker";
import ExpandableCard from "../../components/shared/ExpandableCard";
import { H6, Text } from "../../components/shared/typography";

const FlexibleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
`;

const FlexibleItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LogsStats = () => {
  const [fromLogsFilterDate, setFromLogsFilterDate] = useState<Date>(
    new Date()
  );
  const [toLogsFilterDate, setToLogsFilterDate] = useState<Date>(new Date());

  const [
    fetchSystemStats,
    { loading: statsLoading, data: statsData, error: statsError },
  ] = useLazyQuery<{ vistSystemsStats: SystemsStats }>(
    gql`
      query FetchSystemStats {
        vistSystemsStats {
          connected
          loggedTotal
          loggedLastWeek
          logsTotal
          logsLastWeek
          logsLastMonth
          logsLastYear
        }
      }
    `,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => console.error(error),
    }
  );

  useEffect(() => {
    fetchSystemStats();
  }, [fetchSystemStats]);

  const [
    fetchSystemsLogsNumberByPeriod,
    {
      loading: logsNumberLoading,
      data: logsNumberData,
      error: logsNumberError,
    },
  ] = useLazyQuery<{ vistSystemsLogsNumberByPeriod: number }>(
    gql`
      query FetchSystemsLogsNumberByPeriod($from: DateTime!, $to: DateTime!) {
        vistSystemsLogsNumberByPeriod(from: $from, to: $to)
      }
    `,
    {
      onCompleted: () => {},
      onError: (error) => console.error(error),
    }
  );

  const handleLogsFilterDateRangeChange = (from: Date, to: Date) => {
    setFromLogsFilterDate(from);
    setToLogsFilterDate(to);
    fetchSystemsLogsNumberByPeriod({
      variables: {
        from: from,
        to: to,
      },
    });
  };

  const [openLogsInfo, setOpenLogsInfo] = useState(false);

  return (
    <ExpandableCard
      expanded={openLogsInfo}
      key={"openLogsInfo"}
      header={
        <FlexibleContainer onClick={() => setOpenLogsInfo(!openLogsInfo)}>
          <H6>Logs statistcs</H6>
        </FlexibleContainer>
      }
    >
      {statsLoading && <LoadingIndicator />}
      {!statsError && !statsLoading && (
        <FlexibleContainer>
          <FlexibleItem>
            <Text>{`Connected: ${statsData?.vistSystemsStats.connected}`}</Text>
            <Text>
              {`Logged (last week / total): ${statsData?.vistSystemsStats.loggedLastWeek} / ${statsData?.vistSystemsStats.loggedTotal}`}
            </Text>
          </FlexibleItem>
          <FlexibleItem>
            <Text>
              {`Total logs:  ${statsData?.vistSystemsStats.logsTotal}`}
            </Text>
            <Text>
              {`Last week: ${statsData?.vistSystemsStats.logsLastWeek}`}
            </Text>
            <Text>
              {`Last year: ${statsData?.vistSystemsStats.logsLastYear}`}
            </Text>
          </FlexibleItem>
          <FlexibleItem>
            <Text>
              {`Custom period: ${
                !logsNumberError && !logsNumberLoading && logsNumberData
                  ? logsNumberData?.vistSystemsLogsNumberByPeriod
                  : "-"
              }`}
            </Text>
            <DateRangePicker
              startDate={fromLogsFilterDate}
              endDate={toLogsFilterDate}
              onChange={(start: Date | null, end: Date | null) => {
                handleLogsFilterDateRangeChange(
                  start ? start : fromLogsFilterDate,
                  end ? end : toLogsFilterDate
                );
              }}
            />
          </FlexibleItem>
        </FlexibleContainer>
      )}
    </ExpandableCard>
  );
};

export default LogsStats;
