import React from "react";
import styled from "styled-components";
import { SemiBoldText } from "./typography";

interface ToggleButtonProps {
  onChange: () => void;
  label: string;
  checked: boolean;
}

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.sizes.spacing1};
  cursor: pointer;
`;

const Toggle = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: ${(props) => props.theme.colors.midGrey};
  border-radius: 32px;
  padding: ${(props) => props.theme.sizes.spacing0_5};
  transition: ${(props) => props.theme.transitions.inOut};

  &:before {
    transition: ${(props) => props.theme.transitions.inOut};
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 35px;
    top: 50%;
    left: ${(props) => props.theme.sizes.spacing0_5};
    background: ${(props) => props.theme.colors.white};
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Toggle} {
    background: ${(props) => props.theme.colors.success300};

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

const ToggleButton = ({ onChange, label, checked }: ToggleButtonProps) => {
  return (
    <Label>
      <SemiBoldText>{label}</SemiBoldText>
      <Input checked={checked} type="checkbox" onChange={onChange} />
      <Toggle />
    </Label>
  );
};

export default ToggleButton;
