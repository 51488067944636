import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { PageLayout } from "../../components/Page/PageLayout";
import SidebarCard from "../../components/shared/sidebar-card/SidebarCard";
import { SectionHeader } from "../../components/Page/SectionHeader";
import { useKeycloak } from "@react-keycloak/web";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import LearningSpaces from "./LearningSpaces";
import Customers from "./Customers";
import { StyledLink } from "../../components/shared/typography";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import Logbook from "./Logbook";
import Attendings from "./Attendings";
import Hospitals from "./Hospitals";
import Systems from "./Systems";
import SyncTalent from "./SyncTalent";
import SidebarListItem from "../../components/shared/sidebar-card/SidebarListItem";
import Groups from "./Groups";
import { gql, QueryResult, useQuery } from "@apollo/client";
import { Customer } from "../../generated/graphql";
import irLogEnabledForCustomers from "../../shared/irLogEnabledForCustomers.json";
import CourseListings from "./CourseListings";
import CloudCaseResourceAccessVouchers from "./CloudCaseResourceAccessVouchers";
import CloudCaseMachines from "./CloudCaseMachines";
import Events from "./Events";
import S3FileManager from "../../components/S3UI/S3FileManager";

export interface RouteObj {
  path: string;
  exact: boolean;
  name: string;
  main: React.JSXElementConstructor<unknown>;
}

const Administration = () => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak?.tokenParsed;
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const isSupport = haveRole(user, "support");
  const isProduct = haveRole(user, "product");
  const isQA = haveRole(user, "qa");
  const match = useRouteMatch();

  const API_BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:3001" : "";

  const {
    data: customerData,
  }: QueryResult<{
    customers: Customer[];
  }> = useQuery(
    gql`
      query FetchCustomers {
        customers {
          name
        }
      }
    `
  );

  const routes: RouteObj[] = [];
  if (isCustomerAdmin || isSysAdmin) {
    routes.push({
      path: `${match.path}/accounts`,
      exact: true,
      name: "Accounts",
      main: () => <Customers />,
    });
  }
  if (isSysAdmin) {
    routes.push({
      path: `${match.path}/learning-spaces`,
      exact: true,
      name: "Learning Spaces",
      main: () => <LearningSpaces />,
    });
    routes.push({
      path: `${match.path}/cloud-case-machines`,
      exact: true,
      name: "Cloud Case VMs",
      main: () => <CloudCaseMachines />,
    });
  }
  if (isGroupAdmin || isCustomerAdmin || isSysAdmin) {
    routes.push({
      path: `${match.path}/groups`,
      exact: true,
      name: "Groups",
      main: () => <Groups />,
    });
  }
  if (isSupport || isProduct || isQA || isSysAdmin) {
    routes.push({
      path: `${match.path}/s3-management`,
      exact: true,
      name: "S3 File Management",
      main: () => <S3FileManager />,
    });
  }
  
  if (isSysAdmin || isProduct) {
    routes.push({
      path: `${match.path}/ml2`,
      exact: true,
      name: "ML2",
      main: () => {
        window.open(
          API_BASE_URL + "/ml2-frontend",
          "_blank"

        );
        return null;
      },
    });
  }
  
  // TODO this should probably be switched for IR Log feature flag
  const shouldShowLogbook = customerData?.customers.some((customer) =>
    irLogEnabledForCustomers.names.includes(customer.name)
  );
  const shouldShowCloudCases = customerData?.customers.some(
    (customer) =>
      customer.name === "Db-seed customer" || customer.name === "Medtronic"
  );
  if ((shouldShowLogbook && isCustomerAdmin) || isSysAdmin) {
    routes.push(
      {
        path: `${match.path}/logbook`,
        exact: true,
        name: "Logbook",
        main: () => <Logbook />,
      },
      {
        path: `${match.path}/logbook/attendings/:id`,
        exact: true,
        name: "Attendings",
        main: () => <Attendings />,
      },
      {
        path: `${match.path}/logbook/hospitals/:id`,
        exact: true,
        name: "Hospitals",
        main: () => <Hospitals />,
      }
    );
  }
  if (shouldShowCloudCases && !isSysAdmin) {
    routes.push({
      path: `${match.path}/cloud-case-vouchers`,
      exact: true,
      name: "Cloud Cases",
      main: () => <CloudCaseResourceAccessVouchers />,
    });
  }
  // Is group admin of customer group (or member, but members cannot access admin panel) or customer admin
  if (shouldShowCloudCases && !isSysAdmin) {
    routes.push({
      path: `${match.path}/events`,
      exact: true,
      name: "Events",
      main: () => <Events />,
    });
  }
  if (isSysAdmin) {
    routes.push(
      {
        path: `${match.path}/courses`,
        exact: true,
        name: "Courses",
        main: () => <CourseListings />,
      },
      {
        path: `${match.path}/cloud-case-vouchers`,
        exact: true,
        name: "Cloud Cases",
        main: () => <CloudCaseResourceAccessVouchers />,
      },
      {
        path: `${match.path}/events`,
        exact: true,
        name: "Events",
        main: () => <Events />,
      },
      {
        path: `${match.path}/talent`,
        exact: true,
        name: "Talent",
        main: () => <SyncTalent />,
      },
      {
        path: `${match.path}/systems`,
        exact: true,
        name: "Systems",
        main: () => <Systems />,
      }
    );
  }

  const [breadcrumb, setBreadcrumb] = useState({
    path: routes[0]?.path ?? "",
    name: routes[0]?.name ?? "",
  });

  const ml2Url = (() => {
    switch (process.env.REACT_APP_ENV) {
      case "production":
        return "https://portal.mentice.com/ml2-frontend/";
      case "staging":
        return "https://portal.mentice-staging.com/ml2-frontend/";
      default:
        return "http://localhost:5173/ml2-frontend/";
    }
  })();

  const sidebarList = routes
    .filter(
      // Hide attendings and hospitals routes from sidebar — they're loaded as "submenus" from Logbook
      (route) => route.name !== "Attendings" && route.name !== "Hospitals"
    )

    .map((route) => {
      return (
        <SidebarListItem
          active={location.pathname === route.path}
          key={route.name}
        >
          {route.name === "ML2" ? (
            <StyledLink
              id={`sidebar-${route.name.replace(" ", "-").toLowerCase()}`}
              to="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(ml2Url, "_blank")?.focus();
              }}
            >
              {route.name}
            </StyledLink>
          ) : (
            <StyledLink
              id={`sidebar-${route.name.replace(" ", "-").toLowerCase()}`}
              to={route.path}
              onClick={() =>
                setBreadcrumb({ path: route.path, name: route.name })
              }
            >
              {route.name}
            </StyledLink>
          )}
        </SidebarListItem>
      );
    });

  if (!(isGroupAdmin || isCustomerAdmin || isSysAdmin || isSupport || isProduct || isQA)) {
    return <h1>You do not have the necessary rights to view this page.</h1>;
  } else {
    return (
      <PageLayout title="Mentice Live | Administration">
        <Breadcrumbs
          crumbs={[
            {
              path: `${match.path}`,
              name: "Administration",
            },
            {
              path: `${breadcrumb.path}`,
              name: breadcrumb.name,
            },
            ...(location.pathname.includes("attendings")
              ? [
                  {
                    path: `${match.path}/attendings/:id`,
                    name: "Attendings",
                  },
                ]
              : []),
            ...(location.pathname.includes("hospitals")
              ? [
                  {
                    path: `${match.path}/hospitals/:id`,
                    name: "Hospitals",
                  },
                ]
              : []),
          ]}
        />
        <SectionHeader title="Administration">
          <SidebarCard sidebarList={sidebarList}>
            {(isGroupAdmin || isCustomerAdmin || isSysAdmin || isSupport || isProduct || isQA) && (
              <Switch>
                <Redirect exact from={match.path} to={routes[0].path} />
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.main />}
                  />
                ))}
              </Switch>
            )}
          </SidebarCard>
        </SectionHeader>
      </PageLayout>
    );
  }
};

export default Administration;
