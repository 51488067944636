import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { X, Maximize2, Minimize2, Download } from 'lucide-react';
import { createPortal } from 'react-dom';
import { H4 } from '../shared/typography';
import LoadingSpinner from '../shared/LoadingIndicator';

interface PDFViewerProps {
  url: string;
  onClose: () => void;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

interface OverlayProps {
  isFullscreen: boolean;
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${props => props.isFullscreen ? '0.9' : '0.75'});
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.2s ease-out;
  backdrop-filter: blur(5px);
  padding: ${props => props.isFullscreen ? '0' : '2rem'};
  transition: all 0.3s ease;
`;

interface ViewerProps {
  isFullscreen: boolean;
}

const Viewer = styled.div<ViewerProps>`
  background-color: white;
  border-radius: ${props => props.isFullscreen ? '0' : '12px'};
  width: ${props => props.isFullscreen ? '100vw' : '95vw'};
  max-width: ${props => props.isFullscreen ? '100vw' : '1200px'};
  height: ${props => props.isFullscreen ? '100vh' : '85vh'};
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: ${slideUp} 0.3s ease-out;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    width: ${props => props.isFullscreen ? '100vw' : '80vw'};
  }

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 90vh;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 8px;
  border-radius: 6px;
  color: #666;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #eee;
    color: #000;
  }

  &:active {
    transform: scale(0.95);
  }
`;

const IframeContainer = styled.div`
  flex: 1;
  overflow: hidden;
  background: white;
  position: relative;
  
  iframe {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const PDFViewer: React.FC<PDFViewerProps> = ({ url, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isFullscreen) {
          setIsFullscreen(false);
        } else {
          onClose();
        }
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose, isFullscreen]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // const handleDownload = async () => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     const downloadUrl = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = downloadUrl;
  //     link.download = url.split('/').pop() || 'document.pdf';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(downloadUrl);
  //   } catch (error) {
  //     console.error('Error downloading PDF:', error);
  //   }
  // };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return createPortal(
    <Overlay 
      onClick={() => !isFullscreen && onClose()} 
      isFullscreen={isFullscreen}
    >
      <Viewer 
        onClick={e => e.stopPropagation()}
        isFullscreen={isFullscreen}
      >
        <Header>
          <H4 style={{ marginRight: 'auto' }}>
            Viewing: "{decodeURIComponent(url.split('/').pop()?.split('.pdf')[0] || 'PDF Viewer')}.pdf"
          </H4>
          {/* <ActionButton onClick={handleDownload} title="Download PDF">
            <Download size={20} />
          </ActionButton> */}
          <ActionButton 
            onClick={() => setIsFullscreen(!isFullscreen)}
            title={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
          >
            {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
          </ActionButton>
          <ActionButton onClick={onClose} title="Close">
            <X size={20} />
          </ActionButton>
        </Header>
        <IframeContainer>
          <iframe 
            src={`${url}#toolbar=0`}
            title="PDF Viewer"
            onLoad={handleIframeLoad}
          />
          {isLoading && (
            <LoadingOverlay>
              <LoadingSpinner />
            </LoadingOverlay>
          )}
        </IframeContainer>
      </Viewer>
    </Overlay>,
    document.body
  );
};

export default PDFViewer;