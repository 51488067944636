import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FileText, Book, AlertCircle, Loader, Info } from 'lucide-react';
import { useKeycloak } from "@react-keycloak/web";
import { LinkContainer, DocLink, StateContainer } from './DocumentationLinksStyles';
import PDFViewer from '../S3UI/PDFViewer';

const API_BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "";

interface DocumentationLinksProps {
  softwareCode: string;
  version: string;
}

interface DocumentFile {
  url: string;
  type: 'manual' | 'step by step' | 'release notes';
}

const DocumentationLoadingState: React.FC = () => (
  <StateContainer>
    <Loader size={16} className="animate-spin" />
    <span>Loading documentation...</span>
  </StateContainer>
);

const DocumentationErrorState: React.FC<{ message: string }> = ({ message }) => (
  <StateContainer>
    <AlertCircle size={16} />
    <span>{message}</span>
  </StateContainer>
);

const DocumentationLinks: React.FC<DocumentationLinksProps> = ({ 
  softwareCode,
  version
}) => {
  const [docs, setDocs] = useState<DocumentFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { keycloak } = useKeycloak();
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [blobUrls, setBlobUrls] = useState<string[]>([]);

  useEffect(() => {
    const fetchDocs = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const url = `${API_BASE_URL}/api/s3/documentation/${encodeURIComponent(softwareCode)}/${encodeURIComponent(version)}`;
        
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        });

        if (Object.keys(response.data).length === 0) {
          setError('No documentation available for this version');
          setDocs([]);
          return;
        }

        const availableDocs: DocumentFile[] = [];
        
        if (response.data.manual) {
          availableDocs.push({ url: response.data.manual, type: 'manual' });
        }
        if (response.data['step by step']) {
          availableDocs.push({ url: response.data['step by step'], type: 'step by step' });
        }
        if (response.data['release notes']) {
          availableDocs.push({ url: response.data['release notes'], type: 'release notes' });
        }

        setDocs(availableDocs);
      } catch (error) {
        console.error('Error fetching documentation:', error);
        setError('Unable to fetch documentation');
      } finally {
        setIsLoading(false);
      }
    };

    if (softwareCode && version && keycloak.token) {
      fetchDocs();
    }
  }, [softwareCode, version, keycloak.token]);

  useEffect(() => {
    return () => {
      blobUrls.forEach(url => {
        if (url.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [blobUrls]);

  const handlePdfSelection = (url: string) => {
    if (url.startsWith('blob:')) {
      setBlobUrls(prev => [...prev, url]);
    }
    setSelectedPdf(url);
  };

  if (isLoading) {
    return <DocumentationLoadingState />;
  }

  if (error) {
    return <DocumentationErrorState message={error} />;
  }

  if (docs.length === 0) {
    return (
      <StateContainer>
        <Info size={16} />
        <span>No documentation available for this version</span>
      </StateContainer>
    );
  }

  const getIcon = (type: string) => {
    switch (type) {
      case 'manual':
        return <Book />;
      case 'release notes':
        return <FileText />;
      default:
        return <FileText />;
    }
  };

  const getDisplayName = (type: string) => {
    switch (type) {
      case 'manual':
        return 'Manual';
      case 'step by step':
        return 'Step By Step';
      case 'release notes':
        return 'Release Notes';
      default:
        return type;
    }
  };

  const DocLinkWithLoading: React.FC<{ doc: DocumentFile; onClick: () => void }> = ({ doc, onClick }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
      setIsLoading(true);
      onClick();
      setIsLoading(false);
    };

    return (
      <DocLink 
        key={doc.type}
        onClick={handleClick}
        style={{ cursor: isLoading ? 'wait' : 'pointer' }}
        disabled={isLoading}
      >
        {isLoading ? <Loader size={16} className="animate-spin" /> : getIcon(doc.type)}
        {getDisplayName(doc.type)}
      </DocLink>
    );
  };

  return (
    <>
      <LinkContainer>
        {docs.map((doc) => (
          <DocLinkWithLoading 
            key={doc.type}
            doc={doc}
            onClick={() => handlePdfSelection(doc.url)}
          />
        ))}
      </LinkContainer>

      {selectedPdf && (
        <PDFViewer 
          url={selectedPdf} 
          onClose={() => setSelectedPdf(null)} 
        />
      )}
    </>
  );
};

export default DocumentationLinks;
