import React from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client/errors";
import { useRouteMatch } from "react-router-dom";
import { VistActivityItem as VistActivityItemEntity } from "../../generated/graphql";
import { Section } from "../../components/Page/Section";
import { StyledLink, LargeText } from "../../components/shared/typography";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import { VistActivityItem } from "../../components/VistActivity/VistActivityItem";

interface ActivitiesSectionProps {
  loading: boolean;
  error: ApolloError | undefined;
  shownActivities: VistActivityItemEntity[] | undefined;
  viewAllActivities: boolean;
  offset: number;
  limit: number;
}

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span > p {
    margin: 2px 0 0 0;
    text-align: right;
    padding: 0;
    opacity: 0.75;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.colors.grey};
  }
`;

const GreyLargeText = styled(LargeText)`
  color: #909090;
`;

const ActivitiesSection = ({
  loading,
  error,
  shownActivities = [],
  viewAllActivities,
  offset,
  limit
}: ActivitiesSectionProps) => {
  const match = useRouteMatch();

  const activities = viewAllActivities ? shownActivities : shownActivities.slice(offset, offset + limit )

  return (
    <Section maxColumns={1}>
      {/* List activity log items */}
      {loading ? (
        <LoadingIndicator />
      ) : activities?.length ? (
        !error &&
        activities
          .map((vistActivityItem: VistActivityItemEntity) => (
            <ActivityWrapper key={vistActivityItem.id}>
              <StyledLink
                to={`${match.path}/vist-activity/${vistActivityItem.id}`}
              >
                <VistActivityItem activity={vistActivityItem} />
              </StyledLink>
            </ActivityWrapper>
          ))
      ) : (
        <GreyLargeText style={{ marginTop: "32px", marginBottom: "8px" }}>
          No logs found, try clearing the filters or reloading the page
        </GreyLargeText>
      )}
    </Section>
  );
};

export default ActivitiesSection;
