import React, { useCallback } from "react";
import { debounce } from "lodash";
import { Option } from "react-select/src/filters";
import { CustomerGroup as CustomerGroupEntity } from "../../generated/graphql";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import { ButtonSizes } from "../../shared/enums";
import Download from "../../components/icons/Download";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import Button from "../../components/shared/Button";
import Checkbox from "../../components/shared/Checkbox";
import { H4 } from "../../components/shared/typography";
import TextField from "../../components/shared/TextField";
import CheckboxSpacer from "../../components/CheckboxSpacer";
import SelectField from "../../components/shared/SelectField";
import ToggleButton from "../../components/shared/ToggleButton";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
interface ActionsSectionProps {
  checkedGroups: number[];
  selectedSortOption: Option;
  shownCustomersGroup: CustomerGroupEntity[] | undefined;
  sortOrderOptions: Option[];
  user: KeycloakAccessToken | undefined;
  viewAllActivities: boolean;
  setCheckedGroups: (checked: number[]) => void;
  setSearchText: (search: string) => void;
  setSelectedSortOption: (option: Option) => void;
  handleViewAllActivitiesToggle: () => void;
  onClickDownloadActivities: () => void;
}

const ActionsSection = ({
  checkedGroups,
  selectedSortOption,
  shownCustomersGroup,
  sortOrderOptions,
  user,
  viewAllActivities,
  setCheckedGroups,
  setSearchText,
  setSelectedSortOption,
  handleViewAllActivitiesToggle,
  onClickDownloadActivities,
}: ActionsSectionProps) => {
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");

  const request = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouceRequest = useCallback((value) => request(value), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    debouceRequest(e.currentTarget.value);
  };

  return (
    <ActionBar
      backTo="/"
      title={"Activity Log"}
      searchField={
        <TextField
          searchable
          onClearSearch={() => {
            setSearchText("");
          }}
          placeholder="Search by Course or Exercise Name"
          onChange={onChange}
          id="cy-vist-search-field"
        />
      }
      button={<></>}
      buttonGroup={
        <>
          {(isGroupAdmin || isCustomerAdmin) && (
            <>
              <ToggleButton
                label="View all"
                onChange={handleViewAllActivitiesToggle}
                checked={viewAllActivities}
              />
              <Button
                icon={<Download />}
                secondary
                onClick={onClickDownloadActivities}
                size={ButtonSizes.Medium}
              />
            </>
          )}
          <SelectFieldWidthProvider>
            <SelectField
              name="Sort by"
              isActionBar={true}
              onChange={(option: Option) => setSelectedSortOption(option)}
              value={{
                value: selectedSortOption.value,
                label: `Sort: ${selectedSortOption.label}`,
                data: {
                  sortField: selectedSortOption.data.sortField,
                  sortOrder: selectedSortOption.data.sortOrder,
                },
              }}
              options={sortOrderOptions}
            />
          </SelectFieldWidthProvider>
          {isGroupAdmin && (
            <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
              <FilterContainer onClear={() => setCheckedGroups([])}>
                <H4 style={{ marginBottom: "24px" }}>Groups</H4>
                <CheckboxSpacer>
                  {shownCustomersGroup?.map((group: CustomerGroupEntity) => (
                    <Checkbox
                      key={group.id}
                      title={group.name}
                      checkboxChecked={checkedGroups?.includes(group.id)}
                      onCheckboxClick={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) =>
                        e.currentTarget.checked
                          ? setCheckedGroups([...checkedGroups, group.id])
                          : setCheckedGroups(
                              checkedGroups?.filter((id) => id !== group.id)
                            )
                      }
                    />
                  ))}
                </CheckboxSpacer>
              </FilterContainer>
            </DropdownMenu>
          )}
        </>
      }
    />
  );
};

export default ActionsSection;
