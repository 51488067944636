import React, { useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { VIST_AVAILABLE_RELEASES_QUERY, VIST_INSTALLED_RELEASES_QUERY } from "../queries/updateQuery";
import { useUpdateStore } from "../stores/updateStore";
import { getServiceTag } from "../lib/getServiceTag";
import ToastService from "../services/toastService";

const INITIAL_POLL_INTERVAL = 3600000; // 1 hour
const MAX_POLL_INTERVAL = 86400000; // 24 hours
const BACKOFF_MULTIPLIER = 2;

const UpdateNotifier: React.FC = () => {
  const [currentPollInterval, setCurrentPollInterval] = React.useState(INITIAL_POLL_INTERVAL);
  const serviceTag = getServiceTag();
  const { setCurrentStates, checkForUpdates } = useUpdateStore();

  const { data, error, startPolling, stopPolling } = useQuery(
    VIST_INSTALLED_RELEASES_QUERY,
    {
      variables: { serviceTag },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleNewData = useCallback(() => {
    if (!data?.vistAvailableReleasesForSystem || !setCurrentStates) {
      return;
    }
    try {
      setCurrentStates(data.vistAvailableReleasesForSystem);
      checkForUpdates();
    } catch (error) {
      console.error('Error handling update data:', error);
      ToastService.error('Failed to process update information');
    }
  }, [data, setCurrentStates, checkForUpdates]);

  useEffect(() => {
    handleNewData();
  }, [handleNewData]);

  useEffect(() => {
    startPolling(currentPollInterval);
    
    // Backoff strategy
    const backoffTimeout = setTimeout(() => {
      const newInterval = Math.min(currentPollInterval * BACKOFF_MULTIPLIER, MAX_POLL_INTERVAL);
      setCurrentPollInterval(newInterval);
    }, currentPollInterval);

    return () => {
      stopPolling();
      clearTimeout(backoffTimeout);
    };
  }, [currentPollInterval, startPolling, stopPolling]);

  useEffect(() => {
    if (error) {
      console.error("Error fetching updates:", error);
      ToastService.error("Failed to check for updates. Retrying...");
    }
  }, [error]);

  return null;
};

export default UpdateNotifier;
