import React, { useState, useEffect, useCallback, useRef } from "react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  CancelTokenSource,
} from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Upload, FolderPlus } from "lucide-react";
import { Redirect } from "react-router-dom";
import { ButtonSizes, SortOrder } from "../../shared/enums";
import TextField from "../shared/TextField";
import SelectField from "../shared/SelectField";
import LoadingIndicator from "../shared/LoadingIndicator";
import { SelectFieldWidthProvider } from "../shared/ActionBar";
import { toast } from "react-hot-toast";
import { S3FileCard } from "./S3FileCard";
import { ChevronLeft } from "lucide-react";
import {
  Container,
  Header,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbSeparator,
  ActionBar,
  SearchWrapper,
  ButtonGroup,
  BackButton,
  UploadButton,
  CreateFolderButton,
  FileGrid,
  ErrorMessage,
} from "./S3FileManagerStyles";
import ConfirmationDialog from "./ConfirmationDialog";

interface S3File {
  Key: string;
  Size: number;
  LastModified: string;
  IsDirectory: boolean;
}

const API_BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:3001" : "";

const S3FileManager: React.FC = () => {
  const { keycloak } = useKeycloak();
  const [files, setFiles] = useState<S3File[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState({
    value: "Date",
    label: "Date",
    data: { sortOrder: SortOrder.DateDesc },
  });
  const [currentPath, setCurrentPath] = useState("");
  const [canWrite, setCanWrite] = useState(false);
  const [hasProductRole, setHasProductRole] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);
  const isMounted = useRef(true);

  const setStateIfMounted = useCallback(
    (setter: React.Dispatch<React.SetStateAction<any>>) => {
      return (value: any) => {
        if (isMounted.current) {
          setter(value);
        }
      };
    },
    []
  );

  const fetchFiles = useCallback(
    async (cancelToken: CancelTokenSource) => {
      setStateIfMounted(setLoading)(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/s3/files`, {
          ...getAxiosConfig(),
          params: { prefix: currentPath },
          cancelToken: cancelToken.token,
        });
        setStateIfMounted(setFiles)(response.data);
        setStateIfMounted(setError)(null);
      } catch (err) {
        if (!axios.isCancel(err) && isMounted.current) {
          handleError(err, "Failed to fetch files");
        }
      }
      setStateIfMounted(setLoading)(false);
    },
    [currentPath, setStateIfMounted]
  );

  useEffect(() => {
    isMounted.current = true;
    const cancelToken = axios.CancelToken.source();
    fetchFiles(cancelToken);

    return () => {
      isMounted.current = false;
      cancelToken.cancel("Component unmounted");
    };
  }, [fetchFiles]);

  useEffect(() => {
    setCanWrite(
      keycloak.hasRealmRole("product") || keycloak.hasRealmRole("sys_admin")
    );
    setHasProductRole(
      keycloak.hasRealmRole("product") || keycloak.hasRealmRole("sys_admin")
    );
  }, [keycloak]);

  const handleError = useCallback(
    (err: unknown, customMessage: string) => {
      console.error("Detailed error:", err);
      if (err && typeof err === "object" && "isAxiosError" in err) {
        const axiosError = err as AxiosError;
        const errorMessage = `${customMessage} (Status: ${
          axiosError.response?.status || "Unknown"
        }): ${axiosError.message}`;
        console.error(errorMessage);
        setStateIfMounted(setError)(errorMessage);
      } else if (err instanceof Error) {
        const errorMessage = `${customMessage}: ${err.message}`;
        console.error(errorMessage);
        setStateIfMounted(setError)(errorMessage);
      } else {
        const errorMessage = `${customMessage}: An unexpected error occurred.`;
        console.error(errorMessage);
        setStateIfMounted(setError)(errorMessage);
      }
      if (isMounted.current) {
        toast.error(customMessage);
      }
    },
    [setStateIfMounted]
  );

  const getAxiosConfig = (): AxiosRequestConfig => ({
    headers: {
      authorization: `Bearer ${keycloak.token}`,
    },
  });

  const handleFolderClick = (folderPath: string) => {
    setCurrentPath(folderPath);
  };

  const handleBackClick = () => {
    const pathParts = currentPath.split("/").filter(Boolean);
    pathParts.pop();
    const newPath = pathParts.join("/");
    setCurrentPath(newPath ? `${newPath}/` : "");
  };

  const handleDownload = async (fileKey: string) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/s3/getPreSignedUrl/${encodeURIComponent(fileKey)}`,
        getAxiosConfig()
      );
      const preSignedUrl = response.data.url;

      if (preSignedUrl) {
        window.open(preSignedUrl, "_blank");
        toast.success(`File ${fileKey} download started`);
      } else {
        throw new Error("Pre-signed URL is empty");
      }
    } catch (err) {
      handleError(err, "Failed to initiate file download");
    }
  };

  const handleDeleteClick = (fileKey: string) => {
    const fullPath = fileKey.startsWith(currentPath)
      ? fileKey
      : `${currentPath}${fileKey}`;
    setFileToDelete(fullPath);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (fileToDelete) {
      try {
        const fullPath = fileToDelete.startsWith(currentPath)
          ? fileToDelete
          : `${currentPath}${fileToDelete}`;
        await axios.delete(
          `${API_BASE_URL}/api/s3/delete/${encodeURIComponent(fullPath)}`,
          getAxiosConfig()
        );
        const cancelToken = axios.CancelToken.source();
        fetchFiles(cancelToken);
        if (isMounted.current) {
          toast.success(`File ${fileToDelete} deleted successfully`);
        }
      } catch (err) {
        handleError(err, "Failed to delete file");
      }
    }
    setStateIfMounted(setIsDeleteConfirmOpen)(false);
    setStateIfMounted(setFileToDelete)(null);
  }, [fileToDelete, currentPath, fetchFiles, handleError, setStateIfMounted]);

  const handleRename = async (oldFileKey: string) => {
    const newFileKey = prompt("Enter new file name:", oldFileKey);
    if (newFileKey && newFileKey !== oldFileKey) {
      try {
        await axios.put(
          `${API_BASE_URL}/api/s3/rename/${oldFileKey}`,
          { newFileKey },
          getAxiosConfig()
        );
        const cancelToken = axios.CancelToken.source();
        fetchFiles(cancelToken);
        toast.success(`File renamed from ${oldFileKey} to ${newFileKey}`);
      } catch (err) {
        handleError(err, "Failed to rename file");
      }
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        await axios.post(`${API_BASE_URL}/api/s3/upload`, formData, {
          ...getAxiosConfig(),
          headers: {
            ...getAxiosConfig().headers,
            "Content-Type": "multipart/form-data",
          },
          params: { fileKey: `${currentPath}${file.name}` },
        });
        const cancelToken = axios.CancelToken.source();
        fetchFiles(cancelToken);
        toast.success(`File ${file.name} uploaded successfully`);
      } catch (err) {
        handleError(err, "Failed to upload file");
      }
    }
  };

  const sortOrderOptions = [
    { value: "Date", label: "Date", data: { sortOrder: SortOrder.DateDesc } },
    {
      value: "Name (A-Z)",
      label: "Name (A-Z)",
      data: { sortOrder: SortOrder.AZ },
    },
    {
      value: "Name (Z-A)",
      label: "Name (Z-A)",
      data: { sortOrder: SortOrder.ZA },
    },
  ];

  const filteredFiles = files
    .filter((file) => file.Key.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      switch (selectedSortOption.data.sortOrder) {
        case SortOrder.AZ:
          return a.Key.localeCompare(b.Key);
        case SortOrder.ZA:
          return b.Key.localeCompare(a.Key);
        case SortOrder.DateDesc:
        default:
          return (
            new Date(b.LastModified).getTime() -
            new Date(a.LastModified).getTime()
          );
      }
    });

  const hasRequiredRole =
    keycloak.hasRealmRole("support") ||
    keycloak.hasRealmRole("qa") ||
    keycloak.hasRealmRole("product") ||
    keycloak.hasRealmRole("sys_admin");

  if (!hasRequiredRole) {
    console.error(
      "You do not have the necessary permissions to access this page. Please contact your administrator if you believe this is an error."
    );
    return <Redirect to="/" />;
  }

  const handleCreateFolder = async () => {
    const folderName = prompt("Enter folder name:");
    if (folderName) {
      try {
        await axios.post(
          `${API_BASE_URL}/api/s3/createFolder`,
          { folderPath: `${currentPath}${folderName}/` },
          getAxiosConfig()
        );
        const cancelToken = axios.CancelToken.source();
        fetchFiles(cancelToken);
        toast.success(`Folder ${folderName} created successfully`);
      } catch (err) {
        handleError(err, "Failed to create folder");
      }
    }
  };

  const handleView = async (fileKey: string) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/s3/getPreSignedUrl/${encodeURIComponent(fileKey)}`,
        getAxiosConfig()
      );
      const preSignedUrl = response.data.url;

      if (preSignedUrl) {
        toast.success(`Opening PDF: ${fileKey}`);
      } else {
        throw new Error("Pre-signed URL is empty");
      }
    } catch (err) {
      handleError(err, "Failed to view PDF");
    }
  };

  const getSignedUrl = async (fileKey: string) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/s3/getPreSignedUrl/${encodeURIComponent(fileKey)}`,
        getAxiosConfig()
      );
      return response.data.url;
    } catch (err) {
      handleError(err, "Failed to generate signed URL");
      throw err;
    }
  };

  return (
    <Container>
      <Header>
        <Title>
          {loading
            ? "S3 File Management"
            : `S3 File Management (${filteredFiles.length})`}
        </Title>
        <Breadcrumb>
          <BreadcrumbItem onClick={() => setCurrentPath("")}>
            Root
          </BreadcrumbItem>
          {currentPath
            .split("/")
            .filter(Boolean)
            .map((folder, index, array) => (
              <React.Fragment key={folder}>
                <BreadcrumbSeparator>/</BreadcrumbSeparator>
                <BreadcrumbItem
                  onClick={() =>
                    setCurrentPath(array.slice(0, index + 1).join("/") + "/")
                  }
                >
                  {folder}
                </BreadcrumbItem>
              </React.Fragment>
            ))}
        </Breadcrumb>
        <ActionBar>
          <SearchWrapper>
            <TextField
              searchable
              onClearSearch={() => setSearchTerm("")}
              placeholder="Search files..."
              onChange={(e) =>
                setSearchTerm((e.target as HTMLInputElement).value)
              }
            />
          </SearchWrapper>
          <ButtonGroup>
            {currentPath && (
              <BackButton onClick={handleBackClick} size={ButtonSizes.Small}>
                <ChevronLeft size={16} />
                <span>Back</span>
              </BackButton>
            )}
            {canWrite && (
              <>
                <UploadButton size={ButtonSizes.Small}>
                  <label>
                    <Upload size={16} />
                    <span>Upload File</span>
                    <input type="file" onChange={handleUpload} />
                  </label>
                </UploadButton>
                <CreateFolderButton
                  onClick={handleCreateFolder}
                  size={ButtonSizes.Small}
                >
                  <FolderPlus size={16} />
                  <span>Create Folder</span>
                </CreateFolderButton>
              </>
            )}
          </ButtonGroup>
          <SelectFieldWidthProvider>
            <SelectField
              name="Sort by"
              isActionBar={true}
              onChange={(option: any) => setSelectedSortOption(option)}
              value={selectedSortOption}
              options={sortOrderOptions}
            />
          </SelectFieldWidthProvider>
        </ActionBar>
      </Header>
      {loading && <LoadingIndicator />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <FileGrid>
        {filteredFiles.map((file) => (
          <S3FileCard
            key={file.Key}
            file={file}
            onDownload={handleDownload}
            onDelete={hasProductRole ? handleDeleteClick : undefined}
            onRename={hasProductRole ? handleRename : undefined}
            onFolderClick={handleFolderClick}
            onView={handleView}
            getSignedUrl={getSignedUrl}
          />
        ))}
      </FileGrid>
      <ConfirmationDialog
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onConfirm={handleDeleteConfirm}
        message={`Are you sure you want to delete ${fileToDelete}? This action cannot be undone.`}
      />
    </Container>
  );
};

export default S3FileManager;
