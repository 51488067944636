import { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useUpdateStore } from '../../../stores/updateStore';
import { VistSystemSoftwareRelease } from '../types';
import ToastService from '../../../services/toastService';

const SET_DOWNLOAD_MUTATION = gql`
  mutation VistSystemSoftwareReleaseSetDownload($id: ID!) {
    vistSystemSoftwareReleaseSetDownload(id: $id) {
      code
      success
      message
    }
  }
`;

const SET_INSTALL_MUTATION = gql`
  mutation VistSystemSoftwareReleaseSetInstall($id: ID!) {
    vistSystemSoftwareReleaseSetInstall(id: $id) {
      code
      success
      message
    }
  }
`;

export const useUpdateOperations = (updates: VistSystemSoftwareRelease[], reloadUpdates: () => void) => {
  const [isRunning, setIsRunning] = useState(false);
  const { operationStates, setOperationState, updateSoftwareState } = useUpdateStore();

  const [setDownload] = useMutation(SET_DOWNLOAD_MUTATION, {
    onCompleted: () => {
      reloadUpdates();
    },
    onError(err) {
      console.error(err);
      ToastService.error('Failed to initiate download. Please try again.');
    },
  });

  const [setInstalled] = useMutation(SET_INSTALL_MUTATION, {
    onCompleted: () => {
      reloadUpdates();
    },
    onError(err) {
      console.error(err);
      ToastService.error('Failed to initiate installation. Please try again.');
    },
  });

  const handleOperationCompletion = (update: VistSystemSoftwareRelease) => {
    const currentState = update.state.operationStates;
    const prevState = operationStates[update.id];
    const softwareName = update.release.software.name;

    if (currentState?.download?.state === 'FAILED' && prevState?.action === 'download' && prevState?.state !== 'FAILED') {
      ToastService.updateOperation('download', 'failed', softwareName);
    } else if (currentState?.download?.state === 'COMPLETE' && prevState?.action === 'download' && prevState?.state !== 'COMPLETED') {
      ToastService.updateOperation('download', 'completed', softwareName);
    } else if (currentState?.install?.state === 'FAILED' && prevState?.action === 'install' && prevState?.state !== 'FAILED') {
      ToastService.updateOperation('install', 'failed', softwareName);
    } else if (currentState?.install?.state === 'COMPLETE' && prevState?.action === 'install' && prevState?.state !== 'COMPLETED') {
      ToastService.updateOperation('install', 'completed', softwareName);
    }

    if (!update.state?.operationIsRunning) {
      setOperationState(update.id, { action: '', state: 'IDLE' });
    }
  };

  useEffect(() => {
    if (updates) {
      updates.forEach(handleOperationCompletion);
    }
  }, [updates]);

  useEffect(() => {
    if (isRunning) {
      const opInterval = setInterval(reloadUpdates, 10000);
      return () => clearInterval(opInterval);
    }
  }, [isRunning, reloadUpdates]);

  const handleDownload = (updateId: string) => {
    const update = updates.find((u) => u.id === updateId);
    if (!update) return;

    setDownload({ variables: { id: updateId } });
    setIsRunning(true);
    updateSoftwareState(updateId, 'download');
    setOperationState(updateId, { action: 'download', state: 'RUNNING' });
    ToastService.updateOperation('download', 'started', update.release.software.name);
  };

  const handleInstall = (updateId: string) => {
    const update = updates.find((u) => u.id === updateId);
    if (!update) return;

    setInstalled({ variables: { id: updateId } });
    setIsRunning(true);
    updateSoftwareState(updateId, 'install');
    setOperationState(updateId, { action: 'install', state: 'RUNNING' });
    ToastService.updateOperation('install', 'started', update.release.software.name);
  };

  return {
    isRunning,
    operationStates,
    handleDownload,
    handleInstall,
  };
};
