import React, { useState, useEffect } from "react";
import { CardHeader } from './CardHeader';
import { ActionButtons } from './ActionButtons';
import { InfoGrid } from './InfoGrid';
import { FileText } from "lucide-react";
import { UpdateCardProps } from './types';
import {
  ExpandableUpdateCard,
  CardContent,
  ExpandedContent,
  DocumentationSection,
  DocumentationTitle,
  DocumentationContainer,
  AnimatedCard,
} from "./UpdateCardStyles";
import DocumentationLinks from '../Documentation/DocumentationLinks';
import { TransitionWrapper } from './CelebrationStyles';

const UpdateCard: React.FC<UpdateCardProps> = (props) => {
  const { update, isExpanded, index } = props;
  const [isExiting, setIsExiting] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    if (update.state.installed && props.tabType === 'available') {
      setIsExiting(true);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [update.state.installed, props.tabType]);

  if (!shouldRender) return null;

  return (
    <AnimatedCard index={index}>
      <ExpandableUpdateCard id="cy-customer-card">
        <TransitionWrapper isInstalled={update.state.installed} isExiting={isExiting}>
          <CardContent>
            <CardHeader update={update} />
            <ActionButtons {...props} />
          </CardContent>

          <ExpandedContent isExpanded={isExpanded}>
            <InfoGrid update={update} tabType={update.state.installed ? 'installed' : 'available'} />
            
            <DocumentationSection>
              <DocumentationTitle>
                <FileText size={16} />
                Documentation
              </DocumentationTitle>
              <DocumentationContainer>
                <DocumentationLinks 
                  softwareCode={update.release.software.code}
                  version={update.release.version.semantic}
                />
              </DocumentationContainer>
            </DocumentationSection>
          </ExpandedContent>
        </TransitionWrapper>
      </ExpandableUpdateCard>
    </AnimatedCard>
  );
};

export default UpdateCard;
