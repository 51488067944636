import React, { useState } from "react";
import { PageLayout } from "../../components/Page/PageLayout";
import SidebarCard from "../../components/shared/sidebar-card/SidebarCard";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import { SectionHeader } from "../../components/Page/SectionHeader";
import { StyledLink } from "../../components/shared/typography";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router";
import About from "./About";
import Updates from "./Updates";

import SidebarListItem from "../../components/shared/sidebar-card/SidebarListItem";
import { RouteObj } from "../administration";

interface SystemRoute extends RouteObj {
  onClick: () => void;
}

const SystemPage: React.FC = () => {
  const match = useRouteMatch();

  const routes: SystemRoute[] = [
    {
      path: `${match.path}/about`,
      exact: true,
      name: "About",
      main: () => <About />,
      onClick: () => {
        setBreadcrumb({ path: `${match.path}/about`, name: "About" });
      },
    },
    {
      path: `${match.path}/updates`,
      exact: true,
      name: "Updates",
      main: () => <Updates />,
      onClick: () =>
        setBreadcrumb({ path: `${match.path}/updates`, name: "Updates" }),
    },
  ];

  const location = useLocation();
  var sidebarList = routes.map((route, index) => (
    <SidebarListItem
      active={location.pathname === route.path}
      key={`${route.path} + ${index}`}
    >
      <StyledLink to={route.path} onClick={route.onClick}>
        {route.name}
      </StyledLink>
    </SidebarListItem>
  ));

  // TODO: We could add a local page here?
  // // Insert link to password in sidebar explicitly — this is hosted on Keycloak instead of Live
  // sidebarList.splice(
  //   1,
  //   0,
  //   <SidebarListItem active={false} key={keycloakUrl}>
  //     <SidebarListA href={keycloakUrl}>Password</SidebarListA>
  //   </SidebarListItem>
  // );

  const [breadcrumb, setBreadcrumb] = useState<{ path: string; name: string }>({
    path: routes[0].path,
    name: routes[0].name,
  });

  return (
    <PageLayout title="System">
      <Breadcrumbs
        crumbs={[
          {
            path: `${match.path}`,
            name: "System",
          },
          {
            path: `${breadcrumb.path}`,
            name: breadcrumb.name,
          },
        ]}
      />
      <SectionHeader title="System">
        <SidebarCard sidebarList={sidebarList}>
          <Switch>
            <Redirect exact from={match.path} to={`${match.path}/about`} />
            {routes.map((route, index) => (
              <Route
                key={`${route.path} + ${index}`}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </SidebarCard>
      </SectionHeader>
    </PageLayout>
  );
};

export default SystemPage;
