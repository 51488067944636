import { gql, QueryResult, useQuery } from "@apollo/client";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import { CloudModulesResourceAccessVoucher } from "../../generated/graphql";
import { ButtonSizes, SortOrder } from "../../shared/enums";
import { Option } from "react-select/src/filters";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import { H4, Text } from "../../components/shared/typography";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import CheckboxSpacer from "../../components/CheckboxSpacer";
import Checkbox from "../../components/shared/Checkbox";
import CloudModuleResourceAccessVoucherCard from "./CloudCaseResourceAccessVoucherCard";
import Button from "../../components/shared/Button";
import CreateCloudModulesResourceAccessVoucherDialog from "./CreateOrEditCloudModulesResourceAccessVoucherDialog";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import { AccordionSection } from "../admin/group-admin";
import { cloudModulesResourceAccessVouchersFields } from "../../types/fragments/CloudModulesResourceAccessVouchersFragment";

const CloudCaseResourceAccessVouchers: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const sortOrderOptions = [
    {
      value: "Created at (newest first)",
      label: "Created at (newest first)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Created at (oldest first)",
      label: "Created at (oldest first)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<number[]>(
    []
  );
  const [isCreatingVoucher, setIsCreatingVoucher] = useState<boolean>(false);
  const [editingVoucher, setEditingVoucher] = useState<
    CloudModulesResourceAccessVoucher | undefined
  >(undefined);

  const {
    data,
    loading,
    error,
  }: QueryResult<{
    getAllCloudModulesResourceAccessVouchersAsAdmin: CloudModulesResourceAccessVoucher[];
  }> = useQuery(
    gql`
      query GetResourceAccessVouchers {
        getAllCloudModulesResourceAccessVouchersAsAdmin {
          ...CloudModulesResourceAccessVouchersFields
        }
      }
      ${cloudModulesResourceAccessVouchersFields}
    `
  );

  const learningSpaces = (() => {
    if (
      !data ||
      !data.getAllCloudModulesResourceAccessVouchersAsAdmin ||
      loading ||
      error
    ) {
      return [];
    } else {
      const nonUniqueLearningSpaces =
        data.getAllCloudModulesResourceAccessVouchersAsAdmin.map(
          (voucher) => voucher.learningSpace
        );
      const uniqueLearningSpaces = nonUniqueLearningSpaces.filter(
        (learningSpace1, i) =>
          nonUniqueLearningSpaces.findIndex(
            (learningSpace2) => learningSpace1.id === learningSpace2.id
          ) === i
      );
      return uniqueLearningSpaces;
    }
  })();

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 300),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  return (
    <SidebarCardSection title="" maxColumns={1}>
      <ActionBar
        title={"Cloud Case Vouchers"}
        searchField={
          <TextField
            id="cy-search-courses"
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search"
            onChange={handleSearchChange}
          />
        }
        button={
          <Button
            size={ButtonSizes.Medium}
            onClick={() => setIsCreatingVoucher(true)}
          >
            Create voucher
          </Button>
        }
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => {
                  setSelectedSortOption(option);
                }}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
            <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
              <FilterContainer
                onClear={() => {
                  setSelectedFilterOptions([]);
                }}
              >
                <H4 style={{ marginBottom: "24px" }}>Learning Spaces</H4>
                <CheckboxSpacer>
                  {learningSpaces.map((learningSpace) => (
                    <Checkbox
                      testId={`cy-checkbox-${learningSpace.name}`}
                      key={learningSpace.id}
                      title={learningSpace.name}
                      checkboxChecked={selectedFilterOptions.some(
                        (selectedFilterOption) =>
                          selectedFilterOption === learningSpace.id
                      )}
                      onCheckboxClick={() => {
                        const newSelectedFilterOptions =
                          selectedFilterOptions.some(
                            (selectedFilterOption) =>
                              selectedFilterOption === learningSpace.id
                          )
                            ? selectedFilterOptions.filter(
                                (filteredLearningSpaceId) =>
                                  filteredLearningSpaceId !== learningSpace.id
                              )
                            : [...selectedFilterOptions, learningSpace.id];

                        setSelectedFilterOptions(newSelectedFilterOptions);
                      }}
                    />
                  ))}
                </CheckboxSpacer>
              </FilterContainer>
            </DropdownMenu>
          </>
        }
      />
      {data &&
        data.getAllCloudModulesResourceAccessVouchersAsAdmin &&
        (() => {
          const filteredAndSortedAllVouchers = [
            ...data.getAllCloudModulesResourceAccessVouchersAsAdmin,
          ]
            .filter((voucher) => {
              // Filter on learning space
              if (selectedFilterOptions.length === 0) {
                return true;
              } else {
                return selectedFilterOptions.some(
                  (learningSpaceID) =>
                    voucher.learningSpace.id === learningSpaceID
                );
              }
            })
            .filter((voucher) => {
              // Filter on search text
              if (searchText === "") {
                return true;
              } else {
                const matches = (str: string) =>
                  str.toLowerCase().includes(searchText.toLowerCase());

                return (
                  matches(voucher.code) ||
                  matches(voucher.learningSpace.name) ||
                  matches(voucher.customer.name) ||
                  matches(
                    `${voucher.creator.first_name} ${voucher.creator.last_name}`
                  ) ||
                  voucher.users.some((user) =>
                    matches(`${user.first_name} ${user.last_name}`)
                  ) ||
                  voucher.modules.some(
                    (module) =>
                      matches(module.name) ||
                      module.cases.some((singleCase) =>
                        matches(singleCase.name)
                      )
                  )
                );
              }
            })
            .sort((voucher1, voucher2) => {
              switch (selectedSortOption.value) {
                case "Created at (oldest first)":
                  return new Date(voucher1.createdAt) <
                    new Date(voucher2.createdAt)
                    ? -1
                    : 1;
                case "Created at (newest first)":
                default:
                  return new Date(voucher1.createdAt) >
                    new Date(voucher2.createdAt)
                    ? -1
                    : 1;
              }
            });
          const activeVouchers = filteredAndSortedAllVouchers.filter(
            (voucher) =>
              voucher.archivedAt === null &&
              new Date(voucher.validTo) >= new Date()
          );
          const expiredVouchers = filteredAndSortedAllVouchers.filter(
            (voucher) =>
              voucher.archivedAt === null &&
              new Date(voucher.validTo) < new Date()
          );
          const archivedVouchers = filteredAndSortedAllVouchers.filter(
            (voucher) => voucher.archivedAt !== null
          );
          return (
            <>
              {activeVouchers.length > 0 && (
                <AccordionSection
                  title={`Active vouchers (${activeVouchers.length})`}
                  buttons={[]}
                >
                  {activeVouchers.map((voucher) => {
                    return (
                      <CloudModuleResourceAccessVoucherCard
                        key={voucher.id}
                        voucher={voucher}
                        setIsEditingVoucher={() => setEditingVoucher(voucher)}
                        editable={true}
                        archivable={true}
                      />
                    );
                  })}
                </AccordionSection>
              )}
              {expiredVouchers.length > 0 && (
                <AccordionSection
                  title={`Expired vouchers (${expiredVouchers.length})`}
                  buttons={[]}
                >
                  {expiredVouchers.map((voucher) => {
                    return (
                      <CloudModuleResourceAccessVoucherCard
                        key={voucher.id}
                        voucher={voucher}
                        setIsEditingVoucher={() => {}}
                        editable={false}
                        archivable={true}
                      />
                    );
                  })}
                </AccordionSection>
              )}
              {archivedVouchers.length > 0 && (
                <AccordionSection
                  title={`Archived vouchers (${archivedVouchers.length})`}
                  buttons={[]}
                  startsCollapsed={true}
                >
                  {archivedVouchers.map((voucher) => (
                    <CloudModuleResourceAccessVoucherCard
                      key={voucher.id}
                      voucher={voucher}
                      setIsEditingVoucher={() => {}}
                      editable={false}
                      archivable={false}
                    />
                  ))}
                </AccordionSection>
              )}
            </>
          );
        })()}
      {loading && <LoadingIndicator />}
      {error && (
        <>
          {error.graphQLErrors.map((error: { message: string }) => (
            <Text>{error.message}</Text>
          ))}
        </>
      )}
      {editingVoucher && (
        <CreateCloudModulesResourceAccessVoucherDialog
          onClose={() => setEditingVoucher(undefined)}
          editingVoucher={editingVoucher}
        />
      )}
      {isCreatingVoucher && (
        <CreateCloudModulesResourceAccessVoucherDialog
          onClose={() => setIsCreatingVoucher(false)}
        />
      )}
    </SidebarCardSection>
  );
};

export default CloudCaseResourceAccessVouchers;
