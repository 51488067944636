import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

export const DocLink = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  color: #666;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  
  outline: none;
  text-decoration: none;
  text-align: left;
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'wait' : 'pointer'};

  &:hover {
    background: #f5f5f5;
    color: #000;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    pointer-events: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StateContainer = styled.div`
  padding: 12px;
  border-radius: 4px;
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
