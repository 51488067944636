import React, {  } from "react";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import 'react-phone-input-2/lib/style.css'
import { gql, useQuery } from "@apollo/client";
import { getServiceTag } from "../../lib/getServiceTag";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import {
  H4, Span
} from "../../components/shared/typography";

const GET_INFO = gql`
  query vistComputerGet($serviceTag: String!) {
    vistComputerGet (serviceTag: $serviceTag)  {
      id
      serviceTag
      info
    }
  }`;

const About: React.FC<{}> = () => {
  const {
    loading: infoLoading,
    data: infoData,
    error: infoErrors
  } = useQuery(GET_INFO, {variables: {serviceTag: getServiceTag()}});
  const systemInfo = !infoLoading && !infoErrors && JSON.parse(infoData.vistComputerGet.info);

  return (
    <>
      <SidebarCardSection maxColumns={1} title="About the system">
      {infoLoading && <LoadingIndicator />}
        { !infoErrors &&
          systemInfo && 
          <><H4>Computer</H4>
          <Span><b>Service tag:</b> {systemInfo.servicetag}</Span>
          <Span><b>Model:</b> {systemInfo['computer-manufacturer']} {systemInfo['computer-model']}</Span>
          <Span><b>BIOS:</b> {systemInfo['bios-version']} released {systemInfo['bios-release-date']}</Span>
          <Span><b>GPU:</b> {systemInfo['gpu']} - driver version: {systemInfo['gpu-driver-version']}</Span>
          <Span><b>Disk:</b> {systemInfo['c-disk-free']} free of {systemInfo['c-disk-total']} on OS drive</Span>

          <H4>Installed Applications</H4>
          {
            systemInfo.software.map((sw:string, i:number) => <Span key={'sw-list-' + i}>{sw}</Span>)
          }
          </>
        }
        { !infoLoading && (infoErrors || !systemInfo) &&
          <><H4>Computer</H4>
          <Span>Currently no information is uploaded on system {getServiceTag()}</Span>
          </>
        }
      </SidebarCardSection>
    </>
  );
};
export default About;