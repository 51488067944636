export function getClientVersion(): string {
    const c = document.cookie.split(";").find(s => s.trim().startsWith("client_version"));
    return c ? c.split("=")[1] : "";
  }

export function getClientVersionNumeric(): [number, number, number] {
  const version = getClientVersion();
  const v = version.match(/(\d+)\.(\d+)\.(\d+)/) || ["", "0", "0", "0"];
  const [currentMajor, currentMinor, currentPatch] = v.slice(1).map((e:any) => parseInt(e))
  return [currentMajor, currentMinor, currentPatch]
}
