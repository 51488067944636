import { toast } from 'react-hot-toast';
import { UpdateToast } from '../components/UpdateCard/UpdateToast';
import React from 'react';

type ToastVariant = 'success' | 'error' | 'loading';

interface ToastOptions {
  title: string;
  description: string;
  duration?: number;
  variant: ToastVariant;
}

class ToastService {
  private static defaultDuration = 4000;

  static show({ 
    title, 
    description, 
    duration = ToastService.defaultDuration,
    variant 
  }: ToastOptions) {
    return toast.custom(
      (t) => (
        <UpdateToast
          title={title}
          description={description}
          variant={variant}
        />
      ),
      {
        duration,
        position: 'bottom-right',
      }
    );
  }

  static updateOperation(operation: 'download' | 'install', status: 'started' | 'completed' | 'failed', softwareName: string) {
    const messages = {
      download: {
        started: { title: 'Download Started', description: `Starting download for ${softwareName}`, variant: 'loading' as const },
        completed: { title: 'Download Complete', description: `Successfully downloaded ${softwareName}`, variant: 'success' as const },
        failed: { title: 'Download Failed', description: `Failed to download ${softwareName}. Please try again.`, variant: 'error' as const }
      },
      install: {
        started: { title: 'Installation Started', description: `Starting installation for ${softwareName}`, variant: 'loading' as const },
        completed: { title: 'Installation Complete', description: `Successfully installed ${softwareName}`, variant: 'success' as const },
        failed: { title: 'Installation Failed', description: `Failed to install ${softwareName}. Please try again.`, variant: 'error' as const }
      }
    } as const;

    const config = messages[operation][status];
    return this.show({
      ...config,
      duration: status === 'started' ? 2000 : 4000
    });
  }

  static error(error: Error | string) {
    const message = error instanceof Error ? error.message : error;
    return this.show({
      title: 'Error',
      description: message,
      variant: 'error',
      duration: 5000
    });
  }
}

export default ToastService;
