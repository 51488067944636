import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MenticeLogo } from "../shared/MenticeLogo";
import { useKeycloak } from "@react-keycloak/web";
import {
  activeLinkStyle,
  H4,
  h6Style,
  SemiBoldText,
  TextButton,
} from "../shared/typography";
import { NavLink, useLocation } from "react-router-dom";
import { haveRole, KeycloakAccessToken } from "../../lib/keycloakAccessToken";
import Hamburger from "./Hamburger";
import { User } from "../../generated/graphql";
import { gql, QueryResult, useMutation, useQuery } from "@apollo/client";
import { useToast } from "../../lib/useToast";
import { ButtonSizes, ToastFormat, ToastType } from "../../shared/enums";
import Dialog from "../shared/Dialog";
import Button from "../shared/Button";
import { renderLoadingText } from "../shared/LoadingIndicator";
import { getServiceTag } from "../../lib/getServiceTag";
import { isDebugVistMode } from "../../lib/isDebugVistMode";
import NotificationBell from "../notificationBell";
import { useUpdateStore } from "../../stores/updateStore";
import { getClientVersionNumeric } from "../../lib/getClientVersion";

const NavContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.barShadow};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 998;

  @media screen and (max-width: ${(props) => props.theme.sizes.breakpointM}) {
    position: sticky;
    top: 0;
  }
`;

const Nav = styled.nav`
  height: 96px;
  width: 100%;
  max-width: calc(1128px + 24px + 24px);
  padding: 0 24px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 96px 1fr 1fr;
  align-items: center;
  display: flex;

  @media screen and (max-width: 768px) {
    display: inline-block;
    justify-content: space-between;
    display: flex;
  }
`;

const Logo = styled(MenticeLogo)`
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  margin-right: 48px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-right: 16px;
  }
`;

const UserControls = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

const ResetButton = styled(TextButton)`
  margin-right: ${(props) => props.theme.sizes.spacing5};
`;

const NavLinks = styled.ul`
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
`;

const LeftContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(({ active, ...props }) => <NavLink {...props} />)`
  // https://github.com/styled-components/styled-components/issues/1198
  ${h6Style}
  ${activeLinkStyle}
  margin-right: ${(props) => props.theme.sizes.spacing5};
  text-decoration: none;
  white-space: nowrap;
`;

export const NavBar: React.FC = () => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const user: KeycloakAccessToken | undefined = keycloak.tokenParsed;
  const [, createToast] = useToast();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState<boolean>(false);

  const serviceTag = getServiceTag();
  const debugVISTMode = isDebugVistMode(useLocation().search);
  const clientVersion = getClientVersionNumeric();
  
  const {
    data: userData,
  }: QueryResult<{
    user: User;
  }> = useQuery(
    gql`
      query GetUser($emailAddress: String!) {
        user(emailAddress: $emailAddress) {
          logbook
        }
      }
    `,
    {
      variables: { emailAddress: user?.email ?? "" },
    }
  );

  const [reset, { loading: loadingResetProgress }] = useMutation(
    gql`
      mutation ResetCourseProgress {
        resetAccountProgress {
          done
        }
      }
    `,
    {
      onCompleted: () => {
        setShowResetDialog(false);
        createToast({
          title: "Course progress has been reset. Refreshing site...",
          type: ToastType.SUCCESS,
          format: ToastFormat.TOAST,
        });
        setTimeout(() => {
          window.location.reload();
        }, "3000");
      },
      onError: () => {
        setShowResetDialog(false);
        createToast({
          title:
            "Something went wrong resetting course progress. Please try again later.",
          type: ToastType.ERROR,
          format: ToastFormat.TOAST,
        });
      },
    }
  );

  useEffect(() => {
    let timer: number | undefined = undefined;
    if (buttonDisabled) {
      timer = setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [buttonDisabled]);

  // Functions for checking device width and adjusting the nav accordingly
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const handleWindowSizeChange = () => {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isGroupAdmin = haveRole(user, "group_admin");
  const isCustomerAdmin = haveRole(user, "customer_admin");
  const isSysAdmin = haveRole(user, "sys_admin");
  const isDemoUser = haveRole(user, "demo");
  const isSupport = haveRole(user, "support");
  const isProduct = haveRole(user, "product");
  const isQA = haveRole(user, "qa");

  var navbarLinks = [
    {
      title: "Mentice Live",
      to: "/",
    },
  ];

  const shouldShowLogbook =
    (userData?.user.logbook && userData?.user?.logbook === "true") ||
    isSysAdmin;

  if (shouldShowLogbook) {
    navbarLinks.push({
      title: "Logbook",
      to: "/logbook",
    });
  }

  if (isGroupAdmin || isCustomerAdmin || isSysAdmin || isSupport || isProduct || isQA) {
    navbarLinks.push({
      title: "Administration",
      to: "/administration",
    });
  }

  if ((serviceTag || debugVISTMode) && (clientVersion[0] > 1 || (clientVersion[0] === 1 && clientVersion[1] >= 1))) { // Only show for MLD versions 1.1+
    navbarLinks.push({
      title: "System",
      to: "/system",
    });
  }

  const trimPathname = (pathname: String) => pathname.split("/", 2).join("/");

  const mapOutMenuLinks = (links: { title: string; to: string }[]) => {
    return links.map((link: any, i: number) => {
      return (
        <li key={i}>
          <StyledLink
            active={link.to === trimPathname(location.pathname) ? true : false}
            to={link.to}
          >
            {link.title}
          </StyledLink>
        </li>
      );
    });
  };

  const UserMenu = () =>
    user ? (
      <>
        <li>
          <StyledLink
            active={
              "/profile" === trimPathname(location.pathname) ? true : false
            }
            style={{ textDecoration: "none" }}
            to={"/profile"}
          >
            {user.name}
          </StyledLink>
        </li>
        {isDemoUser && (
          <li>
            <ResetButton
              disabled={buttonDisabled}
              onClick={() => setShowResetDialog(true)}
              id="reset"
            >
              reset
            </ResetButton>
          </li>
        )}
        <li>
          <TextButton onClick={() => keycloak.logout()}>Sign Out</TextButton>
        </li>
      </>
    ) : (
      <li>
        <TextButton onClick={() => keycloak.login()}>Sign In</TextButton>
      </li>
    );

  const { unreadNotifications, newSoftwareAvailable, acknowledgeNotifications } = useUpdateStore();

  return (
    <NavContainer>
      {!isMobile ? (
        <Nav>
          <LeftContainer>
            <NavLinks>
              <NavLink to={"/"}>
                <Logo />
              </NavLink>
              {mapOutMenuLinks(navbarLinks)}
            </NavLinks>
            <NotificationBell
              size={34}
              color="#ff7214"
            />
          </LeftContainer>
          <UserControls>
            <NavLinks>
              <UserMenu />
            </NavLinks>
          </UserControls>
        </Nav>
      ) : (
        <Nav>
          <NavLink
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
            to={"/"}
          >
            <Logo />
            <H4>Mentice Live</H4>
          </NavLink>
          <Hamburger>
            <div>{mapOutMenuLinks(navbarLinks)}</div>
            <div>
              <UserMenu />
            </div>
          </Hamburger>
        </Nav>
      )}
      {showResetDialog && (
        <Dialog
          onClose={() => setShowResetDialog(false)}
          title={"Reset Course Progress"}
          buttons={
            <>
              <Button
                secondary
                onClick={() => setShowResetDialog(false)}
                size={ButtonSizes.Medium}
              >
                Cancel
              </Button>
              <Button
                size={ButtonSizes.Medium}
                onClick={() => {
                  reset();
                }}
              >
                {renderLoadingText(loadingResetProgress, "Reset")}
              </Button>
            </>
          }
        >
          <SemiBoldText>
            Are you sure you want to reset course progress for this account?
          </SemiBoldText>
        </Dialog>
      )}
    </NavContainer>
  );
};