import { VistActivityItem as VistActivityItemEntity } from "../../generated/graphql";
import { exportToExcel } from "../../lib/exportToExcel";
import { ToastData } from "../../lib/toastStore";
import { ToastFormat, ToastType } from "../../shared/enums";
import {
  formatDateAndTime,
  getFormattedDurationStringFromSeconds,
  getTodaysDateTime,
} from "../../lib/formatDateAndTime";

const fileName = "activity-log-" + getTodaysDateTime();

export const handleDownloadActivities = (
  activities: VistActivityItemEntity[] | undefined,
  createToast: (toastData: ToastData) => void
) => {
  if (activities?.length) {
    try {
      exportToExcel(
        activities.map((activity: VistActivityItemEntity) => {
          return {
            createdBy: activity.createdBy?.email,
            courseName: activity.courseName,
            exerciseName: activity.unitName,
            activityStart: formatDateAndTime(activity.activityStart),
            activityEnd: formatDateAndTime(activity.activityEnd),
            exerciseId: activity.exerciseId,
            id: activity.id,
            ipAddress: activity.ipAddress,
            moduleId: activity.moduleId,
            moduleName: activity.moduleName,
            moduleVersion: activity.moduleVersion,
            score: activity.progress,
            result: activity.result,
            serviceTag: activity.serviceTag,
            totalTime: getFormattedDurationStringFromSeconds(
              activity.totalTimeInSeconds,
              true
            ),
            totalTimeSeconds: activity.totalTimeInSeconds,
            sourceUrl: activity.sourceUrl,
          };
        }),
        fileName
      );
      createToast({
        title: "Successfully exported activities",
        type: ToastType.SUCCESS,
        format: ToastFormat.TOAST,
      });
    } catch (error) {
      console.error(error);
      createToast({
        title: "Something went wrong while exporting the activities.",
        type: ToastType.ERROR,
        format: ToastFormat.TOAST,
      });
    }
  } else {
    createToast({
      title: "You have no activities to export",
      type: ToastType.ERROR,
      format: ToastFormat.TOAST,
    });
  }
};
