import React from "react";
import styled from "styled-components";
import { spanStyle } from "../typography";

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 48px 0 24px;
  height: 32px;
  position: relative;
  ${(props: { activitiesLoading: boolean }) =>
    props.activitiesLoading && "pointer-events: none; opacity: 0.5;"}
`;

export const Page = styled.div`
  ${spanStyle}
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  border: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage ? "none" : "1px solid " + props.theme.colors.lightGrey};
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage && props.theme.colors.white};
  background-color: ${(props: { activePage: boolean; theme: any }) =>
    props.activePage
      ? props.theme.colors.primary300
      : props.theme.colors.white};
`;

export const PageArrow = styled.img`
  cursor: pointer;
`;

export const dots = (
  <img src="/static/assets/more.svg" width="16px" alt="Hidden pages"></img>
);
