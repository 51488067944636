import { gql } from "@apollo/client";

export const VIST_AVAILABLE_RELEASES_QUERY = gql`
  query VistAvailableReleasesForSystem($serviceTag: String!) {
    vistAvailableReleasesForSystem(serviceTag: $serviceTag) {
      id
      release {
        id
        stringIdentifier
        software {
          name
        }
        version {
          semantic
        }
      }
      state {
        id
        downloaded
        installed
        operationIsRunning
        operationStates {
          download {
            state
            progress
            errors
          }
          install {
            state
            progress
            errors
          }
        }
      }
      currentRelease {
        id
        stringIdentifier
        software {
          name
        }
        version {
          semantic
        }
      }
      createdAt
    }
  }
`;

export const VIST_INSTALLED_RELEASES_QUERY = gql`
  query VistAvailableReleasesForSystem($serviceTag: String!) {
    vistAvailableReleasesForSystem(serviceTag: $serviceTag) {
      id
      release {
        stringIdentifier
        software {
          name
          code
        }
        version {
          semantic
        }
      }
      state {
        id
        downloaded
        installed
        operationIsRunning
        operationStates {
          download {
            state
            progress
            errors
          }
          install {
            state
            progress
            errors
          }
        }
      }
      currentRelease {
        id
        stringIdentifier
        software {
          name
          code
        }
        version {
          semantic
        }
      }
      createdAt
    }
  }
`;
