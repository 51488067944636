import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  VistAvailableReleasesForSystem,
} from "../types/updateTypes";
import { OperationState } from "../pages/system/types";

interface UpdateState {
  // Tracks the current state of all software in the system
  currentStates: VistAvailableReleasesForSystem[];
  // Used to detect changes between updates by comparing with currentStates
  lastKnownStates: VistAvailableReleasesForSystem[];
  // Counter for the notification badge on the bell icon
  unreadNotifications: number;
  // Flag to show the pulsing animation on the bell
  newSoftwareAvailable: boolean;
  // Historical list of all notifications, including state changes
  notifications: VistAvailableReleasesForSystem[];
  operationStates: Record<string, { 
    action: "download" | "install" | "";
    state: "RUNNING" | "COMPLETED" | "FAILED" | "IDLE";
  }>;
  activeOperations: boolean;
  setCurrentStates: (states: VistAvailableReleasesForSystem[]) => void;
  checkForUpdates: () => void;
  acknowledgeNotifications: () => void;
  updateSoftwareState: (id: string, action: "download" | "install") => void;
  clearNotification: (index: number) => void;
  setOperationState: (updateId: string, state: { 
    action: "download" | "install" | "";
    state: "RUNNING" | "COMPLETED" | "FAILED" | "IDLE";
  }) => void;
  setActiveOperations: (active: boolean) => void;
}

export const useUpdateStore = create<UpdateState>()(
  persist(
    (set, get) => ({
      currentStates: [],
      lastKnownStates: [],
      unreadNotifications: 0,
      newSoftwareAvailable: false,
      notifications: [],
      operationStates: {},
      activeOperations: false,
      setCurrentStates: (states) => {
        const { lastKnownStates } = get();
        if (JSON.stringify(states) !== JSON.stringify(lastKnownStates)) {
          set({ currentStates: states });
          get().checkForUpdates();
        }
      },
      acknowledgeNotifications: () =>
        set({ unreadNotifications: 0, newSoftwareAvailable: false }),
      updateSoftwareState: (id, action) => {
        const { currentStates } = get();
        const updatedStates = currentStates.map((state) => {
          if (state.id === id) {
            const newState: VistAvailableReleasesForSystem = {
              ...state,
              state: {
                ...state.state,
                operationIsRunning: true,
                operationStates: {
                  ...state.state.operationStates,
                  [action]: {
                    ...state.state.operationStates[action],
                    state: "RUNNING" as OperationState["state"],
                  },
                },
              },
            };
            return newState;
          }
          return state;
        });
        set({ currentStates: updatedStates });
      },
      checkForUpdates: () => {
        const { currentStates, lastKnownStates, notifications } = get();
        let newNotifications = 0;
        let newSoftware = false;
        let updatedNotifications = [...notifications];

        // Notification System Overview:
        // 1. Each software can have multiple versions (e.g., gen_crm 3.0.6 and 3.0.7)
        // 2. Each version can trigger notifications for:
        //    - Initial discovery of new software
        //    - Download completion
        //    - Installation completion
        // 3. Timestamps are handled differently:
        //    - New software: Uses original discovery time, which is release creation date
        //    - State changes: Uses current time to appear at top of list since those dates are 

        currentStates.forEach((currentState) => {
          try {
            // Prevent duplicate notifications by checking if we already have this exact
            // version + state combination (e.g., gen_crm 3.0.7 in downloaded state)
            const existingNotification = updatedNotifications.find(
              (n) => 
                n.release.software.name === currentState.release.software.name &&
                n.release.version.semantic === currentState.release.version.semantic &&
                n.state.downloaded === currentState.state.downloaded &&
                n.state.installed === currentState.state.installed
            );

            // Find the previous known state of this specific software version to detect changes
            // Multiple versions of the same software can exist (e.g., gen_crm 3.0.6 and 3.0.7)
            const lastKnownState = lastKnownStates.find(
              (s) => 
                s.release.software.name === currentState.release.software.name &&
                s.release.version.semantic === currentState.release.version.semantic
            );

            // Create a new notification if either:
            // 1. This is the first time we've seen this software version
            // 2. The download state has changed (e.g., software was downloaded)
            // 3. The install state has changed (e.g., software was installed)
            if (!existingNotification && (
              !lastKnownState || 
              currentState.state.downloaded !== lastKnownState.state.downloaded ||
              currentState.state.installed !== lastKnownState.state.installed
            )) {
              newNotifications++;
              
              // Check if we have any previous notification for this software version
              // Used to maintain notification history for the same version
              const previousNotification = updatedNotifications.find(
                (n) => 
                  n.release.software.name === currentState.release.software.name &&
                  n.release.version.semantic === currentState.release.version.semantic
              );

              // Timestamp logic:
              // - For new software notifications: use original creation timestamp
              // - For state changes (downloaded/installed): use current timestamp
              // This ensures state changes appear at the top of the notification list
              const useCurrentTime = currentState.state.downloaded || currentState.state.installed;
              const timestamp = useCurrentTime ? 
                new Date().toISOString() : 
                (previousNotification?.createdAt || currentState.createdAt || new Date().toISOString());

              const notificationWithTimestamp = {
                ...currentState,
                createdAt: timestamp
              };
              updatedNotifications.push(notificationWithTimestamp);
              newSoftware = true;
            }
          } catch (error) {
            console.error("Error processing state:", error);
          }
        });

        // Sort notifications by timestamp to show most recent state changes first
        // while maintaining the chronological order of new software notifications
        updatedNotifications.sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateB - dateA;
        });

        set({
          lastKnownStates: currentStates,
          unreadNotifications: get().unreadNotifications + newNotifications,
          newSoftwareAvailable: newSoftware || get().newSoftwareAvailable,
          notifications: updatedNotifications,
        });
      },
      clearNotification: (index) => {
        const { notifications } = get();
        const updatedNotifications = notifications.filter(
          (_, i) => i !== index
        );
        set({ notifications: updatedNotifications });
      },
      setOperationState: (updateId, state) => 
        set((store) => ({
          operationStates: {
            ...store.operationStates,
            [updateId]: state
          }
        })),
      setActiveOperations: (active) => set({ activeOperations: active }),
    }),
    {
      name: "update-storage",
      partialize: (state) => ({
        lastKnownStates: state.lastKnownStates,
        unreadNotifications: state.unreadNotifications,
        newSoftwareAvailable: state.newSoftwareAvailable,
        notifications: state.notifications,
        operationStates: state.operationStates,
        activeOperations: false,
      }),
    }
  )
);
