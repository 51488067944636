import React, { useState } from "react";
import { Download, Trash, Edit2, FileIcon, FolderIcon, Eye, Link } from "lucide-react";
import { formatFileSize, formatDate } from "../../utils/formatters";
import {
  IconWrapper,
  Card,
  CardFileInfo,
  CardFileName,
  CardFileDetails,
  Actions,
  ActionButton,
  TooltipWrapper,
  Tooltip
} from "./S3FileManagerStyles";
import PDFViewer from './PDFViewer';

interface S3File {
  Key: string;
  Size: number;
  LastModified: string;
  IsDirectory?: boolean;
}

interface S3FileCardProps {
  file: S3File;
  onDownload: (fileKey: string) => void;
  onView?: (fileKey: string) => void;
  onDelete?: (fileKey: string) => void;
  onRename?: (fileKey: string) => void;
  onFolderClick: (folderPath: string) => void;
  getSignedUrl?: (fileKey: string) => Promise<string>;
}

export const S3FileCard: React.FC<S3FileCardProps> = ({
  file,
  onDownload,
  onDelete,
  onRename,
  onFolderClick,
  getSignedUrl,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleClick = () => {
    if (file.IsDirectory) {
      onFolderClick(file.Key);
    }
  };

  const fileName = file.Key.split("/").filter(Boolean).pop() || "";

  const handleViewPdf = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      if (getSignedUrl) {
        const url = await getSignedUrl(file.Key);
        setPdfUrl(url);
        setShowPdfViewer(true);
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
    }
  };

  return (
    <>
      <Card onClick={handleClick} isDirectory={file.IsDirectory}>
        <IconWrapper isDirectory={file.IsDirectory}>
          {file.IsDirectory ? <FolderIcon size={48} /> : <FileIcon size={48} />}
        </IconWrapper>
        <CardFileInfo>
          <CardFileName>{fileName}</CardFileName>
          <CardFileDetails>
            {file.IsDirectory ? (
              <span>Folder</span>
            ) : (
              <>
                <span>{formatFileSize(file.Size)}</span>
                <span>{formatDate(file.LastModified)}</span>
              </>
            )}
          </CardFileDetails>
        </CardFileInfo>
        {!file.IsDirectory && (
          <Actions>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                onDownload(file.Key);
              }}
              title="Download"
            >
              <Download size={20} />
            </ActionButton>
            {getSignedUrl && (
              <TooltipWrapper>
                <ActionButton
                  onClick={async (e) => {
                    e.stopPropagation();
                    const url = await getSignedUrl(file.Key);
                    await navigator.clipboard.writeText(url);
                    setShowTooltip(true);
                    setTimeout(() => setShowTooltip(false), 2000);
                  }}
                  title="Copy signed link (30 min)"
                >
                  <Link size={20} />
                </ActionButton>
                {showTooltip && <Tooltip>Link copied to clipboard!</Tooltip>}
              </TooltipWrapper>
            )}
            {onRename && (
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  onRename(file.Key);
                }}
                title="Rename"
              >
                <Edit2 size={20} />
              </ActionButton>
            )}
            {onDelete && (
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(file.Key);
                }}
                title="Delete"
              >
                <Trash size={20} />
              </ActionButton>
            )}
            {!file.IsDirectory && file.Key.toLowerCase().endsWith('.pdf') && (
              <ActionButton
                onClick={handleViewPdf}
                title="View PDF"
              >
                <Eye size={20} />
              </ActionButton>
            )}
          </Actions>
        )}
      </Card>
      {showPdfViewer && pdfUrl && (
        <PDFViewer 
          url={pdfUrl} 
          onClose={() => {
            setShowPdfViewer(false);
            setPdfUrl(null);
          }}
        />
      )}
    </>
  );
};