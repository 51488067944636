export interface VistSystemSoftwareRelease {
  id: string;
  release: {
    stringIdentifier: string;
    software: {
      code: string;
      name: string;
      status: string;
    };
    version: {
      semantic: string | null;
      major: number;
      minor: number;
      patch: number;
      suffix: string | null;
    };
  };
  state: {
    installed: boolean;
    downloaded: boolean;
    operationIsRunning: boolean;
    operationStates: {
      download: {
        state: string;
        progress: number;
        errors: string[];
      };
      install: {
        state: string;
        progress: number;
        errors: string[];
      };
    };
  };
  currentRelease?: {
    id: string;
    stringIdentifier: string;
    software: {
      name: string;
      code: string;
    };
    version: {
      semantic: string;
    };
  } | null;
  createdAt: string;
}

export interface SortOption {
  value: string;
  label: string;
  data: {
    sortField: "date" | "name";
    sortOrder: "asc" | "desc";
  };
}

export interface OperationState {
  action: string;
  state: string;
}

export type OperationStates = Record<string, OperationState>;

export const sortOrderOptions: SortOption[] = [
  {
    value: "dateDesc",
    label: "Newest First",
    data: {
      sortField: "date",
      sortOrder: "desc",
    },
  },
  {
    value: "dateAsc", 
    label: "Oldest First",
    data: {
      sortField: "date",
      sortOrder: "asc",
    },
  },
  {
    value: "nameAsc",
    label: "Name (A-Z)",
    data: {
      sortField: "name", 
      sortOrder: "asc",
    },
  },
  {
    value: "nameDesc",
    label: "Name (Z-A)", 
    data: {
      sortField: "name",
      sortOrder: "desc", 
    },
  },
];
