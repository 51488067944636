import React, { useState, useRef, useEffect } from "react";
import { Bell } from "lucide-react";
import { useUpdateStore } from "../stores/updateStore";
import { useHistory } from "react-router-dom";
import NotificationItem from "./notificationItem";

interface NotificationBellProps {
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

const NotificationBell: React.FC<NotificationBellProps> = ({
  size = 24,
  color = "#ff7214",
  style = {},
}) => {
  const {
    unreadNotifications,
    newSoftwareAvailable,
    acknowledgeNotifications,
    notifications,
    clearNotification,
  } = useUpdateStore();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const bellRef = useRef<HTMLDivElement>(null);
  
  // history.push() navigates to a new route. It adds a new entry in the
  // browser's history stack. handleNotificationClick() causes the component
  // to rerender and point to "/system/updates" without a full page reload.
  const history = useHistory();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        bellRef.current &&
        !bellRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBellClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    if (unreadNotifications > 0) {
      acknowledgeNotifications();
    }
  };

  const handleClearNotification = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    clearNotification(index);
  };

  const handleNotificationClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(false);
    history.push("/system/updates");
  };

  return (
    <div
      ref={bellRef}
      style={{
        position: "relative",
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      onClick={handleBellClick}
    >
      <Bell
        size={size}
        color={unreadNotifications > 0 ? "white" : color}
        style={{
          transition: "all 0.3s ease",
          transform: unreadNotifications > 0 ? "scale(1.1)" : "scale(1)",
          background: unreadNotifications > 0 ? color : "transparent",
          borderRadius: "50%",
          padding: size * 0.2,
        }}
      />
      {unreadNotifications > 0 && (
        <span
          style={{
            position: "absolute",
            top: -size * 0.15,
            right: -size * 0.15,
            background: "#ff4757",
            borderRadius: "50%",
            width: size * 0.4,
            height: size * 0.4,
            border: "2px solid white",
          }}
        />
      )}
      {newSoftwareAvailable && (
        <div
          style={{
            position: "absolute",
            top: -size * 0.1,
            left: -size * 0.1,
            right: -size * 0.1,
            bottom: -size * 0.1,
            borderRadius: "50%",
            animation: "pulse 2s infinite",
          }}
        />
      )}
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            marginTop: "10px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "15px",
            width: "280px",
            maxHeight: "400px",
            overflowY: "auto",
            zIndex: 1000,
          }}
        >
          <h3 style={{ margin: "0 0 10px", color: "#333" }}>Notifications</h3>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                onClear={(e) => handleClearNotification(index, e)}
                onClick={handleNotificationClick}
              />
            ))
          ) : (
            <p style={{ margin: "0", color: "#666" }}>No notifications</p>
          )}
        </div>
      )}
      <style>
        {`
                @keyframes pulse {
                    0% {
                        box-shadow: 0 0 0 0 rgba(255, 114, 20, 0.7);
                    }
                    70% {
                        box-shadow: 0 0 0 ${size * 0.5}px rgba(255, 114, 20, 0);
                    }
                    100% {
                        box-shadow: 0 0 0 0 rgba(255, 114, 20, 0);
                    }
                }
                `}
      </style>
    </div>
  );
};

export default NotificationBell;
