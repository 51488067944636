import styled, { keyframes } from 'styled-components';

const celebrate = keyframes`
  0% { transform: scale(1); opacity: 0; }
  25% { transform: scale(1.2); opacity: 1; }
  50% { transform: scale(0.95); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const ActionPane = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 16px;
  border-left: 1px solid #eee;
  min-width: 90px;
  justify-content: flex-end;
`;

export const CardLoadingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 4px;
  
  & div {
    display: flex;
    align-items: center;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const StatusText = styled.span<{ status: { installed: boolean; downloaded: boolean; operationIsRunning: boolean } }>`
  font-size: 13px;
  font-weight: 500;
  color: ${props => {
    if (props.status.operationIsRunning) return '#2563eb';
    if (props.status.installed) return '#059669';
    if (props.status.downloaded) return '#d97706';
    return '#666';
  }};
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  outline: none;
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  animation: ${celebrate} 0.6s ease-out, ${fadeOut} 0.3s ease-out 2s forwards;
`;

export const SuccessIcon = styled.div`
  color: #059669;
  display: flex;
  align-items: center;
`;
