import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';

const slideInAndUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0%;
  }
  20% {
    transform: translateY(100%);
    opacity: 20%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
`;

const ToastContainer = styled.div<{ variant: 'success' | 'error' | 'loading' }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: ${props => props.variant === 'success' ? '#10B981' : 
                      props.variant === 'error' ? '#EF4444' : 
                      '#3B82F6'};
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: ${slideInAndUp} 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  max-width: 400px;
  margin-bottom: 8px;
  will-change: transform;
`;

const IconWrapper = styled.div<{ variant: 'success' | 'error' | 'loading' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.variant === 'loading' && `
    animation: spin 1s linear infinite;
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  `}
`;

const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.9;
`;

interface UpdateToastProps {
  title: string;
  description: string;
  variant: 'success' | 'error' | 'loading';
}

export const UpdateToast: React.FC<UpdateToastProps> = ({ title, description, variant }) => {
  const Icon = variant === 'success' ? CheckCircle :
               variant === 'error' ? XCircle :
               Loader2;

  return (
    <ToastContainer variant={variant}>
      <IconWrapper variant={variant}>
        <Icon size={20} />
      </IconWrapper>
      <ToastContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ToastContent>
    </ToastContainer>
  );
}; 