import { gql, QueryResult, useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import Button from "../../components/shared/Button";
import { SidebarCardSection } from "../../components/shared/sidebar-card/SidebarCardSection";
import { EventResourceAccessVoucher } from "../../generated/graphql";
import { ButtonSizes, SortOrder } from "../../shared/enums";
import { eventResourceAccessVouchersFields } from "../../types/fragments/EventResourceAccessVoucherFragment";
import CreateOrEditEventResourceAccessVoucherDialog from "./CreateOrEditEventResourceAccessVoucherDialog";
import { H4, Text } from "../../components/shared/typography";
import EventResourceAccessVoucherCard from "./EventResourceAccessVoucherCard";
import { AccordionSection } from "../admin/group-admin";
import SelectField from "../../components/shared/SelectField";
import { Option } from "react-select/src/filters";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import CheckboxSpacer from "../../components/CheckboxSpacer";
import Checkbox from "../../components/shared/Checkbox";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import TextField from "../../components/shared/TextField";
import { debounce } from "lodash";

const Events: React.FC = () => {
  const [editingEvent, setEditingEvent] = useState<
    EventResourceAccessVoucher | undefined
  >(undefined);
  const [isCreatingEvent, setIsCreatingEvent] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const sortOrderOptions = [
    {
      value: "Event date (ascending)",
      label: "Event date (ascending)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Event date (descending)",
      label: "Event date (descending)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
    {
      value: "Created at (newest first)",
      label: "Created at (newest first)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Created at (oldest first)",
      label: "Created at (oldest first)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<number[]>(
    []
  );

  const {
    data,
    loading,
    error,
  }: QueryResult<{
    getEventResourceAccessVouchersAsAdmin: EventResourceAccessVoucher[];
  }> = useQuery(
    gql`
      query GetResourceAccessVouchers {
        getEventResourceAccessVouchersAsAdmin {
          ...EventResourceAccessVouchersFields
        }
      }
      ${eventResourceAccessVouchersFields}
    `
  );

  const learningSpaces = (() => {
    if (
      !data ||
      !data.getEventResourceAccessVouchersAsAdmin ||
      loading ||
      error
    ) {
      return [];
    } else {
      const nonUniqueLearningSpaces =
        data.getEventResourceAccessVouchersAsAdmin.map(
          (voucher) => voucher.learningSpace
        );
      const uniqueLearningSpaces = nonUniqueLearningSpaces.filter(
        (learningSpace1, i) =>
          nonUniqueLearningSpaces.findIndex(
            (learningSpace2) => learningSpace1.id === learningSpace2.id
          ) === i
      );
      return uniqueLearningSpaces;
    }
  })();

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 300),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  return (
    <SidebarCardSection title="" maxColumns={1}>
      <ActionBar
        title="Events"
        searchField={
          <TextField
            id="cy-search-courses"
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search"
            onChange={handleSearchChange}
          />
        }
        button={
          <Button
            size={ButtonSizes.Medium}
            onClick={() => setIsCreatingEvent(true)}
          >
            Create event
          </Button>
        }
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => {
                  setSelectedSortOption(option);
                }}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
            <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
              <FilterContainer
                onClear={() => {
                  setSelectedFilterOptions([]);
                }}
              >
                <H4 style={{ marginBottom: "24px" }}>Learning Spaces</H4>
                <CheckboxSpacer>
                  {learningSpaces.map((learningSpace) => (
                    <Checkbox
                      testId={`cy-checkbox-${learningSpace.name}`}
                      key={learningSpace.id}
                      title={learningSpace.name}
                      checkboxChecked={selectedFilterOptions.some(
                        (selectedFilterOption) =>
                          selectedFilterOption === learningSpace.id
                      )}
                      onCheckboxClick={() => {
                        const newSelectedFilterOptions =
                          selectedFilterOptions.some(
                            (selectedFilterOption) =>
                              selectedFilterOption === learningSpace.id
                          )
                            ? selectedFilterOptions.filter(
                                (filteredLearningSpaceId) =>
                                  filteredLearningSpaceId !== learningSpace.id
                              )
                            : [...selectedFilterOptions, learningSpace.id];

                        setSelectedFilterOptions(newSelectedFilterOptions);
                      }}
                    />
                  ))}
                </CheckboxSpacer>
              </FilterContainer>
            </DropdownMenu>
          </>
        }
      />
      <SidebarCardSection title="" maxColumns={1}>
        {data &&
          data.getEventResourceAccessVouchersAsAdmin &&
          (() => {
            const filteredAndSortedAllVouchers = [
              ...data.getEventResourceAccessVouchersAsAdmin,
            ]
              .filter((voucher) => {
                // Filter on learning space
                if (selectedFilterOptions.length === 0) {
                  return true;
                } else {
                  return selectedFilterOptions.some(
                    (learningSpaceID) =>
                      voucher.learningSpace.id === learningSpaceID
                  );
                }
              })
              .filter((voucher) => {
                // Filter on search text
                if (searchText === "") {
                  return true;
                } else {
                  const matches = (str: string) =>
                    str.toLowerCase().includes(searchText.toLowerCase());

                  return (
                    matches(voucher.name) ||
                    matches(voucher.description) ||
                    matches(voucher.learningSpace.name) ||
                    matches(voucher.customer.name) ||
                    matches(
                      `${voucher.creator.first_name} ${voucher.creator.last_name}`
                    ) ||
                    voucher.organizers.some((organizer) =>
                      matches(`${organizer.first_name} ${organizer.last_name}`)
                    ) ||
                    voucher.users.some((user) =>
                      matches(`${user.first_name} ${user.last_name}`)
                    )
                  );
                }
              })
              .sort((voucher1, voucher2) => {
                switch (selectedSortOption.value) {
                  case "Event date (ascending)":
                    return new Date(voucher1.eventAt) <
                      new Date(voucher2.eventAt)
                      ? -1
                      : 1;
                  case "Event date (descending)":
                    return new Date(voucher1.eventAt) >
                      new Date(voucher2.eventAt)
                      ? -1
                      : 1;
                  case "Created at (oldest first)":
                    return new Date(voucher1.createdAt) <
                      new Date(voucher2.createdAt)
                      ? -1
                      : 1;
                  case "Created at (newest first)":
                  default:
                    return new Date(voucher1.createdAt) >
                      new Date(voucher2.createdAt)
                      ? -1
                      : 1;
                }
              });
            const upcomingAndOngoingEventVouchers =
              filteredAndSortedAllVouchers.filter(
                (voucher) =>
                  voucher.archivedAt === null &&
                  new Date(voucher.validTo) >= new Date()
              );
            const pastEventVouchers = filteredAndSortedAllVouchers.filter(
              (voucher) =>
                voucher.archivedAt === null &&
                new Date(voucher.validTo) < new Date()
            );
            const archivedEventVouchers = filteredAndSortedAllVouchers.filter(
              (voucher) => voucher.archivedAt !== null
            );
            return (
              <>
                {upcomingAndOngoingEventVouchers.length > 0 && (
                  <AccordionSection
                    title={`Upcoming and ongoing events (${upcomingAndOngoingEventVouchers.length})`}
                    buttons={[]}
                  >
                    {upcomingAndOngoingEventVouchers.map((voucher) => (
                      <EventResourceAccessVoucherCard
                        voucher={voucher}
                        key={voucher.id}
                        setIsEditingVoucher={() => setEditingEvent(voucher)}
                        editable={true}
                        archivable={true}
                      />
                    ))}
                  </AccordionSection>
                )}
                {pastEventVouchers.length > 0 && (
                  <AccordionSection
                    title={`Past events (${pastEventVouchers.length})`}
                    buttons={[]}
                  >
                    {pastEventVouchers.map((voucher) => (
                      <EventResourceAccessVoucherCard
                        voucher={voucher}
                        key={voucher.id}
                        setIsEditingVoucher={() => setEditingEvent(voucher)}
                        editable={false}
                        archivable={true}
                      />
                    ))}
                  </AccordionSection>
                )}
                {archivedEventVouchers.length > 0 && (
                  <AccordionSection
                    title={`Archived events (${archivedEventVouchers.length})`}
                    buttons={[]}
                  >
                    {archivedEventVouchers.map((voucher) => (
                      <EventResourceAccessVoucherCard
                        voucher={voucher}
                        key={voucher.id}
                        setIsEditingVoucher={() => setEditingEvent(voucher)}
                        editable={false}
                        archivable={false}
                      />
                    ))}
                  </AccordionSection>
                )}
              </>
            );
          })()}
        {loading && <LoadingIndicator />}
        {error && (
          <>
            {error.graphQLErrors.map((error: { message: string }) => (
              <Text>{error.message}</Text>
            ))}
          </>
        )}
      </SidebarCardSection>
      {editingEvent && (
        <CreateOrEditEventResourceAccessVoucherDialog
          editingEvent={editingEvent}
          onClose={() => setEditingEvent(undefined)}
        />
      )}
      {isCreatingEvent && (
        <CreateOrEditEventResourceAccessVoucherDialog
          onClose={() => setIsCreatingEvent(false)}
        />
      )}
    </SidebarCardSection>
  );
};

export default Events;
