export const paginationLimit = 5;
export const limit = 25;

export const getPagesList = (activePage: number, totalPages: number) => {
  let pages = [];
  for (var i = 2; i <= totalPages - 1; i++) {
    if (i <= paginationLimit && activePage < paginationLimit) {
      pages.push(i);
    } else if (i >= totalPages - 4 && activePage > totalPages - 3) {
      pages.push(i);
    } else if (i >= activePage - 2 && i <= activePage + 1) {
      pages.push(i);
    }
  }
  return pages;
};

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const handlePageChange = (
  page: number,
  totalPages: number,
  setActivePage: (active: number) => void,
  setOffset: (offset: number) => void
) => {
  const positiveTotalPages =
    page <= 1 ? 1 : totalPages <= 1 ? 1 : page > totalPages ? totalPages : page;
  setActivePage(positiveTotalPages);
  setOffset((positiveTotalPages - 1) * limit);
};
