import { gql, QueryResult, useQuery } from "@apollo/client";
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import SelectField from "../../components/shared/SelectField";
import TextField from "../../components/shared/TextField";
import CourseListingCard from "../../components/TalentCourses/CourseListingCard";
import { TalentBranch, TalentCourseListing } from "../../generated/graphql";
import { SortOrder, TalentCourseCategory } from "../../shared/enums";
import { Option } from "react-select/src/filters";
import LoadingIndicator from "../../components/shared/LoadingIndicator";
import { H4, Text } from "../../components/shared/typography";
import { DropdownMenu } from "../../components/shared/dropdown-menu/DropdownMenu";
import { FilterContainer } from "../../components/shared/dropdown-menu/FilterContainer";
import CheckboxSpacer from "../../components/CheckboxSpacer";
import Checkbox from "../../components/shared/Checkbox";

const CourseListings: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const sortOrderOptions = [
    {
      value: "Name (A-Z)",
      label: "Name (A-Z)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Name (Z-A)",
      label: "Name (Z-A)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
    {
      value: "Code (A-Z)",
      label: "Code (A-Z)",
      data: {
        sortOrder: SortOrder.AZ,
      },
    },
    {
      value: "Code (Z-A)",
      label: "Code (Z-A)",
      data: {
        sortOrder: SortOrder.ZA,
      },
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<number[]>(
    []
  );

  const {
    data,
    loading,
    error,
  }: QueryResult<{
    talentCourseListings: TalentCourseListing[];
  }> = useQuery(
    gql`
      query GetCourses {
        talentCourseListings {
          id
          name
          code
          category
          learningSpaces {
            id
            code
            name
          }
        }
      }
    `
  );

  const learningSpaces = (() => {
    if (!data || !data.talentCourseListings || loading || error) {
      return [];
    } else {
      const learningSpaces: TalentBranch[] = [];
      data.talentCourseListings.forEach((talentCourseListing) => {
        talentCourseListing.learningSpaces.forEach((newLearningSpace) => {
          if (
            !learningSpaces.some(
              (existingLearningSpace) =>
                existingLearningSpace.id === newLearningSpace.id
            )
          ) {
            learningSpaces.push(newLearningSpace);
          }
        });
      });
      return learningSpaces;
    }
  })();

  const debounceRequest = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 300),
    [setSearchText]
  );

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  return (
    <>
      <ActionBar
        title={"Courses"}
        searchField={
          <TextField
            id="cy-search-courses"
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by course name or code"
            onChange={handleSearchChange}
          />
        }
        button={<></>}
        buttonGroup={
          <>
            <SelectFieldWidthProvider>
              <SelectField
                name="Sort by"
                isActionBar={true}
                onChange={(option: Option) => setSelectedSortOption(option)}
                value={{
                  value: selectedSortOption.value,
                  label: `Sort: ${selectedSortOption.label}`,
                  data: {
                    sortField: selectedSortOption.data.sortField,
                    sortOrder: selectedSortOption.data.sortOrder,
                  },
                }}
                options={sortOrderOptions}
              />
            </SelectFieldWidthProvider>
            <DropdownMenu isFilter icon={"/static/assets/filter.svg"}>
              <FilterContainer
                onClear={() => {
                  setSelectedFilterOptions([]);
                }}
              >
                <H4 style={{ marginBottom: "24px" }}>Learning Spaces</H4>
                <CheckboxSpacer>
                  {learningSpaces.map((learningSpace) => (
                    <Checkbox
                      testId={`cy-checkbox-${learningSpace.name}`}
                      key={learningSpace.id}
                      title={learningSpace.name}
                      checkboxChecked={selectedFilterOptions.some(
                        (selectedFilterOption) =>
                          selectedFilterOption === learningSpace.id
                      )}
                      onCheckboxClick={() => {
                        const newSelectedFilterOptions =
                          selectedFilterOptions.some(
                            (selectedFilterOption) =>
                              selectedFilterOption === learningSpace.id
                          )
                            ? selectedFilterOptions.filter(
                                (filteredLearningSpaceId) =>
                                  filteredLearningSpaceId !== learningSpace.id
                              )
                            : [...selectedFilterOptions, learningSpace.id];

                        setSelectedFilterOptions(newSelectedFilterOptions);
                      }}
                    />
                  ))}
                </CheckboxSpacer>
              </FilterContainer>
            </DropdownMenu>
          </>
        }
      />
      {data &&
        data.talentCourseListings &&
        [...data.talentCourseListings]
          .filter((courseListing) => {
            if (selectedFilterOptions.length === 0) {
              return true;
            } else {
              return courseListing.learningSpaces.some((learningSpace) =>
                selectedFilterOptions.some(
                  (selectedFilterOption) =>
                    selectedFilterOption === learningSpace.id
                )
              );
            }
          })
          .filter((courseListing) => {
            if (searchText === "") {
              return true;
            } else {
              return (
                courseListing.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (courseListing.code ?? "")
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            }
          })
          .sort((courseListing1, courseListing2) => {
            switch (selectedSortOption.value) {
              case "Name (Z-A)":
                return courseListing1.name.toLowerCase() <
                  courseListing2.name.toLowerCase()
                  ? 1
                  : -1;
              case "Code (A-Z)":
                return (courseListing1.code?.toLowerCase() ?? "") >
                  (courseListing2.code?.toLowerCase() ?? "")
                  ? 1
                  : -1;
              case "Code (Z-A)":
                return (courseListing1.code?.toLowerCase() ?? "") <
                  (courseListing2.code?.toLowerCase() ?? "")
                  ? 1
                  : -1;
              case "Name (A-Z)":
              default:
                return courseListing1.name.toLowerCase() >
                  courseListing2.name.toLowerCase()
                  ? 1
                  : -1;
            }
          })
          .map((courseListing) => (
            <CourseListingCard
              key={courseListing.id}
              title={courseListing.name}
              code={courseListing.code || undefined}
              category={courseListing.category as TalentCourseCategory}
            />
          ))}
      {loading && <LoadingIndicator />}
      {error && (
        <>
          {error.graphQLErrors.map((error: { message: string }) => (
            <Text>{error.message}</Text>
          ))}
        </>
      )}
    </>
  );
};

export default CourseListings;
