import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject_: any;
  DateTime_: any;
  Date: any;
  Time: any;
  DateTime: any;
  Timestamp: any;
  UtcOffset: any;
  Duration: any;
  ISO8601Duration: any;
  LocalDate: any;
  LocalTime: any;
  LocalEndTime: any;
  EmailAddress: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PhoneNumber: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  URL: any;
  BigInt: any;
  Byte: any;
  Long: any;
  SafeInt: any;
  UUID: any;
  GUID: any;
  Hexadecimal: any;
  HexColorCode: any;
  HSL: any;
  HSLA: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  MAC: any;
  Port: any;
  RGB: any;
  RGBA: any;
  USCurrency: any;
  Currency: any;
  JSON: any;
  JSONObject: any;
  IBAN: any;
  ObjectID: any;
  Void: any;
};



export type MutationResponse = {
  __typename?: 'MutationResponse';
  code: Scalars['Int'];
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  IRLogFormData: IrLogFormData;
  activities: ProcedureActivityMeta;
  activity: ProcedureActivity;
  allCustomerGroups: Array<CustomerGroup>;
  allSoftwareReleases: Array<Maybe<SoftwareRelease>>;
  allSystems: Array<Maybe<System>>;
  allSystemsAndSoftwaresReleases: Array<Maybe<SystemSoftwaresReleases>>;
  allTalentBranchCourses: Array<Maybe<TalentCourse>>;
  allVistActivities: AllVistActivityItems;
  attendings: Array<Attending>;
  caseDownloadLink: Scalars['URL'];
  cloudCaseVMs: Array<CloudCaseVirtualMachine>;
  coursesByGroup: Array<TalentCourse>;
  customer: Customer;
  customerGroups: Array<CustomerGroup>;
  customerGroupsByUser: Array<CustomerGroup>;
  customerLearningSpaceAccess?: Maybe<CustomerLearningSpaceAccess>;
  customers: Array<Customer>;
  enrolledTalentBranches: Array<Maybe<TalentBranch>>;
  getAllCloudModulesResourceAccessVouchersAsAdmin: Array<CloudModulesResourceAccessVoucher>;
  getCloudModulesResourceAccessVouchersAsTrainee: Array<SingleUserCloudModulesResourceAccessVoucher>;
  getCustomerGroup: CustomerGroup;
  getCustomersForAdmin: Array<Customer>;
  getEventResourceAccessVoucherById?: Maybe<EventResourceAccessVoucher>;
  getEventResourceAccessVouchersAsAdmin: Array<EventResourceAccessVoucher>;
  getEventResourceAccessVouchersAsTrainee: Array<EventResourceAccessVoucher>;
  getVmAssignedToMe?: Maybe<CloudCaseVirtualMachine>;
  hospitals: Array<Hospital>;
  logDownloadLink: Scalars['URL'];
  maxClientVersion?: Maybe<SoftwareRelease>;
  newsArticles: Array<NewsArticle>;
  simulation: Simulation;
  singleUserCloudCaseVMAvailable?: Maybe<Scalars['Boolean']>;
  softwareReleaseByReleaseId?: Maybe<Array<Maybe<SoftwareRelease>>>;
  softwareReleasesBySystemsIds: Array<Maybe<SoftwareRelease>>;
  systemAndSoftwareReleasesBySerialNumber?: Maybe<SystemSoftwaresReleases>;
  systemById: System;
  systemReleaseBySystemAndSoftwareId: Array<Maybe<SystemRelease>>;
  systemsBySoftwaresIds: Array<Maybe<System>>;
  talentBranchByName?: Maybe<TalentBranch>;
  talentBranches: Array<TalentBranch>;
  talentCourse: TalentCourse;
  talentCourseListings: Array<TalentCourseListing>;
  user: User;
  vistActivities: Array<VistActivityItem>;
  vistActivitiesByServiceTag: Array<VistActivityItem>;
  vistActivity: VistActivityItem;
  vistAvailableReleasesForSystem: Array<VistSystemSoftwareRelease>;
  vistCases: Array<VistCourse>;
  vistComputerGet?: Maybe<VistSystemComputer>;
  vistCourse: VistCourse;
  vistCourses: Array<VistCourse>;
  vistReleaseGetAll: Array<VistRelease>;
  vistReleaseOpsForSystem: Array<VistReleaseOp>;
  vistSystemReleaseStatesForTag: Array<VistSystemSoftwareReleaseState>;
  vistSystems: Array<VistSystem>;
  vistSystemsByServiceTag: Array<VistSystem>;
  vistSystemsLogsNumberByPeriod: Scalars['Int'];
  vistSystemsStats: SystemsStats;
  youtubeVideos: Array<YoutubeVideo>;
};


export type QueryActivitiesArgs = {
  searchParams: ProcedureActivitySearchParams;
};


export type QueryActivityArgs = {
  id: Scalars['ID'];
};


export type QueryAllSystemsArgs = {
  customerId: Scalars['Int'];
};


export type QueryAllTalentBranchCoursesArgs = {
  talentBranchId: Scalars['String'];
  emailAddress: Scalars['String'];
};


export type QueryAllVistActivitiesArgs = {
  searchParams?: Maybe<ActivitySearchParams>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAttendingsArgs = {
  customerId: Scalars['Int'];
};


export type QueryCaseDownloadLinkArgs = {
  unitId: Scalars['String'];
};


export type QueryCoursesByGroupArgs = {
  customerGroupId: Scalars['Int'];
};


export type QueryCustomerArgs = {
  customerId: Scalars['Int'];
};


export type QueryCustomerGroupsArgs = {
  roles?: Maybe<Array<GroupRole>>;
};


export type QueryCustomerLearningSpaceAccessArgs = {
  customerId: Scalars['Int'];
  learningSpaceId: Scalars['String'];
};


export type QueryEnrolledTalentBranchesArgs = {
  emailAddress: Scalars['String'];
};


export type QueryGetCustomerGroupArgs = {
  customerGroupId: Scalars['Int'];
};


export type QueryGetEventResourceAccessVoucherByIdArgs = {
  voucherId: Scalars['Int'];
};


export type QueryHospitalsArgs = {
  customerId: Scalars['Int'];
};


export type QueryLogDownloadLinkArgs = {
  activityIds: Array<Scalars['String']>;
};


export type QuerySimulationArgs = {
  id: Scalars['Int'];
};


export type QuerySingleUserCloudCaseVmAvailableArgs = {
  durationInSeconds: Scalars['Int'];
};


export type QuerySoftwareReleaseByReleaseIdArgs = {
  releaseId: Scalars['String'];
};


export type QuerySoftwareReleasesBySystemsIdsArgs = {
  systemsIds: Array<Scalars['Int']>;
  customerId: Scalars['Int'];
};


export type QuerySystemAndSoftwareReleasesBySerialNumberArgs = {
  serialNumber: Scalars['String'];
};


export type QuerySystemByIdArgs = {
  id: Scalars['Int'];
};


export type QuerySystemReleaseBySystemAndSoftwareIdArgs = {
  systemId: Scalars['Int'];
  softwareId: Scalars['Int'];
};


export type QuerySystemsBySoftwaresIdsArgs = {
  softwaresIds: Array<Scalars['Int']>;
};


export type QueryTalentBranchByNameArgs = {
  talentBranchName: Scalars['String'];
};


export type QueryTalentCourseArgs = {
  talentCourseId: Scalars['Int'];
};


export type QueryUserArgs = {
  emailAddress: Scalars['String'];
};


export type QueryVistActivitiesArgs = {
  groups: Array<Scalars['Int']>;
  searchParams?: Maybe<ActivitySearchParams>;
};


export type QueryVistActivitiesByServiceTagArgs = {
  serviceTag: Scalars['String'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type QueryVistActivityArgs = {
  id: Scalars['String'];
};


export type QueryVistAvailableReleasesForSystemArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistCasesArgs = {
  serviceTag: Scalars['String'];
  searchParams?: Maybe<VistCourseSearchParams>;
};


export type QueryVistComputerGetArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistCourseArgs = {
  code: Scalars['String'];
  version: Scalars['String'];
  serviceTag: Scalars['String'];
};


export type QueryVistCoursesArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistReleaseOpsForSystemArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistSystemReleaseStatesForTagArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistSystemsByServiceTagArgs = {
  serviceTag: Scalars['String'];
};


export type QueryVistSystemsLogsNumberByPeriodArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryYoutubeVideosArgs = {
  maxResults: Scalars['Int'];
};

export type VistSoftware = {
  __typename?: 'VistSoftware';
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type VistRelease = {
  __typename?: 'VistRelease';
  id: Scalars['ID'];
  stringIdentifier: Scalars['String'];
  software: VistSoftware;
  version: VistReleaseVersion;
  resources?: Maybe<VistReleaseResources>;
};

export type VistReleaseResources = {
  __typename?: 'VistReleaseResources';
  installer: VistReleaseResource;
};

export type VistReleaseResource = {
  __typename?: 'VistReleaseResource';
  id: Scalars['ID'];
  key: Scalars['String'];
  fileType: Scalars['String'];
  checksum?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  size: Scalars['Int'];
};

export type VistReleaseVersion = {
  __typename?: 'VistReleaseVersion';
  semantic?: Maybe<Scalars['String']>;
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  suffix?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  QRLogin: QrLoginResponse;
  acceptInvite: InviteResponse;
  acceptResourceAccessVoucher: RefetchPayload;
  acceptVoucher: VoucherResponse;
  addCustomerAdmin?: Maybe<Scalars['Void']>;
  addCustomerCloudModuleQuotaTransaction: RefetchPayload;
  archiveEventResourceAccessVoucher: RefetchPayload;
  archiveResourceAccessVoucher: RefetchPayload;
  createAttending: RefetchPayload;
  createCloudModulesResourceAccessVoucher: RefetchPayload;
  createCourseVoucher: Voucher;
  createCustomer?: Maybe<Customer>;
  createCustomerGroup: RefetchPayload;
  createEventResourceAccessVoucher: RefetchPayload;
  createHospital: RefetchPayload;
  customerGroupCourseAccess: RefetchPayload;
  deleteActivity: RefetchPayload;
  deleteAttending: RefetchPayload;
  deleteHospital: RefetchPayload;
  deleteSoftwareRelease: RefetchPayload;
  deleteSystem: RefetchPayload;
  deleteSystemRelease: RefetchPayload;
  editCloudModulesResourceAccessVoucher: RefetchPayload;
  editEventResourceAccessVoucher?: Maybe<RefetchPayload>;
  enrollGroupUserInCourse: RefetchPayload;
  enrollTalentUserInCourse: TalentCourse;
  leaveGroup: RefetchPayload;
  removeAllUsers: RefetchPayload;
  removeCustomerAdmin?: Maybe<Scalars['Void']>;
  removeCustomerGroup: RefetchPayload;
  removeUser: RefetchPayload;
  removeVM: RefetchPayload;
  resetAccountProgress?: Maybe<ResetResult>;
  resetMyCourseProgress: RefetchPayload;
  resetVMToIdle: RefetchPayload;
  resetVMToOff: RefetchPayload;
  saveActivity: RefetchPayload;
  saveCustomer: RefetchPayload;
  saveCustomerGroup: RefetchPayload;
  saveLearningSpace: RefetchPayload;
  saveLearningSpaceAccess: RefetchPayload;
  saveSoftwareRelease: SoftwareRelease;
  saveSystem: System;
  saveSystemRelease: SystemRelease;
  startCloudCaseEvent: Scalars['String'];
  startSingleUserCloudCase: Scalars['String'];
  stopCloudCaseSessionWithVoucherId: RefetchPayload;
  syncWithTalent?: Maybe<Scalars['Void']>;
  turnOffVM: RefetchPayload;
  turnOnVM: RefetchPayload;
  updateAttending: RefetchPayload;
  updateCustomer: Customer;
  updateHospital: RefetchPayload;
  updateSoftwareRelease: RefetchPayload;
  updateSystem: RefetchPayload;
  updateSystemReleaseStatus: RefetchPayload;
  updateUser: RefetchPayload;
  uploadInstall?: Maybe<Scalars['Boolean']>;
  uploadVistActivity?: Maybe<Scalars['Boolean']>;
  vistComputerSetInfo: MutationResponse;
  vistReleaseOpSetDetailedState: MutationResponse;
  vistSystemReleaseStateSetAllDownloadedForTag: MutationResponse;
  vistSystemReleaseStateSetAllInstalledForTag: MutationResponse;
  vistSystemSoftwareReleaseSetDownload: MutationResponse;
  vistSystemSoftwareReleaseSetInstall: MutationResponse;
};


export type MutationQrLoginArgs = {
  payload: Scalars['String'];
};


export type MutationAcceptInviteArgs = {
  code: Scalars['String'];
};


export type MutationAcceptResourceAccessVoucherArgs = {
  code: Scalars['String'];
};


export type MutationAcceptVoucherArgs = {
  code: Scalars['String'];
};


export type MutationAddCustomerAdminArgs = {
  customerId: Scalars['String'];
  admin: Scalars['String'];
};


export type MutationAddCustomerCloudModuleQuotaTransactionArgs = {
  customerId: Scalars['Int'];
  timeQuotumDifferenceInMs: Scalars['Int'];
};


export type MutationArchiveEventResourceAccessVoucherArgs = {
  eventResourceAccessVoucherId: Scalars['Int'];
};


export type MutationArchiveResourceAccessVoucherArgs = {
  resourceAccessVoucherId: Scalars['Int'];
};


export type MutationCreateAttendingArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
};


export type MutationCreateCloudModulesResourceAccessVoucherArgs = {
  createCloudModulesResourceAccessVoucher: CreateCloudModulesResourceAccessVoucherInput;
};


export type MutationCreateCourseVoucherArgs = {
  voucher: VoucherInput;
};


export type MutationCreateCustomerArgs = {
  name: Scalars['String'];
};


export type MutationCreateCustomerGroupArgs = {
  customerGroup: CreateCustomerGroup;
};


export type MutationCreateEventResourceAccessVoucherArgs = {
  createEventResourceAccessVoucherInput: CreateEventResourceAccessVoucherInput;
};


export type MutationCreateHospitalArgs = {
  name: Scalars['String'];
  customerId: Scalars['Int'];
};


export type MutationCustomerGroupCourseAccessArgs = {
  customerGroupId: Scalars['Int'];
  courseIds: Array<Scalars['Int']>;
  fullRemoval: Scalars['Boolean'];
};


export type MutationDeleteActivityArgs = {
  activity: ProcedureActivityInput;
};


export type MutationDeleteAttendingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteHospitalArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSoftwareReleaseArgs = {
  softwareId: Scalars['Int'];
};


export type MutationDeleteSystemArgs = {
  systemId: Scalars['Int'];
};


export type MutationDeleteSystemReleaseArgs = {
  systemId: Scalars['Int'];
  softwareId: Scalars['Int'];
};


export type MutationEditCloudModulesResourceAccessVoucherArgs = {
  editCloudModulesResourceAccessVoucher: EditCloudModulesResourceAccessVoucherInput;
};


export type MutationEditEventResourceAccessVoucherArgs = {
  editEventResourceAccessVoucherInput: EditEventResourceAccessVoucherInput;
};


export type MutationEnrollGroupUserInCourseArgs = {
  talentCourseId: Scalars['Int'];
  emailAddress: Scalars['String'];
  customerGroupId: Scalars['Int'];
};


export type MutationEnrollTalentUserInCourseArgs = {
  talentCourseId: Scalars['Int'];
  emailAddress: Scalars['String'];
  customerGroupId?: Maybe<Scalars['Int']>;
};


export type MutationLeaveGroupArgs = {
  customerGroupId: Scalars['Int'];
};


export type MutationRemoveAllUsersArgs = {
  customerGroupId: Scalars['Int'];
};


export type MutationRemoveCustomerAdminArgs = {
  customerId: Scalars['String'];
  adminId: Scalars['String'];
};


export type MutationRemoveCustomerGroupArgs = {
  customerId: Scalars['Int'];
  customerGroupId: Scalars['Int'];
  retainCourseAccess: Scalars['Boolean'];
};


export type MutationRemoveUserArgs = {
  customerGroupId: Scalars['Int'];
  userId: Scalars['String'];
};


export type MutationRemoveVmArgs = {
  vmId: Scalars['Int'];
};


export type MutationResetMyCourseProgressArgs = {
  courseId: Scalars['String'];
};


export type MutationResetVmToIdleArgs = {
  vmId: Scalars['Int'];
};


export type MutationResetVmToOffArgs = {
  vmId: Scalars['Int'];
};


export type MutationSaveActivityArgs = {
  activity: ProcedureActivityInput;
  customerId?: Maybe<Scalars['Int']>;
  attendingName?: Maybe<Scalars['String']>;
};


export type MutationSaveCustomerArgs = {
  customer: SaveCustomerInput;
};


export type MutationSaveCustomerGroupArgs = {
  customerGroup: SaveCustomerGroup;
};


export type MutationSaveLearningSpaceArgs = {
  id: Scalars['String'];
  displayName: Scalars['String'];
  image: Scalars['String'];
};


export type MutationSaveLearningSpaceAccessArgs = {
  customerId: Scalars['Int'];
  learningSpaceId: Scalars['String'];
  courses: Array<Scalars['Int']>;
  fullAccess: Scalars['Boolean'];
};


export type MutationSaveSoftwareReleaseArgs = {
  softwareRelease: SoftwareReleaseInput;
};


export type MutationSaveSystemArgs = {
  system: SystemInput;
};


export type MutationSaveSystemReleaseArgs = {
  systemRelease: SystemReleaseInput;
};


export type MutationStartCloudCaseEventArgs = {
  moduleId: Scalars['String'];
  caseId: Scalars['String'];
  voucherId: Scalars['Int'];
};


export type MutationStartSingleUserCloudCaseArgs = {
  moduleId: Scalars['String'];
  caseId: Scalars['String'];
  voucherId: Scalars['Int'];
};


export type MutationStopCloudCaseSessionWithVoucherIdArgs = {
  voucherId: Scalars['Int'];
};


export type MutationTurnOffVmArgs = {
  url: Scalars['String'];
};


export type MutationTurnOnVmArgs = {
  url: Scalars['String'];
};


export type MutationUpdateAttendingArgs = {
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
};


export type MutationUpdateHospitalArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationUpdateSoftwareReleaseArgs = {
  id: Scalars['Int'];
  softwareRelease: SoftwareReleaseInput;
};


export type MutationUpdateSystemArgs = {
  id: Scalars['Int'];
  system: SystemInput;
};


export type MutationUpdateSystemReleaseStatusArgs = {
  status: Scalars['String'];
  systemId: Scalars['Int'];
  softwareId: Scalars['Int'];
};


export type MutationUpdateUserArgs = {
  user: UserInput;
  emailAddress: Scalars['String'];
};


export type MutationUploadInstallArgs = {
  installation: QtInstallationInput;
};


export type MutationUploadVistActivityArgs = {
  metadata: VistActivityInput;
};


export type MutationVistComputerSetInfoArgs = {
  serviceTag: Scalars['String'];
  info: Scalars['String'];
};


export type MutationVistReleaseOpSetDetailedStateArgs = {
  id: Scalars['ID'];
  input: VistReleaseOpDetailedStateInput;
};


export type MutationVistSystemReleaseStateSetAllDownloadedForTagArgs = {
  serviceTag: Scalars['String'];
  softwares: Array<VistReleaseIdentifierInput>;
};


export type MutationVistSystemReleaseStateSetAllInstalledForTagArgs = {
  serviceTag: Scalars['String'];
  softwares: Array<VistReleaseIdentifierInput>;
};


export type MutationVistSystemSoftwareReleaseSetDownloadArgs = {
  id: Scalars['ID'];
};


export type MutationVistSystemSoftwareReleaseSetInstallArgs = {
  id: Scalars['ID'];
};

export type VistSystemComputer = {
  __typename?: 'VistSystemComputer';
  id: Scalars['ID'];
  serviceTag: Scalars['String'];
  info?: Maybe<Scalars['String']>;
};

export type VistSystemSoftwareRelease = {
  __typename?: 'VistSystemSoftwareRelease';
  id: Scalars['ID'];
  release: VistRelease;
  state: VistSystemSoftwareReleaseState;
  currentRelease?: Maybe<VistRelease>;
  createdAt?: Maybe<Scalars['DateTime_']>;
};

export type VistReleaseOp = {
  __typename?: 'VistReleaseOp';
  id: Scalars['ID'];
  releaseId: Scalars['ID'];
  releaseIdentifier: Scalars['String'];
  downloadOnly: Scalars['Boolean'];
  installerLink?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSONObject_']>;
};

export type VistReleaseOpDetailedStateInput = {
  download?: Maybe<OperationDetailedStateInput>;
  install?: Maybe<OperationDetailedStateInput>;
};

export type OperationDetailedStateInput = {
  state: Scalars['String'];
  progress: Scalars['Float'];
  errors: Array<Scalars['String']>;
};

export type VistSystemSoftwareReleaseState = {
  __typename?: 'VistSystemSoftwareReleaseState';
  id: Scalars['ID'];
  system: VistSystemComputer;
  release: VistRelease;
  downloaded: Scalars['Boolean'];
  installed: Scalars['Boolean'];
  operationStates: VistSystemSoftwareReleaseOperations;
  operationIsRunning: Scalars['Boolean'];
};

export type VistReleaseIdentifierInput = {
  softwareCode: Scalars['String'];
  versionString: Scalars['String'];
  softwareName?: Maybe<Scalars['String']>;
};

export type VistSystemSoftwareReleaseOperations = {
  __typename?: 'VistSystemSoftwareReleaseOperations';
  download: OperationDetailedState;
  install: OperationDetailedState;
};

export type OperationDetailedState = {
  __typename?: 'OperationDetailedState';
  state: Scalars['String'];
  progress: Scalars['Float'];
  errors: Array<Scalars['String']>;
};

export type VistActivityInput = {
  id: Scalars['ID'];
  sourceUrl?: Maybe<Scalars['String']>;
  activityStart: Scalars['DateTime'];
  activityEnd: Scalars['DateTime'];
  activityLogVersion: Scalars['String'];
  clientVersion: Scalars['String'];
  exerciseId: Scalars['String'];
  exerciseName?: Maybe<Scalars['String']>;
  moduleId: Scalars['String'];
  moduleName: Scalars['String'];
  moduleVersion: Scalars['String'];
  unitId?: Maybe<Scalars['String']>;
  keycloakUserId?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  serviceTag: Scalars['String'];
  files: Array<Scalars['String']>;
  images: Array<QtImage>;
  recordings: Array<QtRecording>;
};

export type QtImage = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
  thumbnail: Scalars['String'];
};

export type QtRecording = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
  duration: Scalars['Duration'];
  thumbnail: Scalars['String'];
};

export type QtInstallationInput = {
  serviceTag: Scalars['String'];
  courseData: Array<QtCourseInput>;
};

export type QtCourseInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  version: Scalars['String'];
  resultFormatVersion: Scalars['String'];
  simulations: Array<QtSimulationInput>;
  license?: Maybe<QtCourseLicense>;
  checksum: Scalars['String'];
};

export type QtCourseLicense = {
  checked: Scalars['String'];
  simulations: Array<QtSimulationLicense>;
};

export type QtSimulationLicense = {
  id: Scalars['String'];
  valid: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  remainingdays: Scalars['Int'];
};

export type QtSimulationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  description: Scalars['String'];
  metrics: Array<QtMetricInput>;
  benchmarks: Array<QtBenchmarkInput>;
  groups: Scalars['String'];
  options: Scalars['String'];
  results: Array<QtResultInput>;
};

export type QtMetricInput = {
  internal_id: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  value_format: Scalars['String'];
  enum_type?: Maybe<Scalars['String']>;
  default_operator?: Maybe<Scalars['String']>;
  is_comparable?: Maybe<Scalars['Boolean']>;
};

export type QtBenchmarkInput = {
  label: Scalars['String'];
  description: Scalars['String'];
  metrics: Array<QtBenchmarkValueInput>;
};

export type QtBenchmarkValueInput = {
  internal_id: Scalars['String'];
  operator?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type QtResultInput = {
  date: Scalars['String'];
  user_name: Scalars['String'];
  xml_description: Scalars['String'];
  passed: Scalars['String'];
  values: Array<QtResultValueInput>;
};

export type QtResultValueInput = {
  metric_internal_id: Scalars['String'];
  value: Scalars['String'];
};

export enum CloudCaseVmStatus {
  Off = 'off',
  Desired = 'desired',
  Starting = 'starting',
  Started = 'started',
  Idle = 'idle',
  Void = 'void'
}

export enum GroupRole {
  Admin = 'Admin',
  Learner = 'Learner'
}

export type CloudCaseVirtualMachine = {
  __typename?: 'CloudCaseVirtualMachine';
  id: Scalars['Int'];
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  started_by?: Maybe<User>;
  module_id?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  voucher_id?: Maybe<Scalars['Int']>;
  session_started_at?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<CloudCaseVirtualMachineStateChange>>;
};

export type CloudCaseVirtualMachineStateChange = {
  __typename?: 'CloudCaseVirtualMachineStateChange';
  updated_at: Scalars['DateTime'];
  new_status: CloudCaseVmStatus;
  prev_status: CloudCaseVmStatus;
  started_by?: Maybe<User>;
  case_id?: Maybe<Scalars['String']>;
  module_id?: Maybe<Scalars['String']>;
  voucher_id?: Maybe<Scalars['Int']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  name: Scalars['String'];
  branches: Array<TalentBranch>;
  customerGroups: Array<CustomerGroup>;
  admins: Array<User>;
  cloudModuleTimeBalance: CustomerCloudModuleTimeBalance;
};

export type CustomerLearningSpaceAccess = {
  __typename?: 'CustomerLearningSpaceAccess';
  customer: Customer;
  learningSpace: TalentBranch;
  courses: Array<CustomerCourseAccess>;
  fullAccess: Scalars['Boolean'];
  cloudModules: Array<CloudModule>;
};

export type CustomerCourseAccess = {
  __typename?: 'CustomerCourseAccess';
  course: TalentCourse;
  allowed: Scalars['Boolean'];
};

export type CustomerGroup = {
  __typename?: 'CustomerGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  members?: Maybe<Array<User>>;
  admins?: Maybe<Array<User>>;
  invitationCode?: Maybe<InvitationCode>;
  customer?: Maybe<Customer>;
  courses?: Maybe<Array<CustomerGroupCourseAccess>>;
  assignedCourses?: Maybe<Array<Scalars['Int']>>;
};

export type CustomerGroupCourseAccess = {
  __typename?: 'CustomerGroupCourseAccess';
  course: TalentCourse;
  assigned: Scalars['Boolean'];
};

export type InvitationCode = {
  __typename?: 'InvitationCode';
  id: Scalars['Int'];
  code: Scalars['String'];
  customerGroupId: Scalars['Int'];
};

export type VistCourse = {
  __typename?: 'VistCourse';
  id: Scalars['Int'];
  code: Scalars['String'];
  version: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  resultFormatVersion: Scalars['String'];
  description: Scalars['String'];
  simulations: Array<Simulation>;
  serviceTag: Scalars['String'];
  hasCaseCreator: Scalars['Boolean'];
  license?: Maybe<VistCourseLicense>;
};

export type VistCourseLicense = {
  __typename?: 'VistCourseLicense';
  checked: Scalars['String'];
  simulations: Array<VistSimulationLicense>;
};

export type VistSimulationLicense = {
  __typename?: 'VistSimulationLicense';
  id: Scalars['String'];
  valid: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  remainingdays: Scalars['Int'];
};

export type VistSystem = {
  __typename?: 'VistSystem';
  serviceTag: Scalars['String'];
  simulationsDone: Scalars['Int'];
  lastSimulation?: Maybe<Scalars['DateTime']>;
};

export type SystemsStats = {
  __typename?: 'SystemsStats';
  connected: Scalars['Int'];
  loggedTotal: Scalars['Int'];
  loggedLastWeek: Scalars['Int'];
  logsTotal: Scalars['Int'];
  logsLastWeek: Scalars['Int'];
  logsLastMonth: Scalars['Int'];
  logsLastYear: Scalars['Int'];
};

export type Simulation = {
  __typename?: 'Simulation';
  id: Scalars['Int'];
  code: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  description: Scalars['String'];
  options?: Maybe<Scalars['String']>;
  isCaseCreator: Scalars['Boolean'];
  groups?: Maybe<Group>;
  metrics: Array<Metric>;
  benchmarks: Array<Benchmark>;
  results: Array<Result>;
};

export enum ActivityType {
  Vist = 'VIST',
  Irlog = 'IRLOG'
}

export type Activity = {
  id: Scalars['ID'];
  createdBy?: Maybe<User>;
  type: ActivityType;
  activityStart: Scalars['DateTime'];
  activityEnd: Scalars['DateTime'];
  totalTimeInSeconds: Scalars['Int'];
};

export type VistActivityItem = Activity & {
  __typename?: 'VistActivityItem';
  id: Scalars['ID'];
  createdBy?: Maybe<User>;
  type: ActivityType;
  activityStart: Scalars['DateTime'];
  activityEnd: Scalars['DateTime'];
  totalTimeInSeconds: Scalars['Int'];
  sourceUrl?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  unitName: Scalars['String'];
  courseName: Scalars['String'];
  course?: Maybe<TalentCourse>;
  result: Scalars['String'];
  progress: Scalars['Int'];
  exerciseId?: Maybe<Scalars['String']>;
  exerciseName?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['String']>;
  moduleName: Scalars['String'];
  moduleVersion?: Maybe<Scalars['String']>;
  serviceTag?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  finalResultLink?: Maybe<Scalars['URL']>;
  benchmarkLink?: Maybe<Scalars['URL']>;
  metricValuesLink?: Maybe<Scalars['URL']>;
  groupsLink?: Maybe<Scalars['URL']>;
  metricDescriptionsLink?: Maybe<Scalars['URL']>;
  images?: Maybe<Array<Image>>;
  image?: Maybe<Image>;
  recordings?: Maybe<Array<Recording>>;
  recording?: Maybe<Recording>;
};


export type VistActivityItemImageArgs = {
  id: Scalars['UUID'];
};


export type VistActivityItemRecordingArgs = {
  id: Scalars['UUID'];
};

export type AllVistActivityItems = {
  __typename?: 'AllVistActivityItems';
  activities?: Maybe<Array<VistActivityItem>>;
  count: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['UUID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  thumbnailLink?: Maybe<Scalars['URL']>;
  link?: Maybe<Scalars['URL']>;
};

export type Recording = {
  __typename?: 'Recording';
  id: Scalars['UUID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  duration: Scalars['Duration'];
  thumbnailLink?: Maybe<Scalars['URL']>;
  link?: Maybe<Scalars['URL']>;
};

export type Group = {
  __typename?: 'Group';
  label: Scalars['String'];
  description: Scalars['String'];
  groups?: Maybe<Array<Group>>;
  metrics?: Maybe<Array<Scalars['String']>>;
  is_expanded_by_default?: Maybe<Scalars['Boolean']>;
};

export type Metric = {
  __typename?: 'Metric';
  id: Scalars['Int'];
  internal_id: Scalars['String'];
  label: Scalars['String'];
  unit: Scalars['String'];
  data_type: Scalars['String'];
  default_operator: Scalars['String'];
  value_format: Scalars['String'];
  is_comparable: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type Benchmark = {
  __typename?: 'Benchmark';
  id: Scalars['Int'];
  label: Scalars['String'];
  description: Scalars['String'];
  values: Array<BenchmarkValue>;
};

export type BenchmarkValue = {
  __typename?: 'BenchmarkValue';
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  default_operator: Scalars['String'];
  is_shown_as_graph: Scalars['Boolean'];
  metric: Metric;
};

export type Result = {
  __typename?: 'Result';
  id: Scalars['Int'];
  date: Scalars['String'];
  user: Scalars['String'];
  files: Array<ResultFile>;
  values: Array<ResultValue>;
};

export type ResultFile = {
  __typename?: 'ResultFile';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ResultValue = {
  __typename?: 'ResultValue';
  id: Scalars['Int'];
  value: Scalars['String'];
  metric: Metric;
};

export type User = {
  __typename?: 'User';
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  keycloak_id: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hospital_university?: Maybe<Scalars['String']>;
  number_of_real_procedures_performed?: Maybe<Scalars['String']>;
  number_of_simulated_procedures_performed?: Maybe<Scalars['String']>;
  specialty_interventional_cardiology_years_experience?: Maybe<Scalars['String']>;
  specialty_interventional_neuroradiology_years_experience?: Maybe<Scalars['String']>;
  specialty_interventional_radiology_years_experience?: Maybe<Scalars['String']>;
  specialty_other?: Maybe<Scalars['String']>;
  specialty_other_years_experience?: Maybe<Scalars['String']>;
  specialty_vascular_surgery_years_experience?: Maybe<Scalars['String']>;
  years_as_first_operator?: Maybe<Scalars['String']>;
  years_as_second_operator?: Maybe<Scalars['String']>;
  secondary_email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  logbook?: Maybe<Scalars['String']>;
};

export type CourseProgress = {
  __typename?: 'CourseProgress';
  completion_percentage: Scalars['Int'];
  completion_status: Scalars['String'];
  units?: Maybe<Array<UnitProgress>>;
};

export type UnitProgress = {
  __typename?: 'UnitProgress';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  completion_status: Scalars['String'];
  completed_on: Scalars['String'];
  completed_on_timestamp: Scalars['String'];
  score?: Maybe<Scalars['String']>;
  total_time: Scalars['String'];
  total_time_seconds: Scalars['Int'];
};

export type TalentCourse = {
  __typename?: 'TalentCourse';
  id: Scalars['Int'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  big_avatar?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  completion_status?: Maybe<Scalars['String']>;
  completion_percentage?: Maybe<Scalars['Int']>;
  goto_url?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  units?: Maybe<Array<Maybe<TalentUnit>>>;
  progress: CourseProgress;
  description?: Maybe<Scalars['String']>;
};


export type TalentCourseGoto_UrlArgs = {
  emailAddress: Scalars['String'];
  talentBranchName: Scalars['String'];
};

export type TalentCourseListing = {
  __typename?: 'TalentCourseListing';
  id: Scalars['Int'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  big_avatar?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  learningSpaces: Array<TalentBranch>;
};

export type TalentBranch = {
  __typename?: 'TalentBranch';
  id: Scalars['Int'];
  code: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  image: Scalars['String'];
};

export type TalentUnit = {
  __typename?: 'TalentUnit';
  id: Scalars['String'];
  type: Scalars['String'];
  name: Scalars['String'];
};

export type NewsArticle = {
  __typename?: 'NewsArticle';
  title: Scalars['String'];
  date: Scalars['String'];
  link: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type YoutubeVideo = {
  __typename?: 'YoutubeVideo';
  id: Scalars['String'];
  title: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type Voucher = {
  __typename?: 'Voucher';
  code: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ActivitySortField {
  ActivityStart = 'activityStart',
  CourseName = 'courseName',
  ExerciseName = 'exerciseName',
  Score = 'score'
}

export type ActivitySearchParams = {
  sortField?: Maybe<ActivitySortField>;
  sortOrder?: Maybe<SortOrder>;
  searchText?: Maybe<Scalars['String']>;
};

export enum VistCourseSortField {
  Name = 'name',
  Date = 'date'
}

export type VistCourseSearchParams = {
  sortField?: Maybe<VistCourseSortField>;
  sortOrder?: Maybe<SortOrder>;
  searchText?: Maybe<Scalars['String']>;
};

export type ResetResult = {
  __typename?: 'ResetResult';
  done: Scalars['Boolean'];
};

export type UserInput = {
  keycloak_id: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hospital_university?: Maybe<Scalars['String']>;
  number_of_real_procedures_performed?: Maybe<Scalars['String']>;
  number_of_simulated_procedures_performed?: Maybe<Scalars['String']>;
  specialty_interventional_cardiology_years_experience?: Maybe<Scalars['String']>;
  specialty_interventional_neuroradiology_years_experience?: Maybe<Scalars['String']>;
  specialty_interventional_radiology_years_experience?: Maybe<Scalars['String']>;
  specialty_other?: Maybe<Scalars['String']>;
  specialty_other_years_experience?: Maybe<Scalars['String']>;
  specialty_vascular_surgery_years_experience?: Maybe<Scalars['String']>;
  years_as_first_operator?: Maybe<Scalars['String']>;
  years_as_second_operator?: Maybe<Scalars['String']>;
  secondary_email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  logbook?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  branches: Array<Scalars['String']>;
};

export type SaveCustomerInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Scalars['String']>>;
  admins: Array<Scalars['String']>;
};

export type CreateCustomerGroup = {
  customer_id: Scalars['Int'];
  name: Scalars['String'];
  admin: Scalars['String'];
  description: Scalars['String'];
};

export type SaveCustomerGroup = {
  customer_group_id: Scalars['Int'];
  name: Scalars['String'];
  adminEmail: Scalars['String'];
  description: Scalars['String'];
};

export type VoucherInput = {
  customerGroupId: Scalars['Int'];
  talentCourseId: Scalars['Int'];
  maxUses: Scalars['Int'];
  endDate: Scalars['DateTime'];
};

export type RefetchPayload = {
  __typename?: 'RefetchPayload';
  refetch: Query;
};

export type InviteResponse = {
  __typename?: 'InviteResponse';
  groupName: Scalars['String'];
};

export type VoucherResponse = {
  __typename?: 'VoucherResponse';
  course: Scalars['String'];
  groupName: Scalars['String'];
  learningSpaceNames: Array<Scalars['String']>;
};

export enum ResourceAccessVoucherType {
  EventCloudCase = 'EVENT_CLOUD_CASE',
  SingleUserCloudCase = 'SINGLE_USER_CLOUD_CASE'
}

export enum CloudModulesEventUserRole {
  Organizer = 'ORGANIZER',
  Participant = 'PARTICIPANT'
}

export type ResourceAccessVoucher = {
  id: Scalars['Int'];
  type: ResourceAccessVoucherType;
  code: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  customer: Customer;
  learningSpace: TalentBranch;
  creator?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedByUserId?: Maybe<Scalars['String']>;
  used: Scalars['Int'];
  maxUses?: Maybe<Scalars['Int']>;
  users: Array<User>;
};

export type EventResourceAccessVoucher = ResourceAccessVoucher & {
  __typename?: 'EventResourceAccessVoucher';
  id: Scalars['Int'];
  type: ResourceAccessVoucherType;
  code: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  customer: Customer;
  learningSpace: TalentBranch;
  creator: User;
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedByUserId?: Maybe<Scalars['String']>;
  used: Scalars['Int'];
  maxUses?: Maybe<Scalars['Int']>;
  users: Array<User>;
  modules: Array<CloudModule>;
  eventAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  organizers: Array<User>;
  vm?: Maybe<CloudCaseVirtualMachine>;
  durationInSeconds: Scalars['Int'];
};

export type CloudModulesResourceAccessVoucher = ResourceAccessVoucher & {
  __typename?: 'CloudModulesResourceAccessVoucher';
  id: Scalars['Int'];
  type: ResourceAccessVoucherType;
  code: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  customer: Customer;
  learningSpace: TalentBranch;
  creator: User;
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedByUserId?: Maybe<Scalars['String']>;
  used: Scalars['Int'];
  maxUses?: Maybe<Scalars['Int']>;
  users: Array<User>;
  modules: Array<CloudModule>;
  maxDurationInSeconds: Scalars['Int'];
  userQuota: Array<UserQuotum>;
};

export type EditEventResourceAccessVoucherInput = {
  voucherId: Scalars['Int'];
  name: Scalars['String'];
  eventAt: Scalars['DateTime'];
  durationInSeconds: Scalars['Int'];
  description: Scalars['String'];
};

export type CreateEventResourceAccessVoucherInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  customerId: Scalars['Int'];
  learningSpaceId: Scalars['Int'];
  modules: Array<CloudModuleInput>;
  eventAt: Scalars['DateTime'];
  durationInSeconds: Scalars['Int'];
  maxUses: Scalars['Int'];
};

export type CreateCloudModulesResourceAccessVoucherInput = {
  validTo: Scalars['DateTime'];
  customerId: Scalars['Int'];
  learningSpaceId: Scalars['Int'];
  maxUses: Scalars['Int'];
  maxDurationInSeconds: Scalars['Int'];
  modules: Array<CloudModuleInput>;
};

export type EditCloudModulesResourceAccessVoucherInput = {
  voucherId: Scalars['Int'];
  validTo: Scalars['DateTime'];
  maxUses?: Maybe<Scalars['Int']>;
  modules: Array<CloudModuleInput>;
};

export type SingleUserCloudModulesResourceAccessVoucher = {
  __typename?: 'SingleUserCloudModulesResourceAccessVoucher';
  voucherId: Scalars['Int'];
  customer: Customer;
  learningSpace: TalentBranch;
  modules: Array<CloudModule>;
  durationLeftInSeconds: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  validTo: Scalars['DateTime'];
  expired: Scalars['Boolean'];
};

export type CloudModule = {
  __typename?: 'CloudModule';
  moduleId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  cases: Array<CloudCase>;
};

export type CloudModuleInput = {
  moduleId: Scalars['String'];
  caseIds: Array<Scalars['String']>;
};

export type CloudCase = {
  __typename?: 'CloudCase';
  caseId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type UserQuotum = {
  __typename?: 'UserQuotum';
  user: User;
  durationLeft: Scalars['Int'];
};

export type CustomerCloudModuleTimeBalance = {
  __typename?: 'CustomerCloudModuleTimeBalance';
  consumedTimeInMs: Scalars['Int'];
  reservedTimeInMs: Scalars['Int'];
  spendableTimeInMs: Scalars['Int'];
};

export type QrLoginResponse = {
  __typename?: 'QRLoginResponse';
  status: Scalars['String'];
};

export enum SystemStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN'
}

export enum SystemReleaseStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Outdated = 'OUTDATED',
  Unknown = 'UNKNOWN',
  Updated = 'UPDATED'
}

export enum SoftwareReleaseStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Outdated = 'OUTDATED',
  Released = 'RELEASED'
}

export type SoftwareRelease = {
  __typename?: 'SoftwareRelease';
  id: Scalars['Int'];
  releaseId: Scalars['String'];
  releaseDate: Scalars['DateTime'];
  requiresLicense: Scalars['Boolean'];
  version?: Maybe<Scalars['String']>;
  productBrandName?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  status?: Maybe<SoftwareReleaseStatus>;
  licensedModuleName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['JSONObject']>;
};

export type SoftwareReleaseInput = {
  releaseId: Scalars['String'];
  releaseDate?: Maybe<Scalars['DateTime']>;
  requiresLicense?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  productBrandName?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  status?: Maybe<SoftwareReleaseStatus>;
  licensedModuleName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['JSONObject']>;
};

export type System = {
  __typename?: 'System';
  id: Scalars['Int'];
  account?: Maybe<Scalars['JSONObject']>;
  adminVersion?: Maybe<Scalars['String']>;
  computer?: Maybe<Scalars['JSONObject']>;
  externalHardware?: Maybe<Scalars['JSONObject']>;
  haptic?: Maybe<Scalars['String']>;
  initialInstallDate?: Maybe<Scalars['DateTime']>;
  installationCreatedAt?: Maybe<Scalars['DateTime']>;
  masterSerialNumber?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status?: Maybe<SystemStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemRelease = {
  __typename?: 'SystemRelease';
  id: Scalars['Int'];
  systemId: Scalars['Int'];
  softwareId: Scalars['Int'];
  status: SystemReleaseStatus;
};

export type SystemReleaseInput = {
  systemId: Scalars['Int'];
  softwareId: Scalars['Int'];
  status: SystemReleaseStatus;
};

export type SystemInput = {
  account?: Maybe<Scalars['JSONObject']>;
  adminVersion?: Maybe<Scalars['String']>;
  computer?: Maybe<Scalars['JSONObject']>;
  externalHardware?: Maybe<Scalars['JSONObject']>;
  haptic?: Maybe<Scalars['String']>;
  initialInstallDate?: Maybe<Scalars['DateTime']>;
  installationCreatedAt?: Maybe<Scalars['DateTime']>;
  masterSerialNumber?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status?: Maybe<SystemStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemSoftware = {
  __typename?: 'SystemSoftware';
  systemReleaseId: Scalars['Int'];
  systemReleaseStatus?: Maybe<SystemReleaseStatus>;
  systemId: Scalars['Int'];
  softwareReleaseId: Scalars['Int'];
  systemAccount?: Maybe<Scalars['JSONObject']>;
  systemAdminVersion?: Maybe<Scalars['String']>;
  systemComputer?: Maybe<Scalars['JSONObject']>;
  systemExternalHardware?: Maybe<Scalars['JSONObject']>;
  systemHaptic?: Maybe<Scalars['String']>;
  systemInitialInstallDate?: Maybe<Scalars['DateTime']>;
  systemInstallationCreatedAt?: Maybe<Scalars['DateTime']>;
  systemMasterSerialNumber?: Maybe<Scalars['String']>;
  systemNetwork?: Maybe<Scalars['String']>;
  systemSerialNumber?: Maybe<Scalars['String']>;
  systemStatus?: Maybe<SystemStatus>;
  systemStatusUpdatedAt?: Maybe<Scalars['DateTime']>;
  softwareReleaseBranch?: Maybe<Scalars['String']>;
  softwareReleaseLicensedModuleName?: Maybe<Scalars['String']>;
  softwareReleaseLinks?: Maybe<Scalars['JSONObject']>;
  softwareReleaseProductBrandName?: Maybe<Scalars['String']>;
  softwareReleaseReleaseDate?: Maybe<Scalars['DateTime']>;
  softwareReleaseReleaseId: Scalars['String'];
  softwareReleaseRequiresLicense: Scalars['Boolean'];
  softwareReleaseStatus?: Maybe<SoftwareReleaseStatus>;
  softwareReleaseType?: Maybe<Scalars['String']>;
  softwareReleaseVersion?: Maybe<Scalars['String']>;
};

export type SystemSoftwaresReleases = {
  __typename?: 'SystemSoftwaresReleases';
  id: Scalars['Int'];
  account?: Maybe<Scalars['JSONObject']>;
  adminVersion?: Maybe<Scalars['String']>;
  computer?: Maybe<Scalars['JSONObject']>;
  externalHardware?: Maybe<Scalars['JSONObject']>;
  haptic?: Maybe<Scalars['String']>;
  initialInstallDate?: Maybe<Scalars['DateTime']>;
  installationCreatedAt?: Maybe<Scalars['DateTime']>;
  masterSerialNumber?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<SystemStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  softwaresReleases: Array<Maybe<SoftwareRelease>>;
};

export type ProcedureActivityMeta = {
  __typename?: 'ProcedureActivityMeta';
  totalCount: Scalars['Int'];
  activities: Array<ProcedureActivity>;
};

export type ProcedureActivitySearchParams = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortField?: Maybe<ProcedureActivitySortField>;
  searchText?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  hospital?: Maybe<Scalars['String']>;
  attending?: Maybe<Scalars['String']>;
  participantName?: Maybe<Scalars['String']>;
  interventionType?: Maybe<Scalars['String']>;
  organSystem?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  modalityPrimary?: Maybe<Scalars['String']>;
  complications?: Maybe<Complication>;
};

export enum ProcedureActivitySortField {
  Hospital = 'hospital',
  OptionalIdentifier = 'optionalIdentifier',
  Attending = 'attending',
  Participants = 'participants',
  DateOfProcedure = 'dateOfProcedure',
  ProcedureName = 'procedure_name'
}

export type ParticipantsInput = {
  userId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<ParticipantRole>;
  level?: Maybe<ParticipantLevel>;
};

export type ProcedureCodeInput = {
  code: Scalars['String'];
};

export type ProcedureActivityInput = {
  id: Scalars['String'];
  groupId?: Maybe<Scalars['Int']>;
  dateOfProcedure?: Maybe<Scalars['Date']>;
  optionalIdentifier?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  ageCategory?: Maybe<AgeCategory>;
  sex?: Maybe<PatientSex>;
  setting?: Maybe<ProcedureSetting>;
  attendingId?: Maybe<Scalars['UUID']>;
  participants?: Maybe<Array<ParticipantsInput>>;
  procedure: ProcedureCodeInput;
  procedureComments?: Maybe<Scalars['String']>;
  procedureIndication?: Maybe<Scalars['String']>;
  modalityPrimary?: Maybe<PrimaryModality>;
  modalitySecondary?: Maybe<PrimaryModality>;
  onCall?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  successComments?: Maybe<Scalars['String']>;
  doseTime?: Maybe<Scalars['Float']>;
  doseCtdi?: Maybe<Scalars['Float']>;
  doseDlp?: Maybe<Scalars['Float']>;
  doseSkin?: Maybe<Scalars['Float']>;
  doseDap?: Maybe<Scalars['Float']>;
  dictated?: Maybe<Scalars['Boolean']>;
  complications?: Maybe<Complication>;
  complicationsComments?: Maybe<Scalars['String']>;
  ebl?: Maybe<Ebl>;
  sedation?: Maybe<Sedation>;
  platelet?: Maybe<Platelet>;
  inr?: Maybe<Inr>;
  inrReversal?: Maybe<InrReversal>;
  closureDevice?: Maybe<Scalars['Boolean']>;
  closureDeviceComments?: Maybe<Scalars['String']>;
  imageFollowup?: Maybe<Scalars['Date']>;
  imageFollowupNotes?: Maybe<Scalars['String']>;
  consultFollowup?: Maybe<Scalars['Date']>;
  consultFollowupNotes?: Maybe<Scalars['String']>;
};

export type IrLogFormData = {
  __typename?: 'IRLogFormData';
  interventionTypes: Array<InterventionType>;
  attending: Array<Attending>;
  hospitals: Array<Hospital>;
};

export type InterventionType = {
  __typename?: 'InterventionType';
  name: Scalars['String'];
  organSystems: Array<OrganSystem>;
};

export type OrganSystem = {
  __typename?: 'OrganSystem';
  name: Scalars['String'];
  categories: Array<ProcedureCategory>;
};

export type ProcedureCategory = {
  __typename?: 'ProcedureCategory';
  name: Scalars['String'];
  subCategories: Array<ProcedureSubCategory>;
};

export type ProcedureSubCategory = {
  __typename?: 'ProcedureSubCategory';
  subCategory: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
};

export type ProcedureActivity = {
  __typename?: 'ProcedureActivity';
  id: Scalars['ID'];
  groupId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
  type: ActivityType;
  dateOfProcedure?: Maybe<Scalars['Date']>;
  optionalIdentifier?: Maybe<Scalars['String']>;
  hospital?: Maybe<Scalars['String']>;
  ageCategory?: Maybe<AgeCategory>;
  sex?: Maybe<PatientSex>;
  setting?: Maybe<ProcedureSetting>;
  attending?: Maybe<Attending>;
  participants?: Maybe<Array<Participant>>;
  procedure?: Maybe<Procedure>;
  procedureComments?: Maybe<Scalars['String']>;
  procedureIndication?: Maybe<Scalars['String']>;
  modalityPrimary?: Maybe<Scalars['String']>;
  modalitySecondary?: Maybe<Scalars['String']>;
  onCall?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  successComments?: Maybe<Scalars['String']>;
  doseCtdi?: Maybe<Scalars['Float']>;
  doseDlp?: Maybe<Scalars['Float']>;
  doseSkin?: Maybe<Scalars['Float']>;
  doseDap?: Maybe<Scalars['Float']>;
  doseTime?: Maybe<Scalars['Float']>;
  dictated?: Maybe<Scalars['Boolean']>;
  complications?: Maybe<Complication>;
  complicationsComments?: Maybe<Scalars['String']>;
  ebl?: Maybe<Ebl>;
  sedation?: Maybe<Sedation>;
  platelet?: Maybe<Platelet>;
  inr?: Maybe<Inr>;
  inrReversal?: Maybe<InrReversal>;
  closureDevice?: Maybe<Scalars['Boolean']>;
  closureDeviceComments?: Maybe<Scalars['String']>;
  imageFollowup?: Maybe<Scalars['Date']>;
  imageFollowupNotes?: Maybe<Scalars['String']>;
  consultFollowup?: Maybe<Scalars['Date']>;
  consultFollowupNotes?: Maybe<Scalars['String']>;
  AE_B_Causality?: Maybe<Ae_B_Category>;
  AE_B_Risk_Modifier?: Maybe<Ae_B_Category>;
  AE_B_Preventability?: Maybe<Ae_B_Category>;
  AE_B_Management?: Maybe<Ae_B_Category>;
  AE_B_comments?: Maybe<Scalars['String']>;
  AE_B_Reviewed?: Maybe<Scalars['Boolean']>;
  AE_B_Reviewers?: Maybe<Array<ProcedureActivityReview>>;
  diagnosticSample?: Maybe<DiagnosticSample>;
  ACGME_Cat?: Maybe<Acgme_Cat>;
};

export type Procedure = {
  __typename?: 'Procedure';
  name: Scalars['String'];
  code: Scalars['String'];
  interventionType: Scalars['String'];
  organSystem: Scalars['String'];
  category: Scalars['String'];
  subCategory: Scalars['String'];
};

export type Participant = {
  __typename?: 'Participant';
  user?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<ParticipantRole>;
  level?: Maybe<ParticipantLevel>;
};

export enum ParticipantRole {
  Operator = 'Operator',
  Assistant = 'Assistant',
  Observer = 'Observer',
  Circulator = 'Circulator',
  Scrub = 'Scrub'
}

export enum ParticipantLevel {
  D = 'D',
  Ir1 = 'IR1',
  Ir2 = 'IR2',
  Ir3 = 'IR3',
  Ir4 = 'IR4',
  Ir5 = 'IR5',
  Ir6 = 'IR6',
  Ir7 = 'IR7',
  Np = 'NP',
  Pa = 'PA',
  Pgy1 = 'PGY1',
  PreRadiology = 'PreRadiology',
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4',
  Trainee = 'Trainee'
}

export type ProcedureActivityReview = {
  __typename?: 'ProcedureActivityReview';
  users: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export enum AgeCategory {
  Unknown = 'Unknown',
  Pediatric = 'Pediatric',
  Adult = 'Adult',
  Geriatric = 'Geriatric'
}

export enum PatientSex {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export enum ProcedureSetting {
  Unknown = 'Unknown',
  Ambulatory = 'Ambulatory',
  Inpatient = 'Inpatient',
  Outpatient = 'Outpatient',
  Clinic = 'Clinic'
}

export enum PrimaryModality {
  NoImaging = 'NoImaging',
  Ct = 'CT',
  Fluoroscopy = 'Fluoroscopy',
  Mri = 'MRI',
  Ultrasound = 'Ultrasound'
}

export enum Complication {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  Mild = 'MILD',
  Moderate = 'MODERATE',
  Severe = 'SEVERE',
  None = 'NONE'
}

export enum Ae_B_Category {
  None = 'None',
  Category1 = 'CATEGORY1',
  Category2 = 'CATEGORY2',
  Category3 = 'CATEGORY3'
}

export enum Ebl {
  Ml50_100 = 'ml50_100',
  Lt50mL = 'lt50mL',
  Gt100mLNotRequireingTransfusion = 'gt100mLNotRequireingTransfusion',
  Gt100mLRequireingTransfusion = 'gt100mLRequireingTransfusion',
  Minimal = 'Minimal',
  None = 'None'
}

export enum Sedation {
  General = 'General',
  Local = 'Local',
  Mac = 'MAC',
  ModerateConscious = 'Moderate_Conscious',
  None = 'None'
}

export enum Platelet {
  Lt50 = 'lt50',
  P51_75 = 'p51_75',
  P76_100 = 'p76_100',
  Gt100 = 'gt100',
  None = 'None'
}

export enum Inr {
  Lt1_3 = 'lt1_3',
  I1_3to1_4 = 'i1_3to1_4',
  I1_5to1_7 = 'i1_5to1_7',
  I1_8to2_0 = 'i1_8to2_0',
  Gt2_0 = 'gt2_0',
  None = 'None'
}

export enum InrReversal {
  None = 'None',
  Ffp = 'FFP',
  Cryo = 'Cryo',
  VitaminK = 'VitaminK'
}

export enum DiagnosticSample {
  No = 'No',
  NotApplicable = 'Not_Applicable',
  Pending = 'Pending',
  Yes = 'Yes'
}

export enum Acgme_Cat {
  AorticStentGrafting = 'Aortic_Stent_Grafting',
  ArterialPtaOrStent = 'Arterial_PTA_or_Stent',
  DialysisAccessIntervention = 'Dialysis_Access_Intervention',
  Embolization = 'Embolization',
  NewOutpatientClinicEvaluation = 'New_Outpatient_Clinic_Evaluation',
  Other = 'Other',
  PrimaryGiIntervention = 'Primary_GI_Intervention',
  PrimaryNephrostomy = 'Primary_Nephrostomy',
  TipsOrTipsRevision = 'TIPS_or_TIPS_Revision',
  ThrombolysisOrThrombectomy = 'Thrombolysis_or_Thrombectomy',
  TumorAblation = 'Tumor_Ablation',
  VenousIntervention = 'Venous_Intervention',
  VenousPort = 'Venous_Port'
}

export type Hospital = {
  __typename?: 'Hospital';
  id: Scalars['UUID'];
  name: Scalars['String'];
  customerId: Scalars['Int'];
  customerName: Scalars['String'];
};

export type Attending = {
  __typename?: 'Attending';
  id?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  customerName: Scalars['String'];
};


















































export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  JSONObject_: ResolverTypeWrapper<Scalars['JSONObject_']>;
  DateTime_: ResolverTypeWrapper<Scalars['DateTime_']>;
  MutationResponse: ResolverTypeWrapper<MutationResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Query: ResolverTypeWrapper<{}>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  VistSoftware: ResolverTypeWrapper<VistSoftware>;
  VistRelease: ResolverTypeWrapper<VistRelease>;
  VistReleaseResources: ResolverTypeWrapper<VistReleaseResources>;
  VistReleaseResource: ResolverTypeWrapper<VistReleaseResource>;
  VistReleaseVersion: ResolverTypeWrapper<VistReleaseVersion>;
  Mutation: ResolverTypeWrapper<{}>;
  VistSystemComputer: ResolverTypeWrapper<VistSystemComputer>;
  VistSystemSoftwareRelease: ResolverTypeWrapper<VistSystemSoftwareRelease>;
  VistReleaseOp: ResolverTypeWrapper<VistReleaseOp>;
  VistReleaseOpDetailedStateInput: VistReleaseOpDetailedStateInput;
  OperationDetailedStateInput: OperationDetailedStateInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  VistSystemSoftwareReleaseState: ResolverTypeWrapper<VistSystemSoftwareReleaseState>;
  VistReleaseIdentifierInput: VistReleaseIdentifierInput;
  VistSystemSoftwareReleaseOperations: ResolverTypeWrapper<VistSystemSoftwareReleaseOperations>;
  OperationDetailedState: ResolverTypeWrapper<OperationDetailedState>;
  VistActivityInput: VistActivityInput;
  QtImage: QtImage;
  QtRecording: QtRecording;
  QtInstallationInput: QtInstallationInput;
  QtCourseInput: QtCourseInput;
  QtCourseLicense: QtCourseLicense;
  QtSimulationLicense: QtSimulationLicense;
  QtSimulationInput: QtSimulationInput;
  QtMetricInput: QtMetricInput;
  QtBenchmarkInput: QtBenchmarkInput;
  QtBenchmarkValueInput: QtBenchmarkValueInput;
  QtResultInput: QtResultInput;
  QtResultValueInput: QtResultValueInput;
  CloudCaseVMStatus: CloudCaseVmStatus;
  GroupRole: GroupRole;
  CloudCaseVirtualMachine: ResolverTypeWrapper<CloudCaseVirtualMachine>;
  CloudCaseVirtualMachineStateChange: ResolverTypeWrapper<CloudCaseVirtualMachineStateChange>;
  Customer: ResolverTypeWrapper<Customer>;
  CustomerLearningSpaceAccess: ResolverTypeWrapper<CustomerLearningSpaceAccess>;
  CustomerCourseAccess: ResolverTypeWrapper<CustomerCourseAccess>;
  CustomerGroup: ResolverTypeWrapper<CustomerGroup>;
  CustomerGroupCourseAccess: ResolverTypeWrapper<CustomerGroupCourseAccess>;
  InvitationCode: ResolverTypeWrapper<InvitationCode>;
  VistCourse: ResolverTypeWrapper<VistCourse>;
  VistCourseLicense: ResolverTypeWrapper<VistCourseLicense>;
  VistSimulationLicense: ResolverTypeWrapper<VistSimulationLicense>;
  VistSystem: ResolverTypeWrapper<VistSystem>;
  SystemsStats: ResolverTypeWrapper<SystemsStats>;
  Simulation: ResolverTypeWrapper<Simulation>;
  ActivityType: ActivityType;
  Activity: ResolversTypes['VistActivityItem'];
  VistActivityItem: ResolverTypeWrapper<VistActivityItem>;
  AllVistActivityItems: ResolverTypeWrapper<AllVistActivityItems>;
  Image: ResolverTypeWrapper<Image>;
  Recording: ResolverTypeWrapper<Recording>;
  Group: ResolverTypeWrapper<Group>;
  Metric: ResolverTypeWrapper<Metric>;
  Benchmark: ResolverTypeWrapper<Benchmark>;
  BenchmarkValue: ResolverTypeWrapper<BenchmarkValue>;
  Result: ResolverTypeWrapper<Result>;
  ResultFile: ResolverTypeWrapper<ResultFile>;
  ResultValue: ResolverTypeWrapper<ResultValue>;
  User: ResolverTypeWrapper<User>;
  CourseProgress: ResolverTypeWrapper<CourseProgress>;
  UnitProgress: ResolverTypeWrapper<UnitProgress>;
  TalentCourse: ResolverTypeWrapper<TalentCourse>;
  TalentCourseListing: ResolverTypeWrapper<TalentCourseListing>;
  TalentBranch: ResolverTypeWrapper<TalentBranch>;
  TalentUnit: ResolverTypeWrapper<TalentUnit>;
  NewsArticle: ResolverTypeWrapper<NewsArticle>;
  YoutubeVideo: ResolverTypeWrapper<YoutubeVideo>;
  Voucher: ResolverTypeWrapper<Voucher>;
  SortOrder: SortOrder;
  ActivitySortField: ActivitySortField;
  ActivitySearchParams: ActivitySearchParams;
  VISTCourseSortField: VistCourseSortField;
  VISTCourseSearchParams: VistCourseSearchParams;
  ResetResult: ResolverTypeWrapper<ResetResult>;
  UserInput: UserInput;
  CustomerInput: CustomerInput;
  SaveCustomerInput: SaveCustomerInput;
  CreateCustomerGroup: CreateCustomerGroup;
  SaveCustomerGroup: SaveCustomerGroup;
  VoucherInput: VoucherInput;
  RefetchPayload: ResolverTypeWrapper<RefetchPayload>;
  InviteResponse: ResolverTypeWrapper<InviteResponse>;
  VoucherResponse: ResolverTypeWrapper<VoucherResponse>;
  ResourceAccessVoucherType: ResourceAccessVoucherType;
  CloudModulesEventUserRole: CloudModulesEventUserRole;
  ResourceAccessVoucher: ResolversTypes['EventResourceAccessVoucher'] | ResolversTypes['CloudModulesResourceAccessVoucher'];
  EventResourceAccessVoucher: ResolverTypeWrapper<EventResourceAccessVoucher>;
  CloudModulesResourceAccessVoucher: ResolverTypeWrapper<CloudModulesResourceAccessVoucher>;
  EditEventResourceAccessVoucherInput: EditEventResourceAccessVoucherInput;
  CreateEventResourceAccessVoucherInput: CreateEventResourceAccessVoucherInput;
  CreateCloudModulesResourceAccessVoucherInput: CreateCloudModulesResourceAccessVoucherInput;
  EditCloudModulesResourceAccessVoucherInput: EditCloudModulesResourceAccessVoucherInput;
  SingleUserCloudModulesResourceAccessVoucher: ResolverTypeWrapper<SingleUserCloudModulesResourceAccessVoucher>;
  CloudModule: ResolverTypeWrapper<CloudModule>;
  CloudModuleInput: CloudModuleInput;
  CloudCase: ResolverTypeWrapper<CloudCase>;
  UserQuotum: ResolverTypeWrapper<UserQuotum>;
  CustomerCloudModuleTimeBalance: ResolverTypeWrapper<CustomerCloudModuleTimeBalance>;
  QRLoginResponse: ResolverTypeWrapper<QrLoginResponse>;
  SystemStatus: SystemStatus;
  SystemReleaseStatus: SystemReleaseStatus;
  SoftwareReleaseStatus: SoftwareReleaseStatus;
  SoftwareRelease: ResolverTypeWrapper<SoftwareRelease>;
  SoftwareReleaseInput: SoftwareReleaseInput;
  System: ResolverTypeWrapper<System>;
  SystemRelease: ResolverTypeWrapper<SystemRelease>;
  SystemReleaseInput: SystemReleaseInput;
  SystemInput: SystemInput;
  SystemSoftware: ResolverTypeWrapper<SystemSoftware>;
  SystemSoftwaresReleases: ResolverTypeWrapper<SystemSoftwaresReleases>;
  ProcedureActivityMeta: ResolverTypeWrapper<ProcedureActivityMeta>;
  ProcedureActivitySearchParams: ProcedureActivitySearchParams;
  ProcedureActivitySortField: ProcedureActivitySortField;
  ParticipantsInput: ParticipantsInput;
  ProcedureCodeInput: ProcedureCodeInput;
  ProcedureActivityInput: ProcedureActivityInput;
  IRLogFormData: ResolverTypeWrapper<IrLogFormData>;
  InterventionType: ResolverTypeWrapper<InterventionType>;
  OrganSystem: ResolverTypeWrapper<OrganSystem>;
  ProcedureCategory: ResolverTypeWrapper<ProcedureCategory>;
  ProcedureSubCategory: ResolverTypeWrapper<ProcedureSubCategory>;
  ProcedureActivity: ResolverTypeWrapper<ProcedureActivity>;
  Procedure: ResolverTypeWrapper<Procedure>;
  Participant: ResolverTypeWrapper<Participant>;
  ParticipantRole: ParticipantRole;
  ParticipantLevel: ParticipantLevel;
  ProcedureActivityReview: ResolverTypeWrapper<ProcedureActivityReview>;
  AgeCategory: AgeCategory;
  PatientSex: PatientSex;
  ProcedureSetting: ProcedureSetting;
  PrimaryModality: PrimaryModality;
  Complication: Complication;
  AE_B_Category: Ae_B_Category;
  Ebl: Ebl;
  Sedation: Sedation;
  Platelet: Platelet;
  Inr: Inr;
  InrReversal: InrReversal;
  DiagnosticSample: DiagnosticSample;
  ACGME_Cat: Acgme_Cat;
  Hospital: ResolverTypeWrapper<Hospital>;
  Attending: ResolverTypeWrapper<Attending>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  UtcOffset: ResolverTypeWrapper<Scalars['UtcOffset']>;
  Duration: ResolverTypeWrapper<Scalars['Duration']>;
  ISO8601Duration: ResolverTypeWrapper<Scalars['ISO8601Duration']>;
  LocalDate: ResolverTypeWrapper<Scalars['LocalDate']>;
  LocalTime: ResolverTypeWrapper<Scalars['LocalTime']>;
  LocalEndTime: ResolverTypeWrapper<Scalars['LocalEndTime']>;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>;
  NegativeFloat: ResolverTypeWrapper<Scalars['NegativeFloat']>;
  NegativeInt: ResolverTypeWrapper<Scalars['NegativeInt']>;
  NonEmptyString: ResolverTypeWrapper<Scalars['NonEmptyString']>;
  NonNegativeFloat: ResolverTypeWrapper<Scalars['NonNegativeFloat']>;
  NonNegativeInt: ResolverTypeWrapper<Scalars['NonNegativeInt']>;
  NonPositiveFloat: ResolverTypeWrapper<Scalars['NonPositiveFloat']>;
  NonPositiveInt: ResolverTypeWrapper<Scalars['NonPositiveInt']>;
  PhoneNumber: ResolverTypeWrapper<Scalars['PhoneNumber']>;
  PositiveFloat: ResolverTypeWrapper<Scalars['PositiveFloat']>;
  PositiveInt: ResolverTypeWrapper<Scalars['PositiveInt']>;
  PostalCode: ResolverTypeWrapper<Scalars['PostalCode']>;
  UnsignedFloat: ResolverTypeWrapper<Scalars['UnsignedFloat']>;
  UnsignedInt: ResolverTypeWrapper<Scalars['UnsignedInt']>;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  Byte: ResolverTypeWrapper<Scalars['Byte']>;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  SafeInt: ResolverTypeWrapper<Scalars['SafeInt']>;
  UUID: ResolverTypeWrapper<Scalars['UUID']>;
  GUID: ResolverTypeWrapper<Scalars['GUID']>;
  Hexadecimal: ResolverTypeWrapper<Scalars['Hexadecimal']>;
  HexColorCode: ResolverTypeWrapper<Scalars['HexColorCode']>;
  HSL: ResolverTypeWrapper<Scalars['HSL']>;
  HSLA: ResolverTypeWrapper<Scalars['HSLA']>;
  IPv4: ResolverTypeWrapper<Scalars['IPv4']>;
  IPv6: ResolverTypeWrapper<Scalars['IPv6']>;
  ISBN: ResolverTypeWrapper<Scalars['ISBN']>;
  MAC: ResolverTypeWrapper<Scalars['MAC']>;
  Port: ResolverTypeWrapper<Scalars['Port']>;
  RGB: ResolverTypeWrapper<Scalars['RGB']>;
  RGBA: ResolverTypeWrapper<Scalars['RGBA']>;
  USCurrency: ResolverTypeWrapper<Scalars['USCurrency']>;
  Currency: ResolverTypeWrapper<Scalars['Currency']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  IBAN: ResolverTypeWrapper<Scalars['IBAN']>;
  ObjectID: ResolverTypeWrapper<Scalars['ObjectID']>;
  Void: ResolverTypeWrapper<Scalars['Void']>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  JSONObject_: Scalars['JSONObject_'];
  DateTime_: Scalars['DateTime_'];
  MutationResponse: MutationResponse;
  Int: Scalars['Int'];
  Boolean: Scalars['Boolean'];
  String: Scalars['String'];
  Query: {};
  ID: Scalars['ID'];
  VistSoftware: VistSoftware;
  VistRelease: VistRelease;
  VistReleaseResources: VistReleaseResources;
  VistReleaseResource: VistReleaseResource;
  VistReleaseVersion: VistReleaseVersion;
  Mutation: {};
  VistSystemComputer: VistSystemComputer;
  VistSystemSoftwareRelease: VistSystemSoftwareRelease;
  VistReleaseOp: VistReleaseOp;
  VistReleaseOpDetailedStateInput: VistReleaseOpDetailedStateInput;
  OperationDetailedStateInput: OperationDetailedStateInput;
  Float: Scalars['Float'];
  VistSystemSoftwareReleaseState: VistSystemSoftwareReleaseState;
  VistReleaseIdentifierInput: VistReleaseIdentifierInput;
  VistSystemSoftwareReleaseOperations: VistSystemSoftwareReleaseOperations;
  OperationDetailedState: OperationDetailedState;
  VistActivityInput: VistActivityInput;
  QtImage: QtImage;
  QtRecording: QtRecording;
  QtInstallationInput: QtInstallationInput;
  QtCourseInput: QtCourseInput;
  QtCourseLicense: QtCourseLicense;
  QtSimulationLicense: QtSimulationLicense;
  QtSimulationInput: QtSimulationInput;
  QtMetricInput: QtMetricInput;
  QtBenchmarkInput: QtBenchmarkInput;
  QtBenchmarkValueInput: QtBenchmarkValueInput;
  QtResultInput: QtResultInput;
  QtResultValueInput: QtResultValueInput;
  CloudCaseVirtualMachine: CloudCaseVirtualMachine;
  CloudCaseVirtualMachineStateChange: CloudCaseVirtualMachineStateChange;
  Customer: Customer;
  CustomerLearningSpaceAccess: CustomerLearningSpaceAccess;
  CustomerCourseAccess: CustomerCourseAccess;
  CustomerGroup: CustomerGroup;
  CustomerGroupCourseAccess: CustomerGroupCourseAccess;
  InvitationCode: InvitationCode;
  VistCourse: VistCourse;
  VistCourseLicense: VistCourseLicense;
  VistSimulationLicense: VistSimulationLicense;
  VistSystem: VistSystem;
  SystemsStats: SystemsStats;
  Simulation: Simulation;
  Activity: ResolversParentTypes['VistActivityItem'];
  VistActivityItem: VistActivityItem;
  AllVistActivityItems: AllVistActivityItems;
  Image: Image;
  Recording: Recording;
  Group: Group;
  Metric: Metric;
  Benchmark: Benchmark;
  BenchmarkValue: BenchmarkValue;
  Result: Result;
  ResultFile: ResultFile;
  ResultValue: ResultValue;
  User: User;
  CourseProgress: CourseProgress;
  UnitProgress: UnitProgress;
  TalentCourse: TalentCourse;
  TalentCourseListing: TalentCourseListing;
  TalentBranch: TalentBranch;
  TalentUnit: TalentUnit;
  NewsArticle: NewsArticle;
  YoutubeVideo: YoutubeVideo;
  Voucher: Voucher;
  ActivitySearchParams: ActivitySearchParams;
  VISTCourseSearchParams: VistCourseSearchParams;
  ResetResult: ResetResult;
  UserInput: UserInput;
  CustomerInput: CustomerInput;
  SaveCustomerInput: SaveCustomerInput;
  CreateCustomerGroup: CreateCustomerGroup;
  SaveCustomerGroup: SaveCustomerGroup;
  VoucherInput: VoucherInput;
  RefetchPayload: RefetchPayload;
  InviteResponse: InviteResponse;
  VoucherResponse: VoucherResponse;
  ResourceAccessVoucher: ResolversParentTypes['EventResourceAccessVoucher'] | ResolversParentTypes['CloudModulesResourceAccessVoucher'];
  EventResourceAccessVoucher: EventResourceAccessVoucher;
  CloudModulesResourceAccessVoucher: CloudModulesResourceAccessVoucher;
  EditEventResourceAccessVoucherInput: EditEventResourceAccessVoucherInput;
  CreateEventResourceAccessVoucherInput: CreateEventResourceAccessVoucherInput;
  CreateCloudModulesResourceAccessVoucherInput: CreateCloudModulesResourceAccessVoucherInput;
  EditCloudModulesResourceAccessVoucherInput: EditCloudModulesResourceAccessVoucherInput;
  SingleUserCloudModulesResourceAccessVoucher: SingleUserCloudModulesResourceAccessVoucher;
  CloudModule: CloudModule;
  CloudModuleInput: CloudModuleInput;
  CloudCase: CloudCase;
  UserQuotum: UserQuotum;
  CustomerCloudModuleTimeBalance: CustomerCloudModuleTimeBalance;
  QRLoginResponse: QrLoginResponse;
  SoftwareRelease: SoftwareRelease;
  SoftwareReleaseInput: SoftwareReleaseInput;
  System: System;
  SystemRelease: SystemRelease;
  SystemReleaseInput: SystemReleaseInput;
  SystemInput: SystemInput;
  SystemSoftware: SystemSoftware;
  SystemSoftwaresReleases: SystemSoftwaresReleases;
  ProcedureActivityMeta: ProcedureActivityMeta;
  ProcedureActivitySearchParams: ProcedureActivitySearchParams;
  ParticipantsInput: ParticipantsInput;
  ProcedureCodeInput: ProcedureCodeInput;
  ProcedureActivityInput: ProcedureActivityInput;
  IRLogFormData: IrLogFormData;
  InterventionType: InterventionType;
  OrganSystem: OrganSystem;
  ProcedureCategory: ProcedureCategory;
  ProcedureSubCategory: ProcedureSubCategory;
  ProcedureActivity: ProcedureActivity;
  Procedure: Procedure;
  Participant: Participant;
  ProcedureActivityReview: ProcedureActivityReview;
  Hospital: Hospital;
  Attending: Attending;
  Date: Scalars['Date'];
  Time: Scalars['Time'];
  DateTime: Scalars['DateTime'];
  Timestamp: Scalars['Timestamp'];
  UtcOffset: Scalars['UtcOffset'];
  Duration: Scalars['Duration'];
  ISO8601Duration: Scalars['ISO8601Duration'];
  LocalDate: Scalars['LocalDate'];
  LocalTime: Scalars['LocalTime'];
  LocalEndTime: Scalars['LocalEndTime'];
  EmailAddress: Scalars['EmailAddress'];
  NegativeFloat: Scalars['NegativeFloat'];
  NegativeInt: Scalars['NegativeInt'];
  NonEmptyString: Scalars['NonEmptyString'];
  NonNegativeFloat: Scalars['NonNegativeFloat'];
  NonNegativeInt: Scalars['NonNegativeInt'];
  NonPositiveFloat: Scalars['NonPositiveFloat'];
  NonPositiveInt: Scalars['NonPositiveInt'];
  PhoneNumber: Scalars['PhoneNumber'];
  PositiveFloat: Scalars['PositiveFloat'];
  PositiveInt: Scalars['PositiveInt'];
  PostalCode: Scalars['PostalCode'];
  UnsignedFloat: Scalars['UnsignedFloat'];
  UnsignedInt: Scalars['UnsignedInt'];
  URL: Scalars['URL'];
  BigInt: Scalars['BigInt'];
  Byte: Scalars['Byte'];
  Long: Scalars['Long'];
  SafeInt: Scalars['SafeInt'];
  UUID: Scalars['UUID'];
  GUID: Scalars['GUID'];
  Hexadecimal: Scalars['Hexadecimal'];
  HexColorCode: Scalars['HexColorCode'];
  HSL: Scalars['HSL'];
  HSLA: Scalars['HSLA'];
  IPv4: Scalars['IPv4'];
  IPv6: Scalars['IPv6'];
  ISBN: Scalars['ISBN'];
  MAC: Scalars['MAC'];
  Port: Scalars['Port'];
  RGB: Scalars['RGB'];
  RGBA: Scalars['RGBA'];
  USCurrency: Scalars['USCurrency'];
  Currency: Scalars['Currency'];
  JSON: Scalars['JSON'];
  JSONObject: Scalars['JSONObject'];
  IBAN: Scalars['IBAN'];
  ObjectID: Scalars['ObjectID'];
  Void: Scalars['Void'];
}>;

export interface JsonObject_ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject_'], any> {
  name: 'JSONObject_';
}

export interface DateTime_ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime_'], any> {
  name: 'DateTime_';
}

export type MutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationResponse'] = ResolversParentTypes['MutationResponse']> = ResolversObject<{
  code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  IRLogFormData?: Resolver<ResolversTypes['IRLogFormData'], ParentType, ContextType>;
  activities?: Resolver<ResolversTypes['ProcedureActivityMeta'], ParentType, ContextType, RequireFields<QueryActivitiesArgs, 'searchParams'>>;
  activity?: Resolver<ResolversTypes['ProcedureActivity'], ParentType, ContextType, RequireFields<QueryActivityArgs, 'id'>>;
  allCustomerGroups?: Resolver<Array<ResolversTypes['CustomerGroup']>, ParentType, ContextType>;
  allSoftwareReleases?: Resolver<Array<Maybe<ResolversTypes['SoftwareRelease']>>, ParentType, ContextType>;
  allSystems?: Resolver<Array<Maybe<ResolversTypes['System']>>, ParentType, ContextType, RequireFields<QueryAllSystemsArgs, 'customerId'>>;
  allSystemsAndSoftwaresReleases?: Resolver<Array<Maybe<ResolversTypes['SystemSoftwaresReleases']>>, ParentType, ContextType>;
  allTalentBranchCourses?: Resolver<Array<Maybe<ResolversTypes['TalentCourse']>>, ParentType, ContextType, RequireFields<QueryAllTalentBranchCoursesArgs, 'talentBranchId' | 'emailAddress'>>;
  allVistActivities?: Resolver<ResolversTypes['AllVistActivityItems'], ParentType, ContextType, RequireFields<QueryAllVistActivitiesArgs, never>>;
  attendings?: Resolver<Array<ResolversTypes['Attending']>, ParentType, ContextType, RequireFields<QueryAttendingsArgs, 'customerId'>>;
  caseDownloadLink?: Resolver<ResolversTypes['URL'], ParentType, ContextType, RequireFields<QueryCaseDownloadLinkArgs, 'unitId'>>;
  cloudCaseVMs?: Resolver<Array<ResolversTypes['CloudCaseVirtualMachine']>, ParentType, ContextType>;
  coursesByGroup?: Resolver<Array<ResolversTypes['TalentCourse']>, ParentType, ContextType, RequireFields<QueryCoursesByGroupArgs, 'customerGroupId'>>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<QueryCustomerArgs, 'customerId'>>;
  customerGroups?: Resolver<Array<ResolversTypes['CustomerGroup']>, ParentType, ContextType, RequireFields<QueryCustomerGroupsArgs, never>>;
  customerGroupsByUser?: Resolver<Array<ResolversTypes['CustomerGroup']>, ParentType, ContextType>;
  customerLearningSpaceAccess?: Resolver<Maybe<ResolversTypes['CustomerLearningSpaceAccess']>, ParentType, ContextType, RequireFields<QueryCustomerLearningSpaceAccessArgs, 'customerId' | 'learningSpaceId'>>;
  customers?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType>;
  enrolledTalentBranches?: Resolver<Array<Maybe<ResolversTypes['TalentBranch']>>, ParentType, ContextType, RequireFields<QueryEnrolledTalentBranchesArgs, 'emailAddress'>>;
  getAllCloudModulesResourceAccessVouchersAsAdmin?: Resolver<Array<ResolversTypes['CloudModulesResourceAccessVoucher']>, ParentType, ContextType>;
  getCloudModulesResourceAccessVouchersAsTrainee?: Resolver<Array<ResolversTypes['SingleUserCloudModulesResourceAccessVoucher']>, ParentType, ContextType>;
  getCustomerGroup?: Resolver<ResolversTypes['CustomerGroup'], ParentType, ContextType, RequireFields<QueryGetCustomerGroupArgs, 'customerGroupId'>>;
  getCustomersForAdmin?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType>;
  getEventResourceAccessVoucherById?: Resolver<Maybe<ResolversTypes['EventResourceAccessVoucher']>, ParentType, ContextType, RequireFields<QueryGetEventResourceAccessVoucherByIdArgs, 'voucherId'>>;
  getEventResourceAccessVouchersAsAdmin?: Resolver<Array<ResolversTypes['EventResourceAccessVoucher']>, ParentType, ContextType>;
  getEventResourceAccessVouchersAsTrainee?: Resolver<Array<ResolversTypes['EventResourceAccessVoucher']>, ParentType, ContextType>;
  getVmAssignedToMe?: Resolver<Maybe<ResolversTypes['CloudCaseVirtualMachine']>, ParentType, ContextType>;
  hospitals?: Resolver<Array<ResolversTypes['Hospital']>, ParentType, ContextType, RequireFields<QueryHospitalsArgs, 'customerId'>>;
  logDownloadLink?: Resolver<ResolversTypes['URL'], ParentType, ContextType, RequireFields<QueryLogDownloadLinkArgs, 'activityIds'>>;
  maxClientVersion?: Resolver<Maybe<ResolversTypes['SoftwareRelease']>, ParentType, ContextType>;
  newsArticles?: Resolver<Array<ResolversTypes['NewsArticle']>, ParentType, ContextType>;
  simulation?: Resolver<ResolversTypes['Simulation'], ParentType, ContextType, RequireFields<QuerySimulationArgs, 'id'>>;
  singleUserCloudCaseVMAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QuerySingleUserCloudCaseVmAvailableArgs, 'durationInSeconds'>>;
  softwareReleaseByReleaseId?: Resolver<Maybe<Array<Maybe<ResolversTypes['SoftwareRelease']>>>, ParentType, ContextType, RequireFields<QuerySoftwareReleaseByReleaseIdArgs, 'releaseId'>>;
  softwareReleasesBySystemsIds?: Resolver<Array<Maybe<ResolversTypes['SoftwareRelease']>>, ParentType, ContextType, RequireFields<QuerySoftwareReleasesBySystemsIdsArgs, 'systemsIds' | 'customerId'>>;
  systemAndSoftwareReleasesBySerialNumber?: Resolver<Maybe<ResolversTypes['SystemSoftwaresReleases']>, ParentType, ContextType, RequireFields<QuerySystemAndSoftwareReleasesBySerialNumberArgs, 'serialNumber'>>;
  systemById?: Resolver<ResolversTypes['System'], ParentType, ContextType, RequireFields<QuerySystemByIdArgs, 'id'>>;
  systemReleaseBySystemAndSoftwareId?: Resolver<Array<Maybe<ResolversTypes['SystemRelease']>>, ParentType, ContextType, RequireFields<QuerySystemReleaseBySystemAndSoftwareIdArgs, 'systemId' | 'softwareId'>>;
  systemsBySoftwaresIds?: Resolver<Array<Maybe<ResolversTypes['System']>>, ParentType, ContextType, RequireFields<QuerySystemsBySoftwaresIdsArgs, 'softwaresIds'>>;
  talentBranchByName?: Resolver<Maybe<ResolversTypes['TalentBranch']>, ParentType, ContextType, RequireFields<QueryTalentBranchByNameArgs, 'talentBranchName'>>;
  talentBranches?: Resolver<Array<ResolversTypes['TalentBranch']>, ParentType, ContextType>;
  talentCourse?: Resolver<ResolversTypes['TalentCourse'], ParentType, ContextType, RequireFields<QueryTalentCourseArgs, 'talentCourseId'>>;
  talentCourseListings?: Resolver<Array<ResolversTypes['TalentCourseListing']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserArgs, 'emailAddress'>>;
  vistActivities?: Resolver<Array<ResolversTypes['VistActivityItem']>, ParentType, ContextType, RequireFields<QueryVistActivitiesArgs, 'groups'>>;
  vistActivitiesByServiceTag?: Resolver<Array<ResolversTypes['VistActivityItem']>, ParentType, ContextType, RequireFields<QueryVistActivitiesByServiceTagArgs, 'serviceTag'>>;
  vistActivity?: Resolver<ResolversTypes['VistActivityItem'], ParentType, ContextType, RequireFields<QueryVistActivityArgs, 'id'>>;
  vistAvailableReleasesForSystem?: Resolver<Array<ResolversTypes['VistSystemSoftwareRelease']>, ParentType, ContextType, RequireFields<QueryVistAvailableReleasesForSystemArgs, 'serviceTag'>>;
  vistCases?: Resolver<Array<ResolversTypes['VistCourse']>, ParentType, ContextType, RequireFields<QueryVistCasesArgs, 'serviceTag'>>;
  vistComputerGet?: Resolver<Maybe<ResolversTypes['VistSystemComputer']>, ParentType, ContextType, RequireFields<QueryVistComputerGetArgs, 'serviceTag'>>;
  vistCourse?: Resolver<ResolversTypes['VistCourse'], ParentType, ContextType, RequireFields<QueryVistCourseArgs, 'code' | 'version' | 'serviceTag'>>;
  vistCourses?: Resolver<Array<ResolversTypes['VistCourse']>, ParentType, ContextType, RequireFields<QueryVistCoursesArgs, 'serviceTag'>>;
  vistReleaseGetAll?: Resolver<Array<ResolversTypes['VistRelease']>, ParentType, ContextType>;
  vistReleaseOpsForSystem?: Resolver<Array<ResolversTypes['VistReleaseOp']>, ParentType, ContextType, RequireFields<QueryVistReleaseOpsForSystemArgs, 'serviceTag'>>;
  vistSystemReleaseStatesForTag?: Resolver<Array<ResolversTypes['VistSystemSoftwareReleaseState']>, ParentType, ContextType, RequireFields<QueryVistSystemReleaseStatesForTagArgs, 'serviceTag'>>;
  vistSystems?: Resolver<Array<ResolversTypes['VistSystem']>, ParentType, ContextType>;
  vistSystemsByServiceTag?: Resolver<Array<ResolversTypes['VistSystem']>, ParentType, ContextType, RequireFields<QueryVistSystemsByServiceTagArgs, 'serviceTag'>>;
  vistSystemsLogsNumberByPeriod?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryVistSystemsLogsNumberByPeriodArgs, 'from' | 'to'>>;
  vistSystemsStats?: Resolver<ResolversTypes['SystemsStats'], ParentType, ContextType>;
  youtubeVideos?: Resolver<Array<ResolversTypes['YoutubeVideo']>, ParentType, ContextType, RequireFields<QueryYoutubeVideosArgs, 'maxResults'>>;
}>;

export type VistSoftwareResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSoftware'] = ResolversParentTypes['VistSoftware']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistRelease'] = ResolversParentTypes['VistRelease']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stringIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  software?: Resolver<ResolversTypes['VistSoftware'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['VistReleaseVersion'], ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['VistReleaseResources']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistReleaseResourcesResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistReleaseResources'] = ResolversParentTypes['VistReleaseResources']> = ResolversObject<{
  installer?: Resolver<ResolversTypes['VistReleaseResource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistReleaseResourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistReleaseResource'] = ResolversParentTypes['VistReleaseResource']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checksum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistReleaseVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistReleaseVersion'] = ResolversParentTypes['VistReleaseVersion']> = ResolversObject<{
  semantic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  major?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  patch?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  QRLogin?: Resolver<ResolversTypes['QRLoginResponse'], ParentType, ContextType, RequireFields<MutationQrLoginArgs, 'payload'>>;
  acceptInvite?: Resolver<ResolversTypes['InviteResponse'], ParentType, ContextType, RequireFields<MutationAcceptInviteArgs, 'code'>>;
  acceptResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationAcceptResourceAccessVoucherArgs, 'code'>>;
  acceptVoucher?: Resolver<ResolversTypes['VoucherResponse'], ParentType, ContextType, RequireFields<MutationAcceptVoucherArgs, 'code'>>;
  addCustomerAdmin?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationAddCustomerAdminArgs, 'customerId' | 'admin'>>;
  addCustomerCloudModuleQuotaTransaction?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationAddCustomerCloudModuleQuotaTransactionArgs, 'customerId' | 'timeQuotumDifferenceInMs'>>;
  archiveEventResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationArchiveEventResourceAccessVoucherArgs, 'eventResourceAccessVoucherId'>>;
  archiveResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationArchiveResourceAccessVoucherArgs, 'resourceAccessVoucherId'>>;
  createAttending?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCreateAttendingArgs, 'firstName' | 'lastName' | 'customerId'>>;
  createCloudModulesResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCreateCloudModulesResourceAccessVoucherArgs, 'createCloudModulesResourceAccessVoucher'>>;
  createCourseVoucher?: Resolver<ResolversTypes['Voucher'], ParentType, ContextType, RequireFields<MutationCreateCourseVoucherArgs, 'voucher'>>;
  createCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationCreateCustomerArgs, 'name'>>;
  createCustomerGroup?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCreateCustomerGroupArgs, 'customerGroup'>>;
  createEventResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCreateEventResourceAccessVoucherArgs, 'createEventResourceAccessVoucherInput'>>;
  createHospital?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCreateHospitalArgs, 'name' | 'customerId'>>;
  customerGroupCourseAccess?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationCustomerGroupCourseAccessArgs, 'customerGroupId' | 'courseIds' | 'fullRemoval'>>;
  deleteActivity?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteActivityArgs, 'activity'>>;
  deleteAttending?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteAttendingArgs, 'id'>>;
  deleteHospital?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteHospitalArgs, 'id'>>;
  deleteSoftwareRelease?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteSoftwareReleaseArgs, 'softwareId'>>;
  deleteSystem?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteSystemArgs, 'systemId'>>;
  deleteSystemRelease?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationDeleteSystemReleaseArgs, 'systemId' | 'softwareId'>>;
  editCloudModulesResourceAccessVoucher?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationEditCloudModulesResourceAccessVoucherArgs, 'editCloudModulesResourceAccessVoucher'>>;
  editEventResourceAccessVoucher?: Resolver<Maybe<ResolversTypes['RefetchPayload']>, ParentType, ContextType, RequireFields<MutationEditEventResourceAccessVoucherArgs, 'editEventResourceAccessVoucherInput'>>;
  enrollGroupUserInCourse?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationEnrollGroupUserInCourseArgs, 'talentCourseId' | 'emailAddress' | 'customerGroupId'>>;
  enrollTalentUserInCourse?: Resolver<ResolversTypes['TalentCourse'], ParentType, ContextType, RequireFields<MutationEnrollTalentUserInCourseArgs, 'talentCourseId' | 'emailAddress'>>;
  leaveGroup?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationLeaveGroupArgs, 'customerGroupId'>>;
  removeAllUsers?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationRemoveAllUsersArgs, 'customerGroupId'>>;
  removeCustomerAdmin?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationRemoveCustomerAdminArgs, 'customerId' | 'adminId'>>;
  removeCustomerGroup?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationRemoveCustomerGroupArgs, 'customerId' | 'customerGroupId' | 'retainCourseAccess'>>;
  removeUser?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationRemoveUserArgs, 'customerGroupId' | 'userId'>>;
  removeVM?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationRemoveVmArgs, 'vmId'>>;
  resetAccountProgress?: Resolver<Maybe<ResolversTypes['ResetResult']>, ParentType, ContextType>;
  resetMyCourseProgress?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationResetMyCourseProgressArgs, 'courseId'>>;
  resetVMToIdle?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationResetVmToIdleArgs, 'vmId'>>;
  resetVMToOff?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationResetVmToOffArgs, 'vmId'>>;
  saveActivity?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationSaveActivityArgs, 'activity'>>;
  saveCustomer?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationSaveCustomerArgs, 'customer'>>;
  saveCustomerGroup?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationSaveCustomerGroupArgs, 'customerGroup'>>;
  saveLearningSpace?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationSaveLearningSpaceArgs, 'id' | 'displayName' | 'image'>>;
  saveLearningSpaceAccess?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationSaveLearningSpaceAccessArgs, 'customerId' | 'learningSpaceId' | 'courses' | 'fullAccess'>>;
  saveSoftwareRelease?: Resolver<ResolversTypes['SoftwareRelease'], ParentType, ContextType, RequireFields<MutationSaveSoftwareReleaseArgs, 'softwareRelease'>>;
  saveSystem?: Resolver<ResolversTypes['System'], ParentType, ContextType, RequireFields<MutationSaveSystemArgs, 'system'>>;
  saveSystemRelease?: Resolver<ResolversTypes['SystemRelease'], ParentType, ContextType, RequireFields<MutationSaveSystemReleaseArgs, 'systemRelease'>>;
  startCloudCaseEvent?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationStartCloudCaseEventArgs, 'moduleId' | 'caseId' | 'voucherId'>>;
  startSingleUserCloudCase?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationStartSingleUserCloudCaseArgs, 'moduleId' | 'caseId' | 'voucherId'>>;
  stopCloudCaseSessionWithVoucherId?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationStopCloudCaseSessionWithVoucherIdArgs, 'voucherId'>>;
  syncWithTalent?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType>;
  turnOffVM?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationTurnOffVmArgs, 'url'>>;
  turnOnVM?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationTurnOnVmArgs, 'url'>>;
  updateAttending?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateAttendingArgs, 'id' | 'firstName' | 'lastName'>>;
  updateCustomer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationUpdateCustomerArgs, 'customer'>>;
  updateHospital?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateHospitalArgs, 'id' | 'name'>>;
  updateSoftwareRelease?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateSoftwareReleaseArgs, 'id' | 'softwareRelease'>>;
  updateSystem?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateSystemArgs, 'id' | 'system'>>;
  updateSystemReleaseStatus?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateSystemReleaseStatusArgs, 'status' | 'systemId' | 'softwareId'>>;
  updateUser?: Resolver<ResolversTypes['RefetchPayload'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'user' | 'emailAddress'>>;
  uploadInstall?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUploadInstallArgs, 'installation'>>;
  uploadVistActivity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUploadVistActivityArgs, 'metadata'>>;
  vistComputerSetInfo?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistComputerSetInfoArgs, 'serviceTag' | 'info'>>;
  vistReleaseOpSetDetailedState?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistReleaseOpSetDetailedStateArgs, 'id' | 'input'>>;
  vistSystemReleaseStateSetAllDownloadedForTag?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistSystemReleaseStateSetAllDownloadedForTagArgs, 'serviceTag' | 'softwares'>>;
  vistSystemReleaseStateSetAllInstalledForTag?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistSystemReleaseStateSetAllInstalledForTagArgs, 'serviceTag' | 'softwares'>>;
  vistSystemSoftwareReleaseSetDownload?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistSystemSoftwareReleaseSetDownloadArgs, 'id'>>;
  vistSystemSoftwareReleaseSetInstall?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationVistSystemSoftwareReleaseSetInstallArgs, 'id'>>;
}>;

export type VistSystemComputerResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSystemComputer'] = ResolversParentTypes['VistSystemComputer']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  serviceTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  info?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistSystemSoftwareReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSystemSoftwareRelease'] = ResolversParentTypes['VistSystemSoftwareRelease']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  release?: Resolver<ResolversTypes['VistRelease'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['VistSystemSoftwareReleaseState'], ParentType, ContextType>;
  currentRelease?: Resolver<Maybe<ResolversTypes['VistRelease']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime_']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistReleaseOpResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistReleaseOp'] = ResolversParentTypes['VistReleaseOp']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  releaseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  releaseIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downloadOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  installerLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSONObject_']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistSystemSoftwareReleaseStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSystemSoftwareReleaseState'] = ResolversParentTypes['VistSystemSoftwareReleaseState']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  system?: Resolver<ResolversTypes['VistSystemComputer'], ParentType, ContextType>;
  release?: Resolver<ResolversTypes['VistRelease'], ParentType, ContextType>;
  downloaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  installed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  operationStates?: Resolver<ResolversTypes['VistSystemSoftwareReleaseOperations'], ParentType, ContextType>;
  operationIsRunning?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistSystemSoftwareReleaseOperationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSystemSoftwareReleaseOperations'] = ResolversParentTypes['VistSystemSoftwareReleaseOperations']> = ResolversObject<{
  download?: Resolver<ResolversTypes['OperationDetailedState'], ParentType, ContextType>;
  install?: Resolver<ResolversTypes['OperationDetailedState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OperationDetailedStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationDetailedState'] = ResolversParentTypes['OperationDetailedState']> = ResolversObject<{
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  errors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloudCaseVirtualMachineResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloudCaseVirtualMachine'] = ResolversParentTypes['CloudCaseVirtualMachine']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  started_by?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  module_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  case_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  session_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucher_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  session_started_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  history?: Resolver<Maybe<Array<ResolversTypes['CloudCaseVirtualMachineStateChange']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloudCaseVirtualMachineStateChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloudCaseVirtualMachineStateChange'] = ResolversParentTypes['CloudCaseVirtualMachineStateChange']> = ResolversObject<{
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  new_status?: Resolver<ResolversTypes['CloudCaseVMStatus'], ParentType, ContextType>;
  prev_status?: Resolver<ResolversTypes['CloudCaseVMStatus'], ParentType, ContextType>;
  started_by?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  case_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  module_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucher_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  branches?: Resolver<Array<ResolversTypes['TalentBranch']>, ParentType, ContextType>;
  customerGroups?: Resolver<Array<ResolversTypes['CustomerGroup']>, ParentType, ContextType>;
  admins?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  cloudModuleTimeBalance?: Resolver<ResolversTypes['CustomerCloudModuleTimeBalance'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerLearningSpaceAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerLearningSpaceAccess'] = ResolversParentTypes['CustomerLearningSpaceAccess']> = ResolversObject<{
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  learningSpace?: Resolver<ResolversTypes['TalentBranch'], ParentType, ContextType>;
  courses?: Resolver<Array<ResolversTypes['CustomerCourseAccess']>, ParentType, ContextType>;
  fullAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cloudModules?: Resolver<Array<ResolversTypes['CloudModule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerCourseAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerCourseAccess'] = ResolversParentTypes['CustomerCourseAccess']> = ResolversObject<{
  course?: Resolver<ResolversTypes['TalentCourse'], ParentType, ContextType>;
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerGroup'] = ResolversParentTypes['CustomerGroup']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  admins?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  invitationCode?: Resolver<Maybe<ResolversTypes['InvitationCode']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['CustomerGroupCourseAccess']>>, ParentType, ContextType>;
  assignedCourses?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerGroupCourseAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerGroupCourseAccess'] = ResolversParentTypes['CustomerGroupCourseAccess']> = ResolversObject<{
  course?: Resolver<ResolversTypes['TalentCourse'], ParentType, ContextType>;
  assigned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InvitationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitationCode'] = ResolversParentTypes['InvitationCode']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistCourse'] = ResolversParentTypes['VistCourse']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resultFormatVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  simulations?: Resolver<Array<ResolversTypes['Simulation']>, ParentType, ContextType>;
  serviceTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasCaseCreator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  license?: Resolver<Maybe<ResolversTypes['VistCourseLicense']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistCourseLicenseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistCourseLicense'] = ResolversParentTypes['VistCourseLicense']> = ResolversObject<{
  checked?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  simulations?: Resolver<Array<ResolversTypes['VistSimulationLicense']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistSimulationLicenseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSimulationLicense'] = ResolversParentTypes['VistSimulationLicense']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  remainingdays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VistSystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistSystem'] = ResolversParentTypes['VistSystem']> = ResolversObject<{
  serviceTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  simulationsDone?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastSimulation?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemsStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemsStats'] = ResolversParentTypes['SystemsStats']> = ResolversObject<{
  connected?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loggedTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loggedLastWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logsTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logsLastWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logsLastMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logsLastYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SimulationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Simulation'] = ResolversParentTypes['Simulation']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCaseCreator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  groups?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['Metric']>, ParentType, ContextType>;
  benchmarks?: Resolver<Array<ResolversTypes['Benchmark']>, ParentType, ContextType>;
  results?: Resolver<Array<ResolversTypes['Result']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Activity'] = ResolversParentTypes['Activity']> = ResolversObject<{
  __resolveType: TypeResolveFn<'VistActivityItem', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityType'], ParentType, ContextType>;
  activityStart?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  activityEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  totalTimeInSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
}>;

export type VistActivityItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VistActivityItem'] = ResolversParentTypes['VistActivityItem']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityType'], ParentType, ContextType>;
  activityStart?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  activityEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  totalTimeInSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sourceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes['TalentCourse']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  exerciseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exerciseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moduleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  moduleVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalResultLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  benchmarkLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  metricValuesLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  groupsLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  metricDescriptionsLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType, RequireFields<VistActivityItemImageArgs, 'id'>>;
  recordings?: Resolver<Maybe<Array<ResolversTypes['Recording']>>, ParentType, ContextType>;
  recording?: Resolver<Maybe<ResolversTypes['Recording']>, ParentType, ContextType, RequireFields<VistActivityItemRecordingArgs, 'id'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AllVistActivityItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllVistActivityItems'] = ResolversParentTypes['AllVistActivityItems']> = ResolversObject<{
  activities?: Resolver<Maybe<Array<ResolversTypes['VistActivityItem']>>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = ResolversObject<{
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  thumbnailLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RecordingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Recording'] = ResolversParentTypes['Recording']> = ResolversObject<{
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Duration'], ParentType, ContextType>;
  thumbnailLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['Group']>>, ParentType, ContextType>;
  metrics?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  is_expanded_by_default?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metric'] = ResolversParentTypes['Metric']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  internal_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  default_operator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value_format?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_comparable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BenchmarkResolvers<ContextType = any, ParentType extends ResolversParentTypes['Benchmark'] = ResolversParentTypes['Benchmark']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['BenchmarkValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BenchmarkValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['BenchmarkValue'] = ResolversParentTypes['BenchmarkValue']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default_operator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_shown_as_graph?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metric?: Resolver<ResolversTypes['Metric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['Result'] = ResolversParentTypes['Result']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['ResultFile']>, ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['ResultValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResultFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResultFile'] = ResolversParentTypes['ResultFile']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResultValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResultValue'] = ResolversParentTypes['ResultValue']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metric?: Resolver<ResolversTypes['Metric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  first_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keycloak_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hospital_university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number_of_real_procedures_performed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number_of_simulated_procedures_performed?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_interventional_cardiology_years_experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_interventional_neuroradiology_years_experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_interventional_radiology_years_experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_other_years_experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specialty_vascular_surgery_years_experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  years_as_first_operator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  years_as_second_operator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondary_email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logbook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CourseProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseProgress'] = ResolversParentTypes['CourseProgress']> = ResolversObject<{
  completion_percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completion_status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<Maybe<Array<ResolversTypes['UnitProgress']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnitProgressResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnitProgress'] = ResolversParentTypes['UnitProgress']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completion_status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed_on?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completed_on_timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total_time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_time_seconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TalentCourse'] = ResolversParentTypes['TalentCourse']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  big_avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completion_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completion_percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  goto_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<TalentCourseGoto_UrlArgs, 'emailAddress' | 'talentBranchName'>>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['TalentUnit']>>>, ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['CourseProgress'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentCourseListingResolvers<ContextType = any, ParentType extends ResolversParentTypes['TalentCourseListing'] = ResolversParentTypes['TalentCourseListing']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  big_avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  learningSpaces?: Resolver<Array<ResolversTypes['TalentBranch']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentBranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['TalentBranch'] = ResolversParentTypes['TalentBranch']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TalentUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['TalentUnit'] = ResolversParentTypes['TalentUnit']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NewsArticleResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewsArticle'] = ResolversParentTypes['NewsArticle']> = ResolversObject<{
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type YoutubeVideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['YoutubeVideo'] = ResolversParentTypes['YoutubeVideo']> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['Voucher'] = ResolversParentTypes['Voucher']> = ResolversObject<{
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResetResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetResult'] = ResolversParentTypes['ResetResult']> = ResolversObject<{
  done?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RefetchPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefetchPayload'] = ResolversParentTypes['RefetchPayload']> = ResolversObject<{
  refetch?: Resolver<ResolversTypes['Query'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InviteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteResponse'] = ResolversParentTypes['InviteResponse']> = ResolversObject<{
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VoucherResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherResponse'] = ResolversParentTypes['VoucherResponse']> = ResolversObject<{
  course?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  learningSpaceNames?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResourceAccessVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceAccessVoucher'] = ResolversParentTypes['ResourceAccessVoucher']> = ResolversObject<{
  __resolveType: TypeResolveFn<'EventResourceAccessVoucher' | 'CloudModulesResourceAccessVoucher', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ResourceAccessVoucherType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  learningSpace?: Resolver<ResolversTypes['TalentBranch'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
}>;

export type EventResourceAccessVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventResourceAccessVoucher'] = ResolversParentTypes['EventResourceAccessVoucher']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ResourceAccessVoucherType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  learningSpace?: Resolver<ResolversTypes['TalentBranch'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  modules?: Resolver<Array<ResolversTypes['CloudModule']>, ParentType, ContextType>;
  eventAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  vm?: Resolver<Maybe<ResolversTypes['CloudCaseVirtualMachine']>, ParentType, ContextType>;
  durationInSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloudModulesResourceAccessVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloudModulesResourceAccessVoucher'] = ResolversParentTypes['CloudModulesResourceAccessVoucher']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ResourceAccessVoucherType'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  learningSpace?: Resolver<ResolversTypes['TalentBranch'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  modules?: Resolver<Array<ResolversTypes['CloudModule']>, ParentType, ContextType>;
  maxDurationInSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userQuota?: Resolver<Array<ResolversTypes['UserQuotum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SingleUserCloudModulesResourceAccessVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['SingleUserCloudModulesResourceAccessVoucher'] = ResolversParentTypes['SingleUserCloudModulesResourceAccessVoucher']> = ResolversObject<{
  voucherId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  learningSpace?: Resolver<ResolversTypes['TalentBranch'], ParentType, ContextType>;
  modules?: Resolver<Array<ResolversTypes['CloudModule']>, ParentType, ContextType>;
  durationLeftInSeconds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloudModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloudModule'] = ResolversParentTypes['CloudModule']> = ResolversObject<{
  moduleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cases?: Resolver<Array<ResolversTypes['CloudCase']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloudCaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloudCase'] = ResolversParentTypes['CloudCase']> = ResolversObject<{
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserQuotumResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserQuotum'] = ResolversParentTypes['UserQuotum']> = ResolversObject<{
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  durationLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerCloudModuleTimeBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerCloudModuleTimeBalance'] = ResolversParentTypes['CustomerCloudModuleTimeBalance']> = ResolversObject<{
  consumedTimeInMs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reservedTimeInMs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spendableTimeInMs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QrLoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QRLoginResponse'] = ResolversParentTypes['QRLoginResponse']> = ResolversObject<{
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SoftwareReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SoftwareRelease'] = ResolversParentTypes['SoftwareRelease']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  releaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  releaseDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  requiresLicense?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productBrandName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  branch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SoftwareReleaseStatus']>, ParentType, ContextType>;
  licensedModuleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  links?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['System'] = ResolversParentTypes['System']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  account?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  adminVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  computer?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  externalHardware?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  haptic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initialInstallDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  installationCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  masterSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  network?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serialNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SystemStatus']>, ParentType, ContextType>;
  statusUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemReleaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemRelease'] = ResolversParentTypes['SystemRelease']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  softwareId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SystemReleaseStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemSoftwareResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemSoftware'] = ResolversParentTypes['SystemSoftware']> = ResolversObject<{
  systemReleaseId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  systemReleaseStatus?: Resolver<Maybe<ResolversTypes['SystemReleaseStatus']>, ParentType, ContextType>;
  systemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  softwareReleaseId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  systemAccount?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  systemAdminVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemComputer?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  systemExternalHardware?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  systemHaptic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemInitialInstallDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  systemInstallationCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  systemMasterSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemNetwork?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemStatus?: Resolver<Maybe<ResolversTypes['SystemStatus']>, ParentType, ContextType>;
  systemStatusUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  softwareReleaseBranch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  softwareReleaseLicensedModuleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  softwareReleaseLinks?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  softwareReleaseProductBrandName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  softwareReleaseReleaseDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  softwareReleaseReleaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  softwareReleaseRequiresLicense?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  softwareReleaseStatus?: Resolver<Maybe<ResolversTypes['SoftwareReleaseStatus']>, ParentType, ContextType>;
  softwareReleaseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  softwareReleaseVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SystemSoftwaresReleasesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemSoftwaresReleases'] = ResolversParentTypes['SystemSoftwaresReleases']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  account?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  adminVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  computer?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  externalHardware?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  haptic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initialInstallDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  installationCreatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  masterSerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  network?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SystemStatus']>, ParentType, ContextType>;
  statusUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  softwaresReleases?: Resolver<Array<Maybe<ResolversTypes['SoftwareRelease']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureActivityMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcedureActivityMeta'] = ResolversParentTypes['ProcedureActivityMeta']> = ResolversObject<{
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activities?: Resolver<Array<ResolversTypes['ProcedureActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IrLogFormDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['IRLogFormData'] = ResolversParentTypes['IRLogFormData']> = ResolversObject<{
  interventionTypes?: Resolver<Array<ResolversTypes['InterventionType']>, ParentType, ContextType>;
  attending?: Resolver<Array<ResolversTypes['Attending']>, ParentType, ContextType>;
  hospitals?: Resolver<Array<ResolversTypes['Hospital']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InterventionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InterventionType'] = ResolversParentTypes['InterventionType']> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organSystems?: Resolver<Array<ResolversTypes['OrganSystem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganSystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganSystem'] = ResolversParentTypes['OrganSystem']> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['ProcedureCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcedureCategory'] = ResolversParentTypes['ProcedureCategory']> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subCategories?: Resolver<Array<ResolversTypes['ProcedureSubCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureSubCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcedureSubCategory'] = ResolversParentTypes['ProcedureSubCategory']> = ResolversObject<{
  subCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcedureActivity'] = ResolversParentTypes['ProcedureActivity']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityType'], ParentType, ContextType>;
  dateOfProcedure?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  optionalIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hospital?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ageCategory?: Resolver<Maybe<ResolversTypes['AgeCategory']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['PatientSex']>, ParentType, ContextType>;
  setting?: Resolver<Maybe<ResolversTypes['ProcedureSetting']>, ParentType, ContextType>;
  attending?: Resolver<Maybe<ResolversTypes['Attending']>, ParentType, ContextType>;
  participants?: Resolver<Maybe<Array<ResolversTypes['Participant']>>, ParentType, ContextType>;
  procedure?: Resolver<Maybe<ResolversTypes['Procedure']>, ParentType, ContextType>;
  procedureComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  procedureIndication?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modalityPrimary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modalitySecondary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onCall?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  successComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doseCtdi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  doseDlp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  doseSkin?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  doseDap?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  doseTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dictated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  complications?: Resolver<Maybe<ResolversTypes['Complication']>, ParentType, ContextType>;
  complicationsComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ebl?: Resolver<Maybe<ResolversTypes['Ebl']>, ParentType, ContextType>;
  sedation?: Resolver<Maybe<ResolversTypes['Sedation']>, ParentType, ContextType>;
  platelet?: Resolver<Maybe<ResolversTypes['Platelet']>, ParentType, ContextType>;
  inr?: Resolver<Maybe<ResolversTypes['Inr']>, ParentType, ContextType>;
  inrReversal?: Resolver<Maybe<ResolversTypes['InrReversal']>, ParentType, ContextType>;
  closureDevice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  closureDeviceComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageFollowup?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  imageFollowupNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consultFollowup?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  consultFollowupNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AE_B_Causality?: Resolver<Maybe<ResolversTypes['AE_B_Category']>, ParentType, ContextType>;
  AE_B_Risk_Modifier?: Resolver<Maybe<ResolversTypes['AE_B_Category']>, ParentType, ContextType>;
  AE_B_Preventability?: Resolver<Maybe<ResolversTypes['AE_B_Category']>, ParentType, ContextType>;
  AE_B_Management?: Resolver<Maybe<ResolversTypes['AE_B_Category']>, ParentType, ContextType>;
  AE_B_comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  AE_B_Reviewed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  AE_B_Reviewers?: Resolver<Maybe<Array<ResolversTypes['ProcedureActivityReview']>>, ParentType, ContextType>;
  diagnosticSample?: Resolver<Maybe<ResolversTypes['DiagnosticSample']>, ParentType, ContextType>;
  ACGME_Cat?: Resolver<Maybe<ResolversTypes['ACGME_Cat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Procedure'] = ResolversParentTypes['Procedure']> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interventionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ParticipantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Participant'] = ResolversParentTypes['Participant']> = ResolversObject<{
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['ParticipantRole']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['ParticipantLevel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcedureActivityReviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcedureActivityReview'] = ResolversParentTypes['ProcedureActivityReview']> = ResolversObject<{
  users?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HospitalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Hospital'] = ResolversParentTypes['Hospital']> = ResolversObject<{
  id?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttendingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attending'] = ResolversParentTypes['Attending']> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export interface UtcOffsetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UtcOffset'], any> {
  name: 'UtcOffset';
}

export interface DurationScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Duration'], any> {
  name: 'Duration';
}

export interface Iso8601DurationScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ISO8601Duration'], any> {
  name: 'ISO8601Duration';
}

export interface LocalDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LocalDate'], any> {
  name: 'LocalDate';
}

export interface LocalTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LocalTime'], any> {
  name: 'LocalTime';
}

export interface LocalEndTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LocalEndTime'], any> {
  name: 'LocalEndTime';
}

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export interface NegativeFloatScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NegativeFloat'], any> {
  name: 'NegativeFloat';
}

export interface NegativeIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NegativeInt'], any> {
  name: 'NegativeInt';
}

export interface NonEmptyStringScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonEmptyString'], any> {
  name: 'NonEmptyString';
}

export interface NonNegativeFloatScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeFloat'], any> {
  name: 'NonNegativeFloat';
}

export interface NonNegativeIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeInt'], any> {
  name: 'NonNegativeInt';
}

export interface NonPositiveFloatScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonPositiveFloat'], any> {
  name: 'NonPositiveFloat';
}

export interface NonPositiveIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NonPositiveInt'], any> {
  name: 'NonPositiveInt';
}

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
  name: 'PhoneNumber';
}

export interface PositiveFloatScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PositiveFloat'], any> {
  name: 'PositiveFloat';
}

export interface PositiveIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PositiveInt'], any> {
  name: 'PositiveInt';
}

export interface PostalCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PostalCode'], any> {
  name: 'PostalCode';
}

export interface UnsignedFloatScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedFloat'], any> {
  name: 'UnsignedFloat';
}

export interface UnsignedIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedInt'], any> {
  name: 'UnsignedInt';
}

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface ByteScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Byte'], any> {
  name: 'Byte';
}

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export interface SafeIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['SafeInt'], any> {
  name: 'SafeInt';
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export interface GuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GUID'], any> {
  name: 'GUID';
}

export interface HexadecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Hexadecimal'], any> {
  name: 'Hexadecimal';
}

export interface HexColorCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HexColorCode'], any> {
  name: 'HexColorCode';
}

export interface HslScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HSL'], any> {
  name: 'HSL';
}

export interface HslaScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HSLA'], any> {
  name: 'HSLA';
}

export interface IPv4ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['IPv4'], any> {
  name: 'IPv4';
}

export interface IPv6ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['IPv6'], any> {
  name: 'IPv6';
}

export interface IsbnScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ISBN'], any> {
  name: 'ISBN';
}

export interface MacScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['MAC'], any> {
  name: 'MAC';
}

export interface PortScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Port'], any> {
  name: 'Port';
}

export interface RgbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RGB'], any> {
  name: 'RGB';
}

export interface RgbaScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RGBA'], any> {
  name: 'RGBA';
}

export interface UsCurrencyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['USCurrency'], any> {
  name: 'USCurrency';
}

export interface CurrencyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Currency'], any> {
  name: 'Currency';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export interface IbanScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['IBAN'], any> {
  name: 'IBAN';
}

export interface ObjectIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ObjectID'], any> {
  name: 'ObjectID';
}

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type Resolvers<ContextType = any> = ResolversObject<{
  JSONObject_?: GraphQLScalarType;
  DateTime_?: GraphQLScalarType;
  MutationResponse?: MutationResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  VistSoftware?: VistSoftwareResolvers<ContextType>;
  VistRelease?: VistReleaseResolvers<ContextType>;
  VistReleaseResources?: VistReleaseResourcesResolvers<ContextType>;
  VistReleaseResource?: VistReleaseResourceResolvers<ContextType>;
  VistReleaseVersion?: VistReleaseVersionResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  VistSystemComputer?: VistSystemComputerResolvers<ContextType>;
  VistSystemSoftwareRelease?: VistSystemSoftwareReleaseResolvers<ContextType>;
  VistReleaseOp?: VistReleaseOpResolvers<ContextType>;
  VistSystemSoftwareReleaseState?: VistSystemSoftwareReleaseStateResolvers<ContextType>;
  VistSystemSoftwareReleaseOperations?: VistSystemSoftwareReleaseOperationsResolvers<ContextType>;
  OperationDetailedState?: OperationDetailedStateResolvers<ContextType>;
  CloudCaseVirtualMachine?: CloudCaseVirtualMachineResolvers<ContextType>;
  CloudCaseVirtualMachineStateChange?: CloudCaseVirtualMachineStateChangeResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerLearningSpaceAccess?: CustomerLearningSpaceAccessResolvers<ContextType>;
  CustomerCourseAccess?: CustomerCourseAccessResolvers<ContextType>;
  CustomerGroup?: CustomerGroupResolvers<ContextType>;
  CustomerGroupCourseAccess?: CustomerGroupCourseAccessResolvers<ContextType>;
  InvitationCode?: InvitationCodeResolvers<ContextType>;
  VistCourse?: VistCourseResolvers<ContextType>;
  VistCourseLicense?: VistCourseLicenseResolvers<ContextType>;
  VistSimulationLicense?: VistSimulationLicenseResolvers<ContextType>;
  VistSystem?: VistSystemResolvers<ContextType>;
  SystemsStats?: SystemsStatsResolvers<ContextType>;
  Simulation?: SimulationResolvers<ContextType>;
  Activity?: ActivityResolvers<ContextType>;
  VistActivityItem?: VistActivityItemResolvers<ContextType>;
  AllVistActivityItems?: AllVistActivityItemsResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Recording?: RecordingResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  Metric?: MetricResolvers<ContextType>;
  Benchmark?: BenchmarkResolvers<ContextType>;
  BenchmarkValue?: BenchmarkValueResolvers<ContextType>;
  Result?: ResultResolvers<ContextType>;
  ResultFile?: ResultFileResolvers<ContextType>;
  ResultValue?: ResultValueResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  CourseProgress?: CourseProgressResolvers<ContextType>;
  UnitProgress?: UnitProgressResolvers<ContextType>;
  TalentCourse?: TalentCourseResolvers<ContextType>;
  TalentCourseListing?: TalentCourseListingResolvers<ContextType>;
  TalentBranch?: TalentBranchResolvers<ContextType>;
  TalentUnit?: TalentUnitResolvers<ContextType>;
  NewsArticle?: NewsArticleResolvers<ContextType>;
  YoutubeVideo?: YoutubeVideoResolvers<ContextType>;
  Voucher?: VoucherResolvers<ContextType>;
  ResetResult?: ResetResultResolvers<ContextType>;
  RefetchPayload?: RefetchPayloadResolvers<ContextType>;
  InviteResponse?: InviteResponseResolvers<ContextType>;
  VoucherResponse?: VoucherResponseResolvers<ContextType>;
  ResourceAccessVoucher?: ResourceAccessVoucherResolvers<ContextType>;
  EventResourceAccessVoucher?: EventResourceAccessVoucherResolvers<ContextType>;
  CloudModulesResourceAccessVoucher?: CloudModulesResourceAccessVoucherResolvers<ContextType>;
  SingleUserCloudModulesResourceAccessVoucher?: SingleUserCloudModulesResourceAccessVoucherResolvers<ContextType>;
  CloudModule?: CloudModuleResolvers<ContextType>;
  CloudCase?: CloudCaseResolvers<ContextType>;
  UserQuotum?: UserQuotumResolvers<ContextType>;
  CustomerCloudModuleTimeBalance?: CustomerCloudModuleTimeBalanceResolvers<ContextType>;
  QRLoginResponse?: QrLoginResponseResolvers<ContextType>;
  SoftwareRelease?: SoftwareReleaseResolvers<ContextType>;
  System?: SystemResolvers<ContextType>;
  SystemRelease?: SystemReleaseResolvers<ContextType>;
  SystemSoftware?: SystemSoftwareResolvers<ContextType>;
  SystemSoftwaresReleases?: SystemSoftwaresReleasesResolvers<ContextType>;
  ProcedureActivityMeta?: ProcedureActivityMetaResolvers<ContextType>;
  IRLogFormData?: IrLogFormDataResolvers<ContextType>;
  InterventionType?: InterventionTypeResolvers<ContextType>;
  OrganSystem?: OrganSystemResolvers<ContextType>;
  ProcedureCategory?: ProcedureCategoryResolvers<ContextType>;
  ProcedureSubCategory?: ProcedureSubCategoryResolvers<ContextType>;
  ProcedureActivity?: ProcedureActivityResolvers<ContextType>;
  Procedure?: ProcedureResolvers<ContextType>;
  Participant?: ParticipantResolvers<ContextType>;
  ProcedureActivityReview?: ProcedureActivityReviewResolvers<ContextType>;
  Hospital?: HospitalResolvers<ContextType>;
  Attending?: AttendingResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Time?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  UtcOffset?: GraphQLScalarType;
  Duration?: GraphQLScalarType;
  ISO8601Duration?: GraphQLScalarType;
  LocalDate?: GraphQLScalarType;
  LocalTime?: GraphQLScalarType;
  LocalEndTime?: GraphQLScalarType;
  EmailAddress?: GraphQLScalarType;
  NegativeFloat?: GraphQLScalarType;
  NegativeInt?: GraphQLScalarType;
  NonEmptyString?: GraphQLScalarType;
  NonNegativeFloat?: GraphQLScalarType;
  NonNegativeInt?: GraphQLScalarType;
  NonPositiveFloat?: GraphQLScalarType;
  NonPositiveInt?: GraphQLScalarType;
  PhoneNumber?: GraphQLScalarType;
  PositiveFloat?: GraphQLScalarType;
  PositiveInt?: GraphQLScalarType;
  PostalCode?: GraphQLScalarType;
  UnsignedFloat?: GraphQLScalarType;
  UnsignedInt?: GraphQLScalarType;
  URL?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Byte?: GraphQLScalarType;
  Long?: GraphQLScalarType;
  SafeInt?: GraphQLScalarType;
  UUID?: GraphQLScalarType;
  GUID?: GraphQLScalarType;
  Hexadecimal?: GraphQLScalarType;
  HexColorCode?: GraphQLScalarType;
  HSL?: GraphQLScalarType;
  HSLA?: GraphQLScalarType;
  IPv4?: GraphQLScalarType;
  IPv6?: GraphQLScalarType;
  ISBN?: GraphQLScalarType;
  MAC?: GraphQLScalarType;
  Port?: GraphQLScalarType;
  RGB?: GraphQLScalarType;
  RGBA?: GraphQLScalarType;
  USCurrency?: GraphQLScalarType;
  Currency?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  IBAN?: GraphQLScalarType;
  ObjectID?: GraphQLScalarType;
  Void?: GraphQLScalarType;
}>;


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
