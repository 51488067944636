export enum TalentCourseCategory {
  CARDIOVASCULAR = "Cardiovascular",
  NEUROVASCULAR = "Neurovascular",
  ENDOVASCULAR = "Endovascular",
  PERIPHERAL = "Peripheral vascular",
  OTHER = "Other",
}

export enum VistCourseIcon {
  Heart,
  Patient,
}

export enum Operator {
  LT = "lessthan",
  LE = "lessequal",
  EQ = "equal",
  NE = "notequal",
  GE = "greaterequal",
  GT = "greaterthan",
}

export enum Branches {
  degir = "degir",
  evtnor = "evtnor",
  goto = "goto",
  free = "free",
  staging = "staging",
}

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
}

export enum ToastFormat {
  TOAST = "toast",
  BANNER = "banner",
  COUNTDOWN = "countdown",
  UNSAFE = "unsafe"
}

export enum ButtonSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export enum ButtonTypes {
  Button = "button",
  Submit = "submit",
  Reset = "reset",
}

export enum SortOrder {
  AZ = "az",
  ZA = "za",
  DateDesc = "newest",
  DateAsc = "oldest"
}
