import { useQuery } from "@apollo/client";
import React, { useMemo, useState, useEffect, useRef } from "react";
import TextField from "../../components/shared/TextField";
import SelectField from "../../components/shared/SelectField";
import ActionBar, {
  SelectFieldWidthProvider,
} from "../../components/shared/ActionBar";
import { debounce } from "lodash";
import { Option } from "react-select/src/filters";
import { getServiceTag } from "../../lib/getServiceTag";
import UpdateCard from "../../components/UpdateCard/UpdateCard";
import styled from "styled-components";
import { UpdateInfo } from "../../components/UpdateCard/types";
import { VistSystemSoftwareRelease, sortOrderOptions } from './types';
import { TabContainer, Tab, TabCount } from "./UpdatesStyles";
import {
  VIST_INSTALLED_RELEASES_QUERY,
} from "../../queries/updateQuery";
import { useUpdateOperations } from "./hooks/useUpdateOperations";

const TabContent = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const Updates = () => {
  const [selectedSortOption, setSelectedSortOption] = useState<Option>(
    sortOrderOptions[0]
  );
  const [searchText, setSearchText] = useState("");

  const {
    loading: updatesLoading,
    data: updatesData,
    error: updatesErrors,
    refetch: reloadUpdates,
  } = useQuery(VIST_INSTALLED_RELEASES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      serviceTag: getServiceTag(),
    },
    onCompleted: () => {
      console.log("updatesData: ", updatesData);
    },
  });
  const { vistAvailableReleasesForSystem: updates } =
    !updatesLoading && !updatesErrors && updatesData;

  const { isRunning, operationStates, handleDownload, handleInstall } = useUpdateOperations(
    updates,
    reloadUpdates
  );

  const debounceRequest = debounce((value) => {
    setSearchText(value);
  }, 300);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    debounceRequest(e.currentTarget.value);
  };

  const [expandedCards, setExpandedCards] = useState<Record<string, boolean>>(
    {}
  );

  const toggleCardExpansion = (updateId: string) => {
    setExpandedCards((prev) => ({ ...prev, [updateId]: !prev[updateId] }));
  };

  const [activeTab, setActiveTab] = useState<"available" | "installed">(
    "available"
  );

  const sortedUpdates = useMemo(() => {
    const softwareReleaseArray = [].concat(
      updates || []
    ) as VistSystemSoftwareRelease[];

    return softwareReleaseArray.sort((u1, u2) => {
      switch (selectedSortOption.data.sortField) {
        case "name":
          const nameCompare = u1.release.software.name
            .toLowerCase()
            .localeCompare(u2.release.software.name.toLowerCase());
          return selectedSortOption.data.sortOrder === "asc"
            ? nameCompare
            : -nameCompare;
        case "date":
          const date1 = new Date(u1.createdAt).getTime();
          const date2 = new Date(u2.createdAt).getTime();
          return selectedSortOption.data.sortOrder === "asc"
            ? date1 - date2
            : date2 - date1;
        default:
          return 0;
      }
    });
  }, [updates, selectedSortOption]);

  const filteredUpdates = useMemo(() => {
    return sortedUpdates.filter((update) => {
      if (searchText === "") return true;
      return update.release.software.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [sortedUpdates, searchText]);

  const availableUpdates = filteredUpdates.filter(
    (update) => !update.state.installed
  );
  const installedUpdates = filteredUpdates.filter(
    (update) => update.state.installed
  );

  useEffect(() => {
    const opInterval = setInterval(reloadUpdates, 5000);
    return () => clearInterval(opInterval);
  }, [reloadUpdates]);

  return (
    <div>
      <ActionBar
        title={
          updatesLoading
            ? "Updates"
            : "Updates for " + getServiceTag() + " (" + updates?.length + ")"
        }
        searchField={
          <TextField
            searchable
            onClearSearch={() => {
              setSearchText("");
            }}
            placeholder="Search by software name"
            onChange={handleSearchChange}
          />
        }
        buttonGroup={
          <SelectFieldWidthProvider>
            <SelectField
              name="Sort by"
              isActionBar={true}
              onChange={(option: Option) => setSelectedSortOption(option)}
              value={{
                value: selectedSortOption.value,
                label: `Sort: ${selectedSortOption.label}`,
                data: {
                  sortField: selectedSortOption.data.sortField,
                  sortOrder: selectedSortOption.data.sortOrder,
                },
              }}
              options={sortOrderOptions}
            />
          </SelectFieldWidthProvider>
        }
      />

      <TabContainer>
        <Tab
          active={activeTab === "available"}
          onClick={() => setActiveTab("available")}
        >
          Available Updates
          <TabCount>{availableUpdates.length}</TabCount>
        </Tab>
        <Tab
          active={activeTab === "installed"}
          onClick={() => setActiveTab("installed")}
        >
          Installed Software
          <TabCount>{installedUpdates.length}</TabCount>
        </Tab>
      </TabContainer>

      <TabContent active={activeTab === "available"}>
        {availableUpdates.map(
          (update: VistSystemSoftwareRelease, index: number) => (
            <UpdateCard
              key={update.id}
              update={update as UpdateInfo}
              onDownload={handleDownload}
              onInstall={handleInstall}
              isExpanded={expandedCards[update.id] || false}
              toggleExpansion={() => toggleCardExpansion(update.id)}
              tabType="available"
              index={index}
            />
          )
        )}
      </TabContent>

      <TabContent active={activeTab === "installed"}>
        {installedUpdates.map(
          (update: VistSystemSoftwareRelease, index: number) => (
            <UpdateCard
              key={update.id}
              update={update as UpdateInfo}
              onDownload={handleDownload}
              onInstall={handleInstall}
              isExpanded={expandedCards[update.id] || false}
              toggleExpansion={() => toggleCardExpansion(update.id)}
              tabType="installed"
              index={index}
            />
          )
        )}
      </TabContent>
    </div>
  );
};

export default Updates;
