import styled, { keyframes } from "styled-components";
import { Card } from "../shared/Card";

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TabContent = styled.div<{ active: boolean }>`
  display: ${props => props.active ? 'block' : 'none'};
`;

export const AnimatedCard = styled.div<{ index: number }>`
  opacity: 0;
  transform: translateY(20px);
  animation: ${slideIn} 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: ${props => Math.min(props.index * 0.1, 1)}s;
  will-change: transform, opacity;
`;

export const UpdateCard = styled(Card)`
  margin: 24px 0;
  justify-content: space-between;
  position: relative;
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const ExpandableUpdateCard = styled(UpdateCard)`
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 20px;
`;

export const ExpandedContent = styled.div<{ isExpanded: boolean }>`
  max-height: ${(props) => (props.isExpanded ? "500px" : "0")};
  opacity: ${(props) => (props.isExpanded ? "1" : "0")};
  transition: all 0.3s ease-in-out;
  overflow: visible;
  padding: ${(props) => (props.isExpanded ? "20px" : "0")};
  margin: 0 20px;
  border-top: ${(props) => (props.isExpanded ? "1px solid #eee" : "none")};
`;

export const DocumentationSection = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
  
  > * {
    transition: opacity 0.2s ease-in-out;
  }
`;

export const DocumentationTitle = styled.h5`
  font-size: 14px;
  color: #666;
  margin: 0 0 12px 0;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
    color: #888;
  }
`;

export const DocumentationContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
  min-height: 40px;
`;
